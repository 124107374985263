import { FC } from 'react';
import { useIntl } from 'react-intl';

import { Invoice } from '@common/types/banking';
import { formatDateString } from '@common/utils/dateUtils';

interface InvoiceItemProps {
  invoice: Invoice;
  isOverdue?: boolean;
}

const InvoiceItem: FC<InvoiceItemProps> = ({ invoice, isOverdue = false }) => {
  const { formatNumber } = useIntl();
  return (
    <div className="flex w-full items-center justify-between">
      <span className="text-base font-medium tracking-[-0.01rem]">
        {formatDateString(invoice.createdAt)}
      </span>
      <div
        className={`rounded-lg p-3 text-sm font-semibold tracking-[-0.00875rems] ${
          isOverdue ? 'bg-[#FFDCDC] text-[#FF0000]' : 'bg-[#F9FAFB] text-black'
        }`}
      >
        {formatNumber(invoice.totalAmount, {
          style: 'currency',
          currency: 'EUR',
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
          useGrouping: true,
        })}
      </div>
    </div>
  );
};

export default InvoiceItem;
