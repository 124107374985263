import { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import BackButtonIcon from '@common/components/icons/BackButtonIcon';
import useCountry, { Country } from '@common/hooks/useCountry';
import useTailwindBreakpoints, { TWBreakPoint } from '@common/hooks/useTailwindBreakpoints';
// eslint-disable-next-line boundaries/no-private
import SpainLayout from '@monefit-es/components/layout/MainLayout';
import { TRANSITION_ANIMATION } from '@src/assets/animations/motion.animations';
import clsx from 'clsx';
import { motion } from 'framer-motion';

import MainLayout, { MainLayoutProps } from './Layout.Main';

interface LayoutWithNavigationProps extends MainLayoutProps {
  backButtonText?: string;
  onBackButtonClick?: () => void;
  maxWDesktop?: number;
  maxWMobile?: number;
}

const LayoutWithNavigation: FC<LayoutWithNavigationProps> = ({
  backButtonText,
  onBackButtonClick,
  maxWDesktop,
  maxWMobile,
  children,
  loaderType,
  ...props
}) => {
  const { isMobile } = useTailwindBreakpoints();
  const { formatMessage } = useIntl();
  const { country } = useCountry();
  const navigate = useNavigate();

  const mainLayout = useMemo(() => {
    switch (country) {
      case Country.ES:
        return SpainLayout;
      default:
        return MainLayout;
    }
  }, [country]);

  const handleBackClick = useCallback(() => {
    onBackButtonClick ? onBackButtonClick() : navigate(-1);
  }, [navigate, onBackButtonClick]);

  const LayoutComponent = mainLayout;

  return (
    <LayoutComponent
      loaderType={loaderType}
      hideHeaderAndFooter={isMobile || props.loadingProp}
      {...props}
    >
      <motion.div
        {...TRANSITION_ANIMATION}
        className={clsx('flex size-full flex-col items-center sm:py-0', {
          'justify-between': isMobile,
          'flex-1': isMobile,
          'py-16': isMobile,
          'gap-3': isMobile,
          'gap-32': !isMobile,
        })}
        style={{
          maxWidth: isMobile
            ? maxWMobile
              ? `${maxWMobile}px`
              : '100%'
            : maxWDesktop
              ? `${maxWDesktop}px`
              : '100%',
        }}
      >
        <div
          className={clsx('flex w-full items-start justify-between gap-3', {
            'flex-col': isMobile,
            'flex-row': !isMobile,
          })}
        >
          <button type="button" onClick={handleBackClick}>
            <BackButtonIcon />
          </button>
          <div className="text-2xl font-semibold">
            {backButtonText ?? formatMessage({ defaultMessage: 'Back' })}
          </div>
        </div>

        {children}
      </motion.div>
    </LayoutComponent>
  );
};

export default LayoutWithNavigation;
