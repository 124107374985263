import { useIntl } from 'react-intl';

import Button from '@common/components/button/Button';
import { SubmitButtonProps, getSubmitButtonOptions } from '@rjsf/utils';

interface SumbitButtonExtendedProps extends SubmitButtonProps {
  text?: string;
  disabled?: boolean;
}

const SubmitButtonTemplate = (props: SumbitButtonExtendedProps) => {
  const { formatMessage } = useIntl();
  const { uiSchema } = props;
  const { norender } = getSubmitButtonOptions(uiSchema);
  if (norender) {
    return null;
  }

  return (
    <Button
      text={props.text ?? formatMessage({ defaultMessage: 'Continue' })}
      disabled={props.disabled}
      fullWidth
      type="submit"
      extraClassNames="mt-6"
    />
  );
};

export default SubmitButtonTemplate;
