export function getSmartSaverServiceBaseUrls() {
  const { REACT_APP_SMARTSAVER_API_BASEURL: baseUrl } = process.env;
  const authBaseUrl = `${baseUrl}/v1/auth`;
  const accountBaseUrl = `${baseUrl}/v1/account`;
  const registerBaseUrl = `${baseUrl}/v1/register`;

  return {
    authBaseUrl,
    accountBaseUrl,
    registerBaseUrl,
  };
}
