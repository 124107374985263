import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { delay } from '@common/utils';
import MainLayout from '@monefit-es/components/layout/MainLayout';

const WelcomeTextLoader = ({ onComplete }: { onComplete: () => void }) => {
  const [step, setStep] = useState<number | null>(null);

  const handleAnimation = useCallback(async () => {
    await delay(100);
    setStep(1);
    await delay(2500);
    setStep(2);
    await delay(2500);
    onComplete();
  }, [onComplete]);

  useEffect(() => {
    handleAnimation();
  }, [handleAnimation]);

  return (
    <MainLayout hideHeaderAndFooter>
      <div className="text-[28px]">
        {step === 1 && (
          <div className="animate-fadeInOut text-center">
            <FormattedMessage
              defaultMessage="Welcome to Monefit Credit"
              description="Welcome to Monefit Credit"
            />
          </div>
        )}
        {step === 2 && (
          <div className="animate-fadeInOut text-center">
            <FormattedMessage
              defaultMessage="Your personal creditline."
              description="Your personal creditline."
            />
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default WelcomeTextLoader;
