import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  headerSubText: {
    defaultMessage: 'Looking good (so far)',
    description: '@monefit-es  Looking good (so far)',
  },
  headerText: {
    defaultMessage: 'You could qualify for up to {amount} {currency}!',
    description: '@monefit-es You could qualify for up to {amount} {currency}!',
  },
  mainText: {
    defaultMessage:
      'We just need to review your bank records to confirm your income and financial obligations, so we can determine your credit limit.',
    description:
      '@monefit-es We just need to review your bank records to confirm your income and financial obligations, so we can determine your credit limit.',
  },
  buttonText: {
    defaultMessage: 'Continue',
  },
  monthioPrivacyText: {
    defaultMessage:
      "By proceeding, you'll be redirected to Monthio for bank verification. Learn how your data is processed in Monthio's",
    description:
      "@monefit-es By proceeding, you'll be redirected to Monthio for bank verification. Learn how your data is processed in Monthio's",
  },
  privacyPolicy: {
    defaultMessage: 'Privacy Policy.',
    description: '@monefit-es Privacy Policy.',
  },
});
