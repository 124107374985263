import { FC } from 'react';

import { IconProps } from './types';

const CalendarIcon: FC<IconProps> = ({ width = '24', height = '24' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 1C7.55228 1 8 1.44772 8 2V3H16V2C16 1.44772 16.4477 1 17 1C17.5523 1 18 1.44772 18 2V3H20C21.6569 3 23 4.34315 23 6V19C23 20.6569 21.6569 22 20 22H4C2.34315 22 1 20.6569 1 19V6C1 4.34315 2.34315 3 4 3H6V2C6 1.44772 6.44772 1 7 1ZM4 5C3.44772 5 3 5.44772 3 6V8H21V6C21 5.44772 20.5523 5 20 5H4ZM21 10H3V19C3 19.5523 3.44772 20 4 20H20C20.5523 20 21 19.5523 21 19V10Z"
        fill="black"
      />
    </svg>
  );
};

export default CalendarIcon;
