import { getToken } from '@smartsaver/utils/token';
import axios, { AxiosRequestConfig } from 'axios';

export const initProtectedHttpService = ({
  baseURL,
  params = {},
}: {
  baseURL: string;
  params?: AxiosRequestConfig;
}) => {
  const accessToken = getToken();

  const service = axios.create({
    baseURL,
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      ...params.headers,
    },
    ...params,
  });

  return service;
};
