import React from 'react';
import { Helmet } from 'react-helmet-async';

interface MetaTagsProps {
  title: string;
  description?: string;
  url?: string;
  image?: string;
}

const MetaTags: React.FC<MetaTagsProps> = ({ title, description, url, image }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />

      {/* <!-- Facebook Meta Tags --> */}
      <meta property="og:url" content={url ?? 'app.monefit.com'} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {!!image && <meta property="og:image" content={image} />}

      {/* <!-- Twitter Meta Tags --> */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content={url ?? 'app.monefit.com'} />
      <meta property="twitter:url" content={url ?? 'app.monefit.com'} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {!!image && <meta name="twitter:image" content={image} />}
    </Helmet>
  );
};

export default MetaTags;
