import { FC } from 'react';

import { IconProps } from './types';

const AddFilesIcon: FC<IconProps> = ({ width = '25', height = '24' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M16.5 15C16.5 15.5523 16.0523 16 15.5 16H13.5V18C13.5 18.5523 13.0523 19 12.5 19C11.9477 19 11.5 18.5523 11.5 18V16H9.5C8.94771 16 8.5 15.5523 8.5 15C8.5 14.4477 8.94772 14 9.5 14H11.5V12C11.5 11.4477 11.9477 11 12.5 11C13.0523 11 13.5 11.4477 13.5 12V14H15.5C16.0523 14 16.5 14.4477 16.5 15Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 4C2.5 2.34315 3.84315 1 5.5 1H14.5C14.7652 1 15.0196 1.10536 15.2071 1.29289L22.2071 8.29289C22.3946 8.48043 22.5 8.73478 22.5 9V20C22.5 21.6569 21.1569 23 19.5 23H5.5C3.84315 23 2.5 21.6569 2.5 20V4ZM5.5 3C4.94772 3 4.5 3.44772 4.5 4V20C4.5 20.5523 4.94772 21 5.5 21H19.5C20.0523 21 20.5 20.5523 20.5 20V10H16.5C14.8431 10 13.5 8.65685 13.5 7V3H5.5ZM15.5 4.41421L19.0858 8H16.5C15.9477 8 15.5 7.55228 15.5 7V4.41421Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default AddFilesIcon;
