import { FC } from 'react';

import { IconProps } from './types';

const PaperClipIcon: FC<IconProps> = ({ width = '25', height = '24' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M4.19084 12.5729L14.3832 2.38052C16.1406 0.623161 18.9898 0.623159 20.7472 2.38052C22.4919 4.12527 22.5045 6.94631 20.7847 8.7065L10.555 18.9369C9.38339 20.1085 7.4839 20.1085 6.31232 18.9369C5.14075 17.7653 5.14075 15.8658 6.31232 14.6942L11.9692 9.03739C12.3597 8.64686 12.9929 8.64686 13.3834 9.03739C13.7739 9.42791 13.7739 10.0611 13.3834 10.4516L7.72654 16.1085C7.33601 16.499 7.33601 17.1321 7.72654 17.5227C8.11706 17.9132 8.75023 17.9132 9.14075 17.5227L19.3331 7.33036C20.3094 6.35405 20.3093 4.77104 19.333 3.79473C18.3567 2.81842 16.7738 2.81842 15.7974 3.79473L5.60511 13.9871C4.04302 15.5492 4.04312 18.0819 5.60522 19.644C7.16732 21.2061 9.69998 21.2061 11.2621 19.644L16.9189 13.9872C17.3095 13.5966 17.9426 13.5967 18.3331 13.9872C18.7237 14.3777 18.7237 15.0109 18.3331 15.4014L12.6763 21.0582C10.3331 23.4014 6.53415 23.4014 4.19101 21.0582C1.84788 18.7151 1.84775 14.9161 4.19084 12.5729Z"
        fill="black"
      />
    </svg>
  );
};

export default PaperClipIcon;
