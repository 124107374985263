import { useIntl } from 'react-intl';

import MainLayout from '@smartsaver/components/main-layout/MainLayout';
import SignupCompanyDocuments from '@smartsaver/components/signup-company-documents/SignupCompanyDocuments';

const SignupCompanyDocumentsPage = () => {
  const { formatMessage } = useIntl();
  return (
    <MainLayout pageTitle={formatMessage({ defaultMessage: 'Sign up' })}>
      <SignupCompanyDocuments />
    </MainLayout>
  );
};

export default SignupCompanyDocumentsPage;
