import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  usedCredit: {
    defaultMessage: 'Used credit',
  },
  invoicePeriod: {
    defaultMessage: 'Invoice period',
  },
  due: {
    defaultMessage: 'Due',
  },
  invoice: {
    defaultMessage: 'Invoice',
  },
  amountSelect: {
    defaultMessage: "I'd like to pay:",
    description: "@monefit-es I'd like to pay:",
  },
  leftToPayAfterThisPayment: {
    defaultMessage: 'Left to pay after this payment:',
    description: '@monefit-es Left to pay after this payment:',
  },
  payLesss: {
    defaultMessage: 'I want to pay less',
    description: '@monefit-es I want to pay less',
  },
  payFullAmount: {
    defaultMessage: 'Pay full amount:',
    description: '@monefit-es Pay full amount:',
  },
  continue: {
    defaultMessage: 'Continue',
  },
  download: {
    defaultMessage: 'Download',
  },
  contactSupport: {
    defaultMessage: 'Contact support',
  },
  noRemainingPayment: {
    defaultMessage: 'You’ll pay 0 interest. Nice one 👌',
    description: '@monefit-es You’ll pay 0 interest. Nice one 👌',
  },
  noPaymentWarning: {
    defaultMessage:
      "If you don't pay the remaining {remainingPayment} by {nextInvoiceTargetDate}, {interestLeftToPay} interest will be added to next month's invoice.",
    description:
      "@monefit-es If you don't pay the remaining {remainingPayment} by {nextInvoiceTargetDate}, {interestLeftToPay} interest will be added to next month's invoice.",
  },
  reduceInvoiceToAmount: {
    defaultMessage: "You'll reduce your invoice so far to {amount}",
    description: "@monefit-es You'll reduce your invoice so far to {amount}",
  },
  suffixTh: {
    defaultMessage: 'th',
    description: '@monefit-es th',
  },
  suffixSt: {
    defaultMessage: 'st',
    description: '@monefit-es st',
  },
  suffixNd: {
    defaultMessage: 'nd',
    description: '@monefit-es nd',
  },
  suffixRd: {
    defaultMessage: 'rd',
    description: '@monefit-es rd',
  },
});
