import { RootState } from '@common/redux';
import { initialState } from '@common/redux/slices/documents';
import { createSelector } from '@reduxjs/toolkit';

export const makeSelectDocuments = (state: RootState) =>
  'documents' in state ? state.documents : initialState;

export const selectDocumentsLoading = createSelector(makeSelectDocuments, (b) => b.loading);
export const selectDocumentsError = createSelector(makeSelectDocuments, (b) => b.error);
export const selectDocuments = createSelector(makeSelectDocuments, (b) => b.documents);
export const selectDocumentSigningURL = createSelector(
  makeSelectDocuments,
  (b) => b.documentSigningURL
);
