import { FormatMessage } from '@common/types';
import * as yup from 'yup';

import m from './SignupCompanyDocuments.messages';

interface GetFormSchemaProps {
  formatMessage: FormatMessage;
}

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB
const MIN_FILES = 2;
const MAX_FILES = 10;

export const getFormSchema = ({ formatMessage }: GetFormSchemaProps) =>
  yup.object().shape({
    files: yup
      .mixed<FileList>()
      .test(
        'required',
        formatMessage(m.missingDocumentsMessage, { amount: MIN_FILES }),
        (files) => files && files.length >= MIN_FILES
      )
      .test(
        'fileCount',
        formatMessage(m.maxDocumentsAmountMessage, { amount: MAX_FILES }),
        (files) => files && files.length <= MAX_FILES
      )
      .test('fileSize', formatMessage(m.documentsMaxSizeMessage, { size: 10 }), (files) =>
        files ? Array.from(files).every((file) => file.size <= MAX_FILE_SIZE) : true
      ),
  });

export type GetFormSchema = typeof getFormSchema;

export type FormSchema = yup.InferType<ReturnType<GetFormSchema>>;
