import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  allRightsReserved: {
    defaultMessage: '© Creditstar Group. All Rights Reserved.',
  },
  faq: {
    defaultMessage: 'FAQ',
  },
  contactUs: {
    defaultMessage: 'Contact us',
  },
  legal: {
    defaultMessage: 'Legal',
  },
});
