import { FormatMessage } from '@common/types';
import * as yup from 'yup';

import m from './CompanyForm.messages';

interface GetFormSchemaProps {
  formatMessage: FormatMessage;
}

export const getFormSchema = ({ formatMessage }: GetFormSchemaProps) =>
  yup.object({
    companyName: yup.string().required(formatMessage(m.fieldIsMandatory)),
    companyRegNumber: yup.string().required(formatMessage(m.fieldIsMandatory)),
    companyCountry: yup.string().required(formatMessage(m.fieldIsMandatory)),
    companyAddressCountry: yup.string().required(formatMessage(m.fieldIsMandatory)),
    companyAddress: yup.string().required(formatMessage(m.fieldIsMandatory)),
    companyCity: yup.string().required(formatMessage(m.fieldIsMandatory)),
    representativeFirstName: yup.string().required(formatMessage(m.fieldIsMandatory)),
    representativeLastName: yup.string().required(formatMessage(m.fieldIsMandatory)),
    representativeIdNumber: yup.string().required(formatMessage(m.fieldIsMandatory)),
    representativeBirthday: yup.string().required(formatMessage(m.fieldIsMandatory)),
    representativeTaxResidency: yup.string().required(formatMessage(m.fieldIsMandatory)),
    representativeAddress: yup.string().required(formatMessage(m.fieldIsMandatory)),
    representativeCity: yup.string().required(formatMessage(m.fieldIsMandatory)),
    representativePostalCode: yup.string().required(formatMessage(m.fieldIsMandatory)),
    companyPostalCode: yup.string().required(formatMessage(m.fieldIsMandatory)),
    promoCode: yup.string().optional().nullable(),
  });

export type GetCompanyFormSchema = typeof getFormSchema;

export type CompanyFormSchema = yup.InferType<ReturnType<GetCompanyFormSchema>>;
