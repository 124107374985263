import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Checkbox from '@common/components/checkbox/Checkbox';
import Form from '@common/components/form/Form';
import { emailValidationRegex } from '@common/components/form/Form.helpers';
import SignupBaseCard from '@common/components/sign-up-layout/SignupBaseCard';
import SignupLayout from '@common/components/sign-up-layout/SignupLayout';
import TextInput from '@common/components/text-input/TextInput';
import { useApplicationFlowInstance } from '@common/hooks';
import { useDispatch } from '@common/hooks/useDispatch';
import { useSelector } from '@common/hooks/useSelector';
import useTracker from '@common/hooks/useTracker';
import useUserSessionData from '@common/hooks/useUserSessionData';
import { RootDispatch } from '@common/redux';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions, IdentificationCheckResponse } from '@common/services/application';
import { TrackEvent } from '@common/utils/amplitude/events';
import * as yup from 'yup';

interface FormValues {
  firstName?: string;
  lastName?: string;
  personalCode?: string;
  email: string;
  termsAndPrivacyConsent: boolean;
  emailMarketingConsent: boolean;
}

const FIELDS_TO_LOG_ON_CHANGE = [
  {
    fieldName: 'email',
    event: TrackEvent.EMAIL_PAGE_EMAIL_ENTERED,
  },
  {
    fieldName: 'termsAndPrivacyConsent',
    event: TrackEvent.EMAIL_PAGE_CHECKBOX_CHECKED,
  },
];

const ReviewDetailsPage = () => {
  const { trackEvent } = useTracker({
    initEventName: TrackEvent.EMAIL_PAGE_INIT,
    errorTrackEventName: TrackEvent.EMAIL_PAGE_ERROR,
  });

  const [instanceId] = useApplicationFlowInstance();
  useTracker({ initEventName: TrackEvent.EMAIL_PAGE_INIT });
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<RootDispatch>();
  const { data } = useSelector((state) => state.application.flowInstance);
  const { getFromStorage, saveToStorage } = useUserSessionData();

  const termsAndPrivacyLabel = (
    <div className="text-[15px] font-medium leading-normal text-black">
      <FormattedMessage
        defaultMessage="I have read and agree with the {link} policy of Monefit CreditLine"
        values={{
          link: (
            <a
              className="underline"
              rel="noreferrer"
              target="_blank"
              href="https://monefit.com/wp-content/uploads/2023/04/Monefit-Estonia-_OU_-Kliendiandmete-tootlemise-pohimotted.pdf"
            >
              <FormattedMessage defaultMessage="terms & privacy"></FormattedMessage>
            </a>
          ),
        }}
      />
    </div>
  );

  const markentingConsentText = formatMessage({
    defaultMessage: "I'm eager to receive exciting updates and exclusive offers from Monefit!",
    description: 'Email: marketing consent',
  });

  const typedResponseData = data?.responseData as IdentificationCheckResponse;
  const storageData = getFromStorage();

  const schema: yup.ObjectSchema<FormValues> = useMemo(
    () =>
      yup.object({
        firstName: yup.string(),
        lastName: yup.string(),
        personalCode: yup.string(),
        /**
         * Regex is used because validation for
         * yup.string().email() allows an invalid email such as user@example.com..au to be marked as valid.
         * https://github.com/jquense/yup/issues/507
         */
        email: yup
          .string()
          .required(
            formatMessage({
              defaultMessage: 'Email is required',
              description: 'Email:  "Email is required"',
            })
          )
          .matches(
            emailValidationRegex,
            formatMessage({
              defaultMessage: "Invalid email format. Include '@' and a valid domain, like '.com'",
              description: 'Email: "Invalid email format"',
            })
          ),
        termsAndPrivacyConsent: yup
          .bool()
          .oneOf(
            [true],
            formatMessage({
              defaultMessage: 'The field is mandatory',
              description: 'Email: "The field is mandatory" (termsAndPrivacyConsent)',
            })
          )
          .required(
            formatMessage({
              defaultMessage: 'The field is mandatory',
              description: 'Email: "The field is mandatory" (termsAndPrivacyConsent)',
            })
          ),
        emailMarketingConsent: yup.bool().required(),
      }),
    [formatMessage]
  );

  return (
    <SignupLayout>
      <SignupBaseCard
        headerText={formatMessage({
          defaultMessage: 'Please review your details',
          description: 'Email: "Please review your details"',
        })}
        subHeaderText={formatMessage({
          defaultMessage: 'Please take a moment to review your details below.',
          description: 'Email: "Please take a moment to review your details below."',
        })}
      >
        <Form
          submitTrackEventName={TrackEvent.EMAIL_PAGE_SUBMIT}
          fieldsToTrackOnChange={FIELDS_TO_LOG_ON_CHANGE}
          onSubmit={(values: FormValues) => {
            const { email, termsAndPrivacyConsent, emailMarketingConsent } = values;
            dispatch(
              performApplicationFlowAction({
                instanceId,
                action: FlowActions.SUBMIT,
                data: { email, termsAndPrivacyConsent, emailMarketingConsent },
              })
            )
              .unwrap()
              .then(() => {
                saveToStorage({
                  email,
                  lastName: typedResponseData?.lastName,
                  firstName: typedResponseData?.firstName,
                  personalCode: typedResponseData?.personalCode,
                });
              })
              .catch(() => {
                trackEvent(TrackEvent.EMAIL_PAGE_ERROR);
              });
          }}
          schema={schema}
          initialValues={{
            firstName: typedResponseData?.firstName ?? storageData?.firstName,
            lastName: typedResponseData?.lastName ?? storageData?.lastName,
            personalCode: typedResponseData?.personalCode ?? storageData?.personalCode,
            email: (data?.prefilledData as any)?.email ?? storageData?.email,
            emailMarketingConsent: false,
          }}
        >
          <TextInput
            name="firstName"
            label={formatMessage({
              defaultMessage: 'First name',
              description: 'Email: "First name"',
            })}
            disabled
          />
          <TextInput
            name="lastName"
            label={formatMessage({
              defaultMessage: 'Last name',
              description: 'Email: "Last name"',
            })}
            disabled
          />
          <TextInput
            name="personalCode"
            label={formatMessage({ defaultMessage: 'ID', description: 'Email: "ID"' })}
            disabled
          />
          <TextInput
            name="email"
            type="email"
            label={formatMessage({
              defaultMessage: 'Email',
              description: 'Email: "Email" (input label)',
            })}
            desciption={formatMessage({
              defaultMessage: 'We will send you secure code to verify your email.',
              description: 'Email: "We will send you secure code to verify your email."',
            })}
          />
          <div className="mt-3">
            <Checkbox name="termsAndPrivacyConsent" labelComponent={termsAndPrivacyLabel} />
          </div>
          <div className="mt-3">
            <Checkbox name="emailMarketingConsent" label={markentingConsentText} />
          </div>
        </Form>
      </SignupBaseCard>
    </SignupLayout>
  );
};

export default ReviewDetailsPage;
