import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  heading1: {
    defaultMessage: 'Good news {name}!',
    description: '@monefit-es Good news {name}!',
  },
  heading2: {
    defaultMessage: 'Your approved credit limit is {limit} €',
    description: '@monefit-es Your approved credit limit is {limit} €',
  },
  heading3: {
    defaultMessage: 'Choose Credit+ to proceed with the full approved limit',
    description: '@monefit-es Choose Credit+ to proceed with the full approved limit',
  },
  creditText1: {
    defaultMessage: 'SmartSaver cashback',
    description: '@monefit-es SmartSaver cashback',
  },
  creditText2: {
    defaultMessage: 'Double SmartSaver bonus',
    description: '@monefit-es Double SmartSaver bonus',
  },
  creditText3: {
    defaultMessage: 'SmartSaver bonus',
    description: '@monefit-es SmartSaver bonus',
  },
  subscriptionText1: {
    defaultMessage:
      '*Get 0% p.a. credit if paid on time, if overdue then {percentage}% interest rate applied',
    description:
      '@monefit-es *Get 0% p.a. credit if paid on time, if overdue then {percentage}% interest rate applied',
  },
  subscriptionText2: {
    defaultMessage: 'SmartSaver cashback',
    description: '@monefit-es SmartSaver cashback',
  },
  subscriptionText3: {
    defaultMessage: 'Double SmartSaver bonus',
    description: '@monefit-es Double SmartSaver bonus',
  },
});
