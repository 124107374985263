import { emailValidationRegex } from '@common/components/form/Form.helpers';
import { FormatMessage } from '@common/types';
import * as yup from 'yup';

import m from './ForgotPasswordForm.messages';

interface GetFormSchemaProps {
  formatMessage: FormatMessage;
}

export const getFormSchema = ({ formatMessage }: GetFormSchemaProps) =>
  yup.object({
    identifier: yup
      .string()
      .required(formatMessage(m.blankEmail))
      .matches(emailValidationRegex, formatMessage(m.wrongEmail)),
  });

export type GetForgotPasswordFormSchema = typeof getFormSchema;

export type ForgotPasswordFormSchema = yup.InferType<ReturnType<GetForgotPasswordFormSchema>>;
