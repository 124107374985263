import { emailValidationRegex } from '@common/components/form/Form.helpers';
import { FormatMessage } from '@common/types';
import * as yup from 'yup';

import m from './SignUpForm.messages';

interface GetFormSchemaProps {
  formatMessage: FormatMessage;
}

export const getFormSchema = ({ formatMessage }: GetFormSchemaProps) =>
  yup.object({
    identifier: yup
      .string()
      .required(formatMessage(m.blankEmail))
      .matches(emailValidationRegex, formatMessage(m.wrongEmail)),
    password: yup
      .string()
      .min(8, formatMessage(m.shortPassword))
      .matches(/[A-Z]/, formatMessage(m.passwordUpperCaseMissing))
      .matches(/[a-z]/, formatMessage(m.passwordLowerCaseMissing))
      .matches(/\d/, formatMessage(m.passwordNumberMissing))
      .required(formatMessage(m.passwordRequired)),
    consent: yup
      .bool()
      .oneOf([true], formatMessage(m.consentMissing))
      .required(formatMessage(m.consentMissing)),
  });

export type GetSignupFormSchema = typeof getFormSchema;

export type SignupFormSchema = yup.InferType<ReturnType<GetSignupFormSchema>>;
