import { InvididualFormSchema } from '@smartsaver/components/individual-form/IndividualForm.schema';
import { storeToken } from '@smartsaver/utils/token';
import { getSmartSaverServiceBaseUrls } from '@smartsaver/utils/url';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

import { initProtectedHttpService } from './protectedHttpService';

const { registerBaseUrl } = getSmartSaverServiceBaseUrls();
const registerService = axios.create({
  baseURL: registerBaseUrl,
  headers: {
    'Content-type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  withCredentials: true,
});

const protectedRegisterService = initProtectedHttpService({
  baseURL: registerBaseUrl,
  params: { withCredentials: true },
});

export const initRegister = async ({
  email,
  password,
  termsAndPrivacyAccepted,
}: {
  email: string;
  password: string;
  termsAndPrivacyAccepted: '1' | '0';
}) => {
  const { data } = await registerService.post('', { email, password, termsAndPrivacyAccepted });
  const decoded = jwtDecode(data?.data?.token);
  console.log(decoded);
  if (data?.data?.token) {
    storeToken(data.data.token);
  }
};

export const registerInvididual = async (data: InvididualFormSchema) => {
  const res = await protectedRegisterService?.post('/individual', data);
  console.log(res);
};
