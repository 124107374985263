import { useIntl } from 'react-intl';

const DataProtectedBlock = () => {
  const { formatMessage } = useIntl();
  return (
    <div className="text-center text-lg font-normal leading-normal text-[#82838e]">
      {formatMessage({
        defaultMessage: 'Your data is protected 🔒',
        description: '@smartsaver Your data is protected 🔒',
      })}
    </div>
  );
};

export default DataProtectedBlock;
