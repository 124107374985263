import { useCallback, useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';

import TagManager from '@sooro-io/react-gtm-module';

import useApp, { AppId } from './useApp';
import useEnvironment from './useEnvironment';
import useSubApp, { SubAppId } from './useSubApp';

const useInitTrackers = () => {
  const { appId } = useApp();
  const { subAppId } = useSubApp();
  const { isProduction } = useEnvironment();
  const {
    REACT_APP_GTM_ID_EE,
    REACT_APP_GTM_ID_ES,
    REACT_APP_GTM_ID_SMARTSAVER,
    REACT_APP_PIXEL_ID_SMARTSAVER,
  } = process.env;

  // Google Tag Manager Initialization
  const initGTM = useCallback(() => {
    if (!isProduction) {
      return;
    }

    let gtmId = null;
    let source = '';

    switch (subAppId) {
      case SubAppId.MONEFIT_EE:
        gtmId = REACT_APP_GTM_ID_EE;
        source = 'https://www.googletagmanager.com/gtm.js';
        break;
      case SubAppId.MONEFIT_ES:
        gtmId = REACT_APP_GTM_ID_ES;
        source = 'https://ss.monefit.com/gtm.js';
        break;
      default:
        if (appId === AppId.SMARTSAVER) {
          gtmId = REACT_APP_GTM_ID_SMARTSAVER;
          source = 'https://ss.monefit.com/gtm.js';
        }
    }

    if (gtmId) {
      TagManager.initialize({ gtmId, source });
    }
  }, [
    isProduction,
    subAppId,
    appId,
    REACT_APP_GTM_ID_EE,
    REACT_APP_GTM_ID_ES,
    REACT_APP_GTM_ID_SMARTSAVER,
  ]);

  // Facebook Pixel Initialization
  const initPixel = useCallback(() => {
    if (!isProduction || appId !== AppId.SMARTSAVER || !REACT_APP_PIXEL_ID_SMARTSAVER) {
      return;
    }

    ReactPixel.init(REACT_APP_PIXEL_ID_SMARTSAVER);
    ReactPixel.pageView();
  }, [isProduction, appId, REACT_APP_PIXEL_ID_SMARTSAVER]);

  // Adservice Master Tag Initialization
  const initAdserviceTag = useCallback(() => {
    if (
      !isProduction ||
      appId !== AppId.SMARTSAVER ||
      document.getElementById('adservice-master-tag')
    ) {
      return;
    }

    // Create and configure the Adservice Master Tag script
    const scriptTag = document.createElement('script');
    scriptTag.id = 'adservice-master-tag';
    scriptTag.src = 'https://www.aservice.cloud/trc/mastertag';
    scriptTag.async = true;

    // Append the script to the document head
    document.head.appendChild(scriptTag);

    window.asData = window.asData || [];

    window.atag = function () {
      if (window.asData) {
        window.asData.push(arguments);
      }
    };

    window.atag('init');
    window.atag('track', 'pageview');

    // Cleanup function to remove the script if the component unmounts
    return () => {
      document.head.removeChild(scriptTag);
      delete window.asData;
      delete window.atag;
    };
  }, [isProduction]);

  useEffect(() => {
    initGTM();
    initPixel();
    initAdserviceTag();
  }, [initGTM, initPixel, initAdserviceTag]);
};

export default useInitTrackers;
