import { FC, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { IntercomProvider } from 'react-use-intercom';

import { initSourcebuster } from '@smartsaver/services/sourcebuster';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import Router from './Router';
import Intercom from './components/intercom/Intercom';
import { useLocale } from './hooks/useLocale';
import useMessages from './hooks/useMessages';

const App: FC = (): JSX.Element | null => {
  const { locale } = useLocale();
  const { messages, messagesLoading } = useMessages();
  const queryClient = new QueryClient();
  const { REACT_APP_INTERCOM_ID_SMARTSAVER } = process.env;

  useEffect(() => {
    // TODO: uncomment when sourcebuster issue is fixed
    // initSourcebuster();
  }, []);

  if (!messages || messagesLoading) {
    return null;
  }
  return (
    <IntlProvider locale={locale} messages={messages}>
      <QueryClientProvider client={queryClient}>
        <IntercomProvider appId={REACT_APP_INTERCOM_ID_SMARTSAVER ?? ''} autoBoot={false}>
          <Intercom />
          <Router />
        </IntercomProvider>
      </QueryClientProvider>
    </IntlProvider>
  );
};

export default App;
