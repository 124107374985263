import { ReactNode, useMemo } from 'react';

import classNames from 'classnames';
import clsx from 'clsx';

interface ImageCardProps {
  children: ReactNode;
  imageSrc?: string;
  imageText?: string;
  hasSmallerImageText?: boolean;
  imageSubText?: string;
  maxWClassName?: string;
  changeFlexDirectionOnLg?: boolean;
  imagePosition?: 'left' | 'right';
  hideImageOnMobile?: boolean;
  childrenPaddingClassNames?: string;
}

const ImageCard = ({
  children,
  imageSrc,
  imageText,
  imageSubText,
  hasSmallerImageText = false,
  maxWClassName,
  changeFlexDirectionOnLg = false,
  imagePosition = 'left',
  hideImageOnMobile = false,
}: ImageCardProps) => {
  const textSizeClass = hasSmallerImageText ? 'sm:text-[55px]' : 'sm:text-[65px]';
  const imageOnLeftSide = useMemo(() => imagePosition === 'left', [imagePosition]);
  const imageOnRightSide = useMemo(() => imagePosition === 'right', [imagePosition]);

  return (
    <div
      className={clsx(
        'flex min-h-[500px] w-full flex-col items-stretch overflow-hidden rounded-3xl',
        maxWClassName ?? 'max-w-[1000px]',
        {
          'lg:flex-row': changeFlexDirectionOnLg && imageOnLeftSide,
          'lg:flex-row-reverse': changeFlexDirectionOnLg && imageOnRightSide,
          'sm:flex-row lg:flex-row': !changeFlexDirectionOnLg && imageOnLeftSide,
          'sm:flex-row-reverse lg:flex-row-reverse': !changeFlexDirectionOnLg && imageOnRightSide,
        }
      )}
    >
      {imageSrc && (
        <div
          style={{
            backgroundImage: `url(${imageSrc})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
          className={clsx(
            {
              'hidden sm:flex': hideImageOnMobile,
              'lg:rounded-bl-3xl lg:rounded-tr-none': changeFlexDirectionOnLg && imageOnLeftSide,
              'sm:rounded-bl-3xl sm:rounded-tr-none': !changeFlexDirectionOnLg && imageOnLeftSide,
              'lg:rounded-br-3xl lg:rounded-tl-none': changeFlexDirectionOnLg && imageOnRightSide,
              'sm:rounded-br-3xl sm:rounded-tl-none': !changeFlexDirectionOnLg && imageOnRightSide,
            },
            'flex h-auto min-h-[250px] w-full min-w-0 flex-col items-center justify-end gap-6 rounded-t-3xl bg-black p-2 lg:p-12',
            'flex-1'
          )}
        >
          {!!imageText && (
            <div
              className={classNames(
                textSizeClass,
                'text-center text-[45px] font-black leading-[55px] text-white'
              )}
              style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' }}
            >
              {imageText}
              {!!imageSubText && (
                <p
                  style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 1)' }}
                  className="text-[20px] leading-normal"
                >
                  {imageSubText}
                </p>
              )}
            </div>
          )}
        </div>
      )}
      <div
        className={clsx(
          {
            'lg:rounded-bl-none lg:rounded-tr-3xl': changeFlexDirectionOnLg && imageOnLeftSide,
            'sm:rounded-bl-none sm:rounded-tr-3xl': !changeFlexDirectionOnLg && imageOnLeftSide,
            'lg:rounded-br-none lg:rounded-tl-3xl': changeFlexDirectionOnLg && imageOnRightSide,
            'sm:rounded-br-none sm:rounded-tl-3xl': !changeFlexDirectionOnLg && imageOnRightSide,
          },
          'flex h-auto min-w-0 flex-col items-center justify-between rounded-b-3xl bg-white p-5 sm:px-6 sm:py-12 lg:p-12',
          'flex-1'
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default ImageCard;
