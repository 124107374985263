import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  title: {
    defaultMessage: 'Almost there ⚡️',
  },
  subTitle: {
    defaultMessage: 'Please complete your profile.',
  },
  generalInfo: {
    defaultMessage: 'General information',
  },
  companyName: {
    defaultMessage: 'Company name',
  },
  companyRegNumber: {
    defaultMessage: 'Company registration number',
  },
  companyCountry: {
    defaultMessage: 'Company country',
  },
  companyCountryAddressLabel: {
    defaultMessage: 'Select country',
  },
  addressTitle: {
    defaultMessage: 'Legal address of company',
  },
  representativeTitle: {
    defaultMessage: 'Representative information',
  },
  identityNumber: {
    defaultMessage: 'National identity number',
  },
  taxResidency: {
    defaultMessage: 'Tax residency',
  },
  representativeContactTitle: {
    defaultMessage: 'Representative contact',
  },
  firstName: {
    defaultMessage: 'First name',
  },
  lastName: {
    defaultMessage: 'Last name',
  },
  birthDay: {
    defaultMessage: 'Birthday',
  },
  selectCountry: {
    defaultMessage: 'Select country',
  },
  address: {
    defaultMessage: 'Address',
  },
  city: {
    defaultMessage: 'City',
  },
  postalCode: {
    defaultMessage: 'Postal code',
  },
  promoCodeTitle: {
    defaultMessage: 'Promo code (optional)',
  },
  promoCodeLabel: {
    defaultMessage: 'Enter a promo code',
  },
  fieldIsMandatory: {
    defaultMessage: 'This field is required',
  },
  ctaButton: {
    defaultMessage: 'Next',
  },
  dataProtected: {
    defaultMessage: 'Your data is protected 🔒',
    description: '@smartsaver Your data is protected 🔒',
  },
});
