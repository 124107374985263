import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  headingText: {
    defaultMessage: 'Invoice paid',
    description: '@monefit-es Invoice paid',
  },
  descriptionText: {
    defaultMessage: 'You have paid {amount}',
    description: '@monefit-es You have paid with amount placeholder',
  },
  buttonText: {
    defaultMessage: 'Done',
  },
});
