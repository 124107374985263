import { FC, ReactNode } from 'react';

interface SignupFormSectionProps {
  children?: ReactNode;
  title?: string;
}

const SignupFormSection: FC<SignupFormSectionProps> = ({ title, children }) => {
  return (
    <div className="flex size-full flex-col gap-3">
      {!!title && <div className="text-lg">{title}</div>}
      {children ?? <></>}
    </div>
  );
};

export default SignupFormSection;
