import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useSelector } from '@common/hooks';
import { selectFlowInstance } from '@common/redux/selectors';
import MainLayout from '@monefit-es/components/layout/MainLayout';
import PhoneSubmitForm from '@monefit-es/components/phone-submit-form/PhoneSubmitForm';

const PhonePage = () => {
  const { formatMessage } = useIntl();
  const flowInstanceData = useSelector(selectFlowInstance);
  const [searchParams] = useSearchParams();
  const verifiedEmail = searchParams.get('verified');

  const isResumed = useMemo(
    () => !!flowInstanceData.data?.isResumed,
    [flowInstanceData.data?.isResumed]
  );

  useEffect(() => {
    if (!isResumed && verifiedEmail === '1') {
      toast.success(
        formatMessage({
          defaultMessage: 'Email verified',
          description: '@monefit-es Email verified',
        })
      );
    }
  }, [isResumed, verifiedEmail, formatMessage]);

  return (
    <MainLayout type="onboarding">
      <PhoneSubmitForm />
    </MainLayout>
  );
};

export default PhonePage;
