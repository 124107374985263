import { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';

import Layout from '@common/components/layout/Layout';
import BlackDotsLoader from '@common/components/loader/BlackDotsLoader';
import { useApplicationFlowInstance, useDispatch } from '@common/hooks';
import { RootDispatch } from '@common/redux';
import { setShowLoader } from '@common/redux/slices/application';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions, FlowSteps } from '@common/services/application';

// eslint-disable-next-line boundaries/no-private
import m from './DrawdownDecisionPage.message';

const DrawdownDecisionPage = () => {
  const [instanceId] = useApplicationFlowInstance();
  const dispatch = useDispatch<RootDispatch>();
  const { formatMessage } = useIntl();

  const submitDecision = useCallback(async () => {
    await dispatch(performApplicationFlowAction({ action: FlowActions.SUBMIT, instanceId }))
      .unwrap()
      .then((r) => {
        if (r.currentStepId !== FlowSteps.DECISION) {
          dispatch(setShowLoader(true));
        }
      });
  }, [instanceId, dispatch]);

  useEffect(() => {
    dispatch(setShowLoader(false));
    submitDecision();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const componentToShow = useMemo(() => {
    return (
      <div className="inline-flex w-full flex-col items-start justify-between rounded-3xl bg-white p-7 backdrop-blur-lg">
        <div className="flex flex-col items-center justify-end gap-5 self-stretch py-20">
          <div className="flex justify-center">
            <BlackDotsLoader style={{ height: 60 }} />
          </div>

          <div className="self-stretch text-center text-base font-medium leading-none tracking-tight text-black">
            {formatMessage(m.pendingMessage)}
          </div>
        </div>
      </div>
    );
  }, [formatMessage]);

  return (
    <Layout.WithNavigation
      maxWDesktop={545}
      hideProgressBar
      backButtonText={formatMessage({
        defaultMessage: 'In progress',
        description: 'Monefit-ES: In progress',
      })}
      loaderType="empty"
    >
      {componentToShow}
    </Layout.WithNavigation>
  );
};

export default DrawdownDecisionPage;
