import { FC } from 'react';

import { inputErrorStyles } from './InputError.styles';

interface InputErrorProps {
  message: string;
}

const InputError: FC<InputErrorProps> = ({ message }) => {
  return <div className={inputErrorStyles.base}>{message}</div>;
};

export default InputError;
