import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import Button from '@common/components/button/Button';
import ImageCard from '@common/components/image-card/ImageCard';
import { Currency, CurrencySymbol } from '@common/constants';
import { useApplicationFlowInstance, useDispatch, useLocale, useSelector } from '@common/hooks';
import useEnvironment from '@common/hooks/useEnvironment';
import { RootDispatch } from '@common/redux';
import { selectFlowInstance } from '@common/redux/selectors';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions } from '@common/services/application';
import { getPublicUrl } from '@common/utils';
import monthioLogo from '@src/assets/img/common/monthio-logo.png';
import monthioCardSuccessImg from '@src/assets/img/monefit-es/monthio-card-success.webp';

import m from './BankStatementCard.messages';

interface BankStatementCardProps {
  setRedirectUrl: (v: string) => void;
}

const BankStatementCard = ({ setRedirectUrl }: BankStatementCardProps) => {
  const { formatMessage, formatNumber } = useIntl();
  const { localeWithCountry } = useLocale();
  const { environment } = useEnvironment();
  const [instanceId] = useApplicationFlowInstance();
  const dispatch = useDispatch<RootDispatch>();
  const data = useSelector(selectFlowInstance);

  const limit = useMemo(() => data.data?.responseData?.preliminaryLimit, [data.data?.responseData]);

  const handleSubmit = useCallback(async () => {
    const { REACT_APP_ES_APPLICATION_FLOW_ID } = process.env;
    await dispatch(
      performApplicationFlowAction({
        action: FlowActions.SUBMIT,
        instanceId,
        shouldRefreshToken: true,
        data: {
          redirectUrl: `${getPublicUrl(environment ?? 'development')}/${localeWithCountry}/${REACT_APP_ES_APPLICATION_FLOW_ID}/bank`,
        },
      })
    )
      .unwrap()
      .then((res) => {
        setRedirectUrl(res.responseData.redirectUrl);
      });
  }, [dispatch, instanceId, setRedirectUrl, environment, localeWithCountry]);

  return (
    <ImageCard imageSrc={monthioCardSuccessImg} changeFlexDirectionOnLg>
      <div className="flex size-full flex-col justify-normal gap-3 sm:justify-between  sm:gap-6">
        <div className="flex w-full flex-col gap-3">
          <div className="flex flex-col gap-2">
            {!!limit && (
              <div className="font-heavy self-stretch text-4xl font-black leading-none text-black sm:leading-tight">
                {formatMessage(m.headerText, {
                  currency: CurrencySymbol.EUR,
                  amount: formatNumber(limit, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                    currency: Currency.EUR,
                  }),
                })}
              </div>
            )}
          </div>
          <div className="w-full text-base font-medium leading-normal text-black">
            {formatMessage(m.mainText)}
          </div>
        </div>
        <div className="mt-5 flex w-full flex-col gap-6 sm:mt-0">
          <Button onClick={handleSubmit} text={formatMessage(m.buttonText)} />

          <div className="flex w-full flex-col gap-3.5 sm:flex-row">
            <div className="inline-flex flex-col items-start justify-start gap-2.5 pt-0.5">
              <img className="h-4 w-20 mix-blend-luminosity" src={monthioLogo} alt="monthio-logo" />
            </div>
            <div className="shrink grow basis-0">
              <div className="text-sm font-medium text-black text-opacity-60 ">
                {formatMessage(m.monthioPrivacyText)} &nbsp;
                <a
                  className="text-sm font-medium text-black underline"
                  rel="noreferrer"
                  target="_blank"
                  href="https://monefit.com/wp-content/uploads/2024/10/monthios_privacy_policy_ES_07.05.2024.pdf"
                >
                  {formatMessage(m.privacyPolicy)}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ImageCard>
  );
};

export default BankStatementCard;
