import { IconProps } from './types';

const IconUp = ({ width = '24', height = '24', strokeWidth = '1.5', className }: IconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 15L12.7809 9.66939C12.3316 9.2842 11.6684 9.2842 11.2191 9.66939L5 15"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IconUp;
