import { FC, ReactNode } from 'react';

import BlackDotsLoader from '@common/components/loader/BlackDotsLoader';
import LoadingCard from '@common/components/loader/LoadingCard';
import main_loader from '@src/assets/img/monefit-es/main_loader.webp';
import clsx from 'clsx';

export interface MainLoaderProps {
  showIndicator?: boolean;
  extraClassNames?: string;
  imageSrc?: string;
  text?: string;
  gradientOpacity?: number;
  hasTextShadow?: boolean;
  textNode?: ReactNode;
  type?: 'dots' | 'image' | 'empty';
  heightClassName?: string;
}

const MainLoader: FC<MainLoaderProps> = ({
  showIndicator = true,
  extraClassNames,
  imageSrc,
  text,
  gradientOpacity,
  hasTextShadow = true,
  textNode,
  type = 'dots',
  heightClassName,
}) => {
  if (type === 'image') {
    return (
      <div className="flex w-full items-center justify-center text-black">
        <LoadingCard
          imgSrcProp={imageSrc ?? main_loader}
          {...{ extraClassNames, text, gradientOpacity, showIndicator, hasTextShadow, textNode }}
        />
      </div>
    );
  }
  if (type === 'dots') {
    return (
      <div
        className={clsx(
          'flex  w-full items-center justify-center text-black',
          heightClassName ?? 'min-h-svh'
        )}
      >
        <BlackDotsLoader style={{ height: 40 }} />
      </div>
    );
  }

  if (type === 'empty') {
    return null;
  }

  return null;
};

export default MainLoader;
