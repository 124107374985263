import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Navigate, useNavigate } from 'react-router-dom';

import AccountLoader from '@common/components/account-loader/AccountLoader';
import Button from '@common/components/button/Button';
import SuccessIcon from '@common/components/icons/SuccessIcon';
import Layout from '@common/components/layout/Layout';
import { useLocale } from '@common/hooks';
import useMonthioUrlParams from '@common/hooks/useMonthioUrlParams';
import { getPaymentByTransactionID } from '@common/services/payment';

import m from './PaymentSuccessPage.messages';

const PaymentSuccessPage = () => {
  const { paymentId, paymentSessionId } = useMonthioUrlParams();
  const [successFullPaymentAmount, setSuccessFullPaymentAmount] = useState<{
    amount: string;
    currency: string;
  }>({ amount: '', currency: '' });
  const { formatMessage, formatNumber } = useIntl();
  const { localeWithCountry } = useLocale();
  const navigate = useNavigate();

  const handleBackClick = useCallback(() => {
    navigate(`/${localeWithCountry}/user`);
  }, [navigate, localeWithCountry]);

  useEffect(() => {
    if (!paymentId || successFullPaymentAmount.amount) {
      return;
    }
    let retryCount = 0;
    const interval = setInterval(async () => {
      const payment = await getPaymentByTransactionID(paymentId);

      retryCount++;

      if (retryCount > 5) {
        clearInterval(interval);
        return;
      }

      if (!payment) {
        return;
      }

      clearInterval(interval);
      setSuccessFullPaymentAmount({ amount: payment.amount, currency: payment.currency });
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [paymentId, successFullPaymentAmount]);

  if (!paymentId && !paymentSessionId) {
    return <Navigate to={`/${localeWithCountry}/user`} />;
  }

  return (
    <Layout.WithNavigation
      maxWDesktop={545}
      backButtonText={formatMessage(m.headingText)}
      onBackButtonClick={handleBackClick}
    >
      <div className="inline-flex w-full flex-col items-start justify-between rounded-3xl bg-white p-7 backdrop-blur-lg">
        {!successFullPaymentAmount.amount && <AccountLoader />}
        {!!successFullPaymentAmount.amount && (
          <>
            <div className="mt-10 flex flex-col items-center justify-end gap-5 self-stretch">
              <SuccessIcon />
              <div className="mt-2 self-stretch text-center text-base font-medium leading-none tracking-tight text-black">
                {formatMessage(m.descriptionText, {
                  amount: formatNumber(+successFullPaymentAmount.amount, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                    currency: successFullPaymentAmount.currency,
                    currencyDisplay: 'symbol',
                    style: 'currency',
                  }),
                })}
              </div>
            </div>
            <div className="mt-24 w-full">
              <Button fullWidth onClick={handleBackClick} text={formatMessage(m.buttonText)} />
            </div>
          </>
        )}
      </div>
    </Layout.WithNavigation>
  );
};

export default PaymentSuccessPage;
