import { useCallback } from 'react';
import { Navigate } from 'react-router-dom';

import { useLocale } from '@common/hooks';
import useFlowIdFromUrl from '@common/hooks/useFlowIdFromUrl';
import DrawdownDecisionPage from '@monefit-es/pages/drawdown-decision-page/DrawdownDecisionPage';
import OnboardingDecisionPage from '@monefit-es/pages/onboarding-decision-page/OnboardingDecisionPage';

const DecisionPage = () => {
  const { flowId } = useFlowIdFromUrl();
  const { localeWithCountry } = useLocale();

  const renderDesicionPage = useCallback(() => {
    const { REACT_APP_ES_APPLICATION_FLOW_ID, REACT_APP_ES_DRAWDOWN_FLOW_ID } = process.env;

    switch (flowId) {
      case REACT_APP_ES_APPLICATION_FLOW_ID:
        return <OnboardingDecisionPage />;
      case REACT_APP_ES_DRAWDOWN_FLOW_ID:
        return <DrawdownDecisionPage />;
      default:
        return <Navigate replace to={`/${localeWithCountry}/user`} />;
    }
  }, [flowId, localeWithCountry]);

  return <>{renderDesicionPage()}</>;
};

export default DecisionPage;
