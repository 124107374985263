import { useEffect, useState } from 'react';

import { LocalStorageKeys } from '@common/constants';
import { AppId } from '@common/hooks';

import { useLocale } from './useLocale';

const useMessages = () => {
  const { locale } = useLocale();

  const [messages, setMessages] = useState({});
  const [messagesLoading, setMessagesLoading] = useState(false);

  useEffect(() => {
    const loadMessages = async () => {
      const localeToCheck =
        sessionStorage.getItem(`${LocalStorageKeys.LOCALE}_${AppId.SMARTSAVER}`) ?? locale ?? null;
      setMessagesLoading(true);
      try {
        if (localeToCheck) {
          const loadedMessages = await import(
            `@translations/${AppId.SMARTSAVER}/${localeToCheck}.json`
          );
          setMessages(loadedMessages);
        }
      } catch (e) {
        console.warn(e);
      }
      setMessagesLoading(false);
    };

    loadMessages();
  }, [locale]);

  return {
    messages,
    messagesLoading,
  };
};

export default useMessages;
