import { useMemo } from 'react';
import { useIntl } from 'react-intl';

export interface CountryMap {
  fullName: string;
  isoName: string;
  emoji: string;
}

const isFlagEmojiSupported = (isoName: string, emoji: string) => {
  return isoName !== emoji;
};

const useCountries = () => {
  const { formatMessage } = useIntl();
  const countries: CountryMap[] = useMemo(
    () => [
      { fullName: formatMessage({ defaultMessage: 'Germany' }), isoName: 'DE', emoji: '🇩🇪' },
      { fullName: formatMessage({ defaultMessage: 'Spain' }), isoName: 'ES', emoji: '🇪🇸' },
      { fullName: formatMessage({ defaultMessage: 'France' }), isoName: 'FR', emoji: '🇫🇷' },
      { fullName: formatMessage({ defaultMessage: 'Estonia' }), isoName: 'EE', emoji: '🇪🇪' },
      { fullName: formatMessage({ defaultMessage: 'Austria' }), isoName: 'AT', emoji: '🇦🇹' },
      { fullName: formatMessage({ defaultMessage: 'Belgium' }), isoName: 'BE', emoji: '🇧🇪' },
      { fullName: formatMessage({ defaultMessage: 'Bulgaria' }), isoName: 'BG', emoji: '🇧🇬' },
      { fullName: formatMessage({ defaultMessage: 'Croatia' }), isoName: 'HR', emoji: '🇭🇷' },
      {
        fullName: formatMessage({ defaultMessage: 'Republic of Cyprus' }),
        isoName: 'CY',
        emoji: '🇨🇾',
      },
      { fullName: formatMessage({ defaultMessage: 'Czech Republic' }), isoName: 'CZ', emoji: '🇨🇿' },
      { fullName: formatMessage({ defaultMessage: 'Denmark' }), isoName: 'DK', emoji: '🇩🇰' },
      { fullName: formatMessage({ defaultMessage: 'Finland' }), isoName: 'FI', emoji: '🇫🇮' },
      { fullName: formatMessage({ defaultMessage: 'Greece' }), isoName: 'GR', emoji: '🇬🇷' },
      { fullName: formatMessage({ defaultMessage: 'Hungary' }), isoName: 'HU', emoji: '🇭🇺' },
      { fullName: formatMessage({ defaultMessage: 'Ireland' }), isoName: 'IE', emoji: '🇮🇪' },
      { fullName: formatMessage({ defaultMessage: 'Italy' }), isoName: 'IT', emoji: '🇮🇹' },
      { fullName: formatMessage({ defaultMessage: 'Latvia' }), isoName: 'LV', emoji: '🇱🇻' },
      { fullName: formatMessage({ defaultMessage: 'Lithuania' }), isoName: 'LT', emoji: '🇱🇹' },
      { fullName: formatMessage({ defaultMessage: 'Luxembourg' }), isoName: 'LU', emoji: '🇱🇺' },
      { fullName: formatMessage({ defaultMessage: 'Malta' }), isoName: 'MT', emoji: '🇲🇹' },
      { fullName: formatMessage({ defaultMessage: 'Netherlands' }), isoName: 'NL', emoji: '🇳🇱' },
      { fullName: formatMessage({ defaultMessage: 'Poland' }), isoName: 'PL', emoji: '🇵🇱' },
      { fullName: formatMessage({ defaultMessage: 'Portugal' }), isoName: 'PT', emoji: '🇵🇹' },
      { fullName: formatMessage({ defaultMessage: 'Romania' }), isoName: 'RO', emoji: '🇷🇴' },
      { fullName: formatMessage({ defaultMessage: 'Slovakia' }), isoName: 'SK', emoji: '🇸🇰' },
      { fullName: formatMessage({ defaultMessage: 'Slovenia' }), isoName: 'SI', emoji: '🇸🇮' },
      { fullName: formatMessage({ defaultMessage: 'Sweden' }), isoName: 'SE', emoji: '🇸🇪' },
      { fullName: formatMessage({ defaultMessage: 'Iceland' }), isoName: 'IS', emoji: '🇮🇸' },
      { fullName: formatMessage({ defaultMessage: 'Liechtenstein' }), isoName: 'LI', emoji: '🇱🇮' },
      { fullName: formatMessage({ defaultMessage: 'Norway' }), isoName: 'NO', emoji: '🇳🇴' },
      { fullName: formatMessage({ defaultMessage: 'Switzerland' }), isoName: 'CH', emoji: '🇨🇭' },
    ],
    [formatMessage]
  );

  const countriesOptionsForSelect = useMemo(
    () =>
      countries.map((c) => ({
        label: isFlagEmojiSupported(c.isoName, c.emoji) ? `${c.emoji} ${c.fullName}` : c.fullName,
        value: c.isoName,
      })),
    [countries]
  );

  return { countries, countriesOptionsForSelect };
};

export default useCountries;
