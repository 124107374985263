const PencilIcon = () => {
  return (
    <svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.166016" width="34" height="34" rx="17" fill="#F8F5F2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3815 9.08775C19.4546 8.01299 21.1951 8.01233 22.2691 9.08628L24.7609 11.5781C25.8257 12.6429 25.8368 14.3667 24.7857 15.445L15.5526 24.9183C14.8471 25.6422 13.8794 26.0504 12.869 26.0503L10.1168 26.0502C8.83758 26.0501 7.81597 24.9838 7.86977 23.7047L7.98792 20.8956C8.02742 19.9566 8.4177 19.0666 9.08154 18.4018L18.3815 9.08775ZM21.2092 10.1477C20.7211 9.65958 19.9299 9.65988 19.4421 10.1484L17.779 11.814L22.0588 16.0938L23.7123 14.3973C24.1901 13.9071 24.1851 13.1236 23.7011 12.6396L21.2092 10.1477ZM10.1422 19.4624L16.7192 12.8755L21.0118 17.1681L14.4792 23.8705C14.0559 24.3049 13.4753 24.5498 12.869 24.5497L10.1169 24.5496C9.69048 24.5496 9.34994 24.1941 9.36787 23.7678L9.48603 20.9588C9.50973 20.3953 9.74389 19.8613 10.1422 19.4624ZM25.3826 25.977C25.7966 25.977 26.1323 25.6411 26.1323 25.2267C26.1323 24.8124 25.7966 24.4764 25.3826 24.4764H19.2608C18.8468 24.4764 18.5111 24.8124 18.5111 25.2267C18.5111 25.6411 18.8468 25.977 19.2608 25.977H25.3826Z"
        fill="#5C554D"
      />
    </svg>
  );
};

export default PencilIcon;
