import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  headerText: {
    defaultMessage: 'Your finances',
    description: '@monefit-es Your finances',
  },
  subHeaderText: {
    defaultMessage: 'Tell us about your regular income and expenses and other commitments please. ',
    description:
      '@monefit-es Tell us about your regular income and expenses and other commitments please. ',
  },
  continue: {
    defaultMessage: 'Continue',
    description: '@monefit-es Continue',
  },
  mainSourceIncomeText: {
    defaultMessage: 'What’s your main source of income?',
    description: '@monefit-es What’s your main source of income?',
  },
  employmentTypeLabel: {
    defaultMessage: 'Employment type',
    description: 'Employment type',
  },
  employerLabel: {
    defaultMessage: 'Employer',
    description: '@monefit-es Employer',
  },
  monthlyIncomeLablel: {
    defaultMessage: 'Net per month (€)',
    description: '@monefit-es Net per month (€)',
  },
  taxIdentificationNumberLabel: {
    defaultMessage: 'Your registration number',
    description: '@monefit-es Your registration number',
  },
  hasAdditionalIncomeLabel: {
    defaultMessage: 'Do you have other sources of income?',
    description: '@monefit-es Do you have other sources of income?',
  },
  additionalIncomeSourceLabel: {
    defaultMessage: 'Source',
    description: 'Source',
  },
  additionalMonthlyNetIncome: {
    defaultMessage: 'Net per month (€)',
    description: '@monefit-es Net per month (€)',
  },
  addButtonText: {
    defaultMessage: 'Add more',
    description: 'Add more',
  },
  totalMonthlyExpensesText: {
    defaultMessage: 'Total monthly expenses',
    description: '@monefit-es Total monthly expenses',
  },
  monthlyExpensesHelpText: {
    defaultMessage: 'Your regular bills like utility, mobile phone, rent etc.',
    description: '@monefit-es Your regular bills like utility, mobile phone, rent etc.',
  },
  monthlyExpensesLabel: {
    defaultMessage: 'Home expences per month (€)',
    description: '@monefit-es Home expences per month (€)',
  },
  hasActiveCreditsLabel: {
    defaultMessage: 'Do you have any active loans or credit cards?',
    description: 'Do you have any active loans or credit cards?',
  },
  activeCreditAmountLabel: {
    defaultMessage: 'Regular credit expenses (€)',
  },
  mandatoryFieldError: {
    defaultMessage: 'This field cannot be empty',
  },
  minimumFieldError: {
    defaultMessage: ' The amount must be 1 or higher',
  },
  dropdownSelectLabel: {
    defaultMessage: 'Employment type',
  },
  sourceDropdownSelectLabel: {
    defaultMessage: 'Source',
  },
});
