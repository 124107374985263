import { Route, Routes } from 'react-router-dom';

import { SmartSaverRoute } from './constants/routes';
import { useLocale } from './hooks/useLocale';
import CompanySignupPage from './pages/company-signup-page/CompanySignupPage';
import ForgotPasswordPage from './pages/forgot-password-page/ForgotPasswordPage';
import IndividualSignupPage from './pages/individual-signup-page/IndividualSignUpPage';
import LoginPage from './pages/login-page/LoginPage';
import SignupCompanyDocumentsPage from './pages/signup-company-documents-page/SignupCompanyDocumentsPage';
import SignUpPage from './pages/signup-page/SignupPage';

const Router = () => {
  const { locale, allowedLocales } = useLocale();

  if (!locale || !allowedLocales) {
    return <></>;
  }

  return (
    <Routes>
      <Route path={`${locale}/${SmartSaverRoute.LOGIN}`} element={<LoginPage />} />
      <Route path={`${locale}/${SmartSaverRoute.SIGNUP}`} element={<SignUpPage />} />
      <Route
        path={`${locale}/${SmartSaverRoute.FORGOT_PASSWORD}`}
        element={<ForgotPasswordPage />}
      />
      <Route
        path={`${locale}/${SmartSaverRoute.SIGNUP_INDIVIDUAL}`}
        element={<IndividualSignupPage />}
      />
      <Route path={`${locale}/${SmartSaverRoute.SIGNUP_COMPANY}`} element={<CompanySignupPage />} />
      <Route
        path={`${locale}/${SmartSaverRoute.SIGNUP_COMPANY_DOCUMENTS}`}
        element={<SignupCompanyDocumentsPage />}
      />
      <Route path="*" element={<>Page not found</>} />
    </Routes>
  );
};

export default Router;
