import { useIntl } from 'react-intl';

import CompanyForm from '@smartsaver/components/company-form/CompanyForm';
import MainLayout from '@smartsaver/components/main-layout/MainLayout';

const CompanySignupPage = () => {
  const { formatMessage } = useIntl();
  return (
    <MainLayout pageTitle={formatMessage({ defaultMessage: 'Sign up' })}>
      <CompanyForm />
    </MainLayout>
  );
};

export default CompanySignupPage;
