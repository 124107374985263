import { useEffect, useMemo, useState } from 'react';

export enum TWBreakPoint {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  XXL = '2xl',
}

const breakpoints = {
  [TWBreakPoint.XS]: 450,
  [TWBreakPoint.SM]: 640,
  [TWBreakPoint.MD]: 768,
  [TWBreakPoint.LG]: 1024,
  [TWBreakPoint.XL]: 1280,
  [TWBreakPoint.XXL]: 1536,
};

type Breakpoint = keyof typeof breakpoints;

const useTailwindBreakpoints = () => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState<Breakpoint | null>(null);

  const getCurrentBreakpoint = () => {
    const width = window.innerWidth;

    if (width >= breakpoints[TWBreakPoint.XXL]) {
      return TWBreakPoint.XXL;
    }
    if (width >= breakpoints[TWBreakPoint.XL]) {
      return TWBreakPoint.XL;
    }
    if (width >= breakpoints[TWBreakPoint.LG]) {
      return TWBreakPoint.LG;
    }
    if (width >= breakpoints[TWBreakPoint.MD]) {
      return TWBreakPoint.MD;
    }
    if (width >= breakpoints[TWBreakPoint.SM]) {
      return TWBreakPoint.SM;
    }
    if (width <= breakpoints[TWBreakPoint.XS]) {
      return TWBreakPoint.XS;
    }

    return TWBreakPoint.SM;
  };

  const isMobile = useMemo(
    () => !!currentBreakpoint && [TWBreakPoint.XS, TWBreakPoint.SM].includes(currentBreakpoint),
    [currentBreakpoint]
  );

  useEffect(() => {
    const handleResize = () => {
      setCurrentBreakpoint(getCurrentBreakpoint());
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    breakpoint: currentBreakpoint,
    isMobile,
  };
};

export default useTailwindBreakpoints;
