import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  title: {
    defaultMessage: 'Forgot password? ',
  },
  subTitle: {
    defaultMessage:
      'Please enter your registered e-mail address to receive instructions on restoring your password',
  },
  emailLabel: {
    defaultMessage: 'Enter your email',
  },
  passwordRequired: {
    defaultMessage: 'Password cannot be blank',
  },
  blankEmail: {
    defaultMessage: 'Email cannot be blank',
  },
  wrongEmail: {
    defaultMessage: 'Email is not a valid email address',
  },
  ctaButton: {
    defaultMessage: 'Renew password',
  },
  successMessage: {
    defaultMessage: 'Check your email for further instructions.',
  },
});
