import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  title: {
    defaultMessage: 'Get started ⚡️',
    description: '@smartsaver Get started',
  },
  subTitle: {
    defaultMessage: 'Enter your email and create a password',
    description: '@smartsaver Enter your email and create a password',
  },
  emailLabel: {
    defaultMessage: 'Email',
  },
  passwordLabel: {
    defaultMessage: 'Set password',
  },
  consentLabel: {
    defaultMessage:
      'I confirm that I have read and agreed to the {termsOfUselink} and {privacyPolicyLink}.',
    description:
      '@smartsaver I confirm that i have read and agreed to the {termsOfUselink} and {privacyPolicyLink} ',
  },
  termsOfUse: {
    defaultMessage: 'Terms of Use',
  },
  privacyPolicy: {
    defaultMessage: 'Privacy Policy',
  },
  haveAccount: {
    defaultMessage: 'Already have account? {loginLink}',
    description: '@smartsaver Already have account? {loginLink}',
  },
  login: {
    defaultMessage: 'Login',
  },
  cancelEmail: {
    defaultMessage:
      'If you do not want to receive our news and other useful information, you can change your preferences in your profile settings later.',
    description:
      '@smartsaver If you do not want to receive our news and other useful information, you can change your preferences in your profile settings later.',
  },
  footer: {
    defaultMessage: 'Your data is protected 🔒',
    description: '@smartsaver Your data is protected 🔒',
  },
  continue: {
    defaultMessage: 'Continue',
  },

  // Signup form validation messages
  blankEmail: {
    defaultMessage: 'Email cannot be blank',
  },
  wrongEmail: {
    defaultMessage: 'Email is not a valid email address',
  },
  shortPassword: {
    defaultMessage: 'Password should contain at least 8 characters',
  },
  passwordUpperCaseMissing: {
    defaultMessage: 'Password should contain at least one upper case character',
  },
  passwordLowerCaseMissing: {
    defaultMessage: 'Password should contain at least one lower case character',
  },
  passwordNumberMissing: {
    defaultMessage: 'Password should contain at least one numeric / digit character',
  },
  passwordRequired: {
    defaultMessage: 'Password cannot be blank',
  },
  consentMissing: {
    defaultMessage: 'Please agree to the Terms of Use and Privacy Policy to continue',
  },
});
