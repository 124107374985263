import useInitialFlowActionPerform from '@common/hooks/useInitialFlowActionPerform';
import MainLayout from '@monefit-es/components/layout/MainLayout';
import QuestionnaireForm from '@monefit-es/components/questionnaire-form/QuestionnaireForm';

const Questionnaire = () => {
  useInitialFlowActionPerform({});

  return (
    <MainLayout type="onboarding">
      <QuestionnaireForm />
    </MainLayout>
  );
};

export default Questionnaire;
