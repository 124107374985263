import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import useOptimove from '@common/hooks/useOptimove';
import MainLayout from '@smartsaver/components/main-layout/MainLayout';
import SignUpForm from '@smartsaver/components/sign-up-form/SignUpForm';
import { SignupFormSchema } from '@smartsaver/components/sign-up-form/SignUpForm.schema';
import { OPTIMOVE_EVENTS } from '@smartsaver/constants/optimoveEvents';
import { SmartSaverRoute } from '@smartsaver/constants/routes';
import { useLocale } from '@smartsaver/hooks/useLocale';
import { initRegister } from '@smartsaver/services/register';
import { useMutation } from '@tanstack/react-query';

const SignUpPage = () => {
  const { reportEvent } = useOptimove();
  const navigate = useNavigate();
  const { locale } = useLocale();
  const { formatMessage } = useIntl();

  const signupMutation = useMutation({
    mutationFn: (v: SignupFormSchema) => {
      return initRegister({
        email: v.identifier,
        termsAndPrivacyAccepted: v.consent ? '1' : '0',
        password: v.password,
      });
    },
    onSuccess: (data) => {
      // TODO: Handler
      reportEvent({ eventName: OPTIMOVE_EVENTS.signup });
      navigate(`/${locale}/${SmartSaverRoute.SIGNUP_INDIVIDUAL}`);
    },
    onError: (error) => {
      // TODO: message
      toast.error(String(error));
    },
  });

  const { setPageVisit } = useOptimove();

  useEffect(() => {
    setPageVisit({});
  }, []);

  return (
    <MainLayout
      loadingProp={signupMutation?.isPending}
      pageTitle={formatMessage({ defaultMessage: 'Sign up' })}
    >
      <SignUpForm signupMutation={signupMutation} />
    </MainLayout>
  );
};

export default SignUpPage;
