import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import useTailwindBreakpoints from '@common/hooks/useTailwindBreakpoints';
import ForgotPassowordForm from '@smartsaver/components/forgot-password-form/ForgotPasswordForm';
import m from '@smartsaver/components/forgot-password-form/ForgotPasswordForm.messages';
import { ForgotPasswordFormSchema } from '@smartsaver/components/forgot-password-form/ForgotPasswordForm.schema';
import MainLayout from '@smartsaver/components/main-layout/MainLayout';
import { requestPasswordChange } from '@smartsaver/services/account';
import { useMutation } from '@tanstack/react-query';

const ForgotPasswordPage = () => {
  const { isMobile } = useTailwindBreakpoints();

  const { formatMessage } = useIntl();
  const requestPasswordChangeMutation = useMutation({
    mutationFn: (v: ForgotPasswordFormSchema) => {
      return requestPasswordChange(v.identifier);
    },
    onSuccess: () => {
      toast.success(formatMessage(m.successMessage));
    },
    onError: (error) => {
      // TODO: message
      toast.error(String(error));
    },
  });

  return (
    <MainLayout
      childrenWrapperAlign={isMobile ? 'start' : 'center'}
      loadingProp={requestPasswordChangeMutation?.isPending}
      pageTitle={formatMessage({ defaultMessage: 'Forgot your password?' })}
    >
      <ForgotPassowordForm requestPasswordChangeMutation={requestPasswordChangeMutation} />
    </MainLayout>
  );
};

export default ForgotPasswordPage;
