import SignupLayout from '@common/components/sign-up-layout/SignupLayout';
import useTracker from '@common/hooks/useTracker';
import { TrackEvent } from '@common/utils/amplitude/events';
import ApprovedForm from '@monefit-ee/components/approved-form/ApprovedForm';

const ApprovedPage = () => {
  useTracker({
    initEventName: TrackEvent.APPROVED_PAGE_INIT,
    errorTrackEventName: TrackEvent.APPROVED_PAGE_ERROR,
  });

  return (
    <SignupLayout>
      <ApprovedForm />
    </SignupLayout>
  );
};

export default ApprovedPage;
