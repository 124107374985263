import { useIntl } from 'react-intl';

import DocusignIcon from '@common/components/icons/DocusignIcon';

import m from './DocumentsFooter.messages';

const DocumentsFooter = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="mt-3 inline-flex w-full flex-col items-center justify-start gap-3.5">
      <div className="inline-flex w-full items-start justify-start gap-3.5">
        <div>
          <DocusignIcon />
        </div>
        <div className="text-[0.9rem] font-medium leading-6 text-black text-opacity-60">
          {formatMessage(m.footerText)} &nbsp;
          <a
            className="text-[0.9rem] font-medium text-black underline"
            rel="noreferrer"
            target="_blank"
            href="https://www.docusign.com/es-es/empresa/politica-de-privacidad"
          >
            {formatMessage(m.privacyPolicy)}
          </a>
        </div>
      </div>
    </div>
  );
};

export default DocumentsFooter;
