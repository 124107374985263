import InputError from '@common/components/input-error/InputError';
import { FieldTemplateProps } from '@rjsf/utils';
import classNames from 'classnames';

const FieldTemplate = (props: FieldTemplateProps) => {
  const { description, id, help, style, errors, children } = props;
  const touchedFields = props?.uiSchema?.['ui:touched'];

  const isTouched = touchedFields?.[id] ?? false;
  return (
    <div className={classNames(props.classNames)} style={style}>
      {!!description && <div className="text-lg">{description}</div>}
      {!!help && <div className="my-2 text-sm text-neutral-500">{help}</div>}
      <div className="w-full">{children}</div>
      {isTouched && <InputError message={String(errors)} />}
    </div>
  );
};

export default FieldTemplate;
