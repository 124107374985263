export const TRANSITION_ANIMATION = {
  initial: { opacity: 0, scale: 0.5 },
  animate: { opacity: 1, scale: 1 },
  transition: {
    duration: 0.8,
    delay: 0.2,
    ease: [0, 0.71, 0.2, 1.01],
  },
};

export const MOBILE_MENU_ANIMATION_VARIANTS = {
  open: {
    opacity: 1,
    x: 0,
    transition: {
      type: 'spring',
      stiffness: 100,
      damping: 10,
      mass: 0.5,
    },
  },
  closed: {
    opacity: 0,
    x: '-100%',
    transition: {
      type: 'spring',
      stiffness: 100,
      damping: 10,
      mass: 0.5,
    },
  },
};

export const MENU_BUTTON_ANIMATION_VARIANTS = {
  open: {
    rotate: 90,
    scale: 1.1,
    opacity: 1,
    transition: { duration: 0.3, ease: 'easeOut' },
  },
  closed: {
    rotate: 0,
    scale: 1,
    opacity: 1,
    transition: { duration: 0.3, ease: 'easeIn' },
  },
};

export const SELECT_ANIMATION = {
  initial: { opacity: 0, scale: 0.95, y: -10 },
  animate: { opacity: 1, scale: 1, y: 0 },
  exit: { opacity: 0, scale: 0.95, y: -10 },
  transition: { duration: 0.3, ease: 'easeInOut' },
};
