import { FC } from 'react';

import { IconProps } from './types';

const HidePasswordIcon: FC<IconProps> = ({ width = '25', height = '24' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <g clipPath="url(#clip0_1_483)">
        <path
          d="M1.89131 8.40006C1.55994 7.95823 0.93314 7.86869 0.491312 8.20006C0.0494847 8.53143 -0.0400584 9.15823 0.291312 9.60006C0.666571 10.1004 1.15159 10.6522 1.7519 11.2063L0.25906 13.4453C-0.0473181 13.9048 0.0768202 14.5257 0.536331 14.8321C0.995843 15.1385 1.61672 15.0143 1.9231 14.5548L3.32447 12.453C4.05143 12.9467 4.88248 13.4097 5.82213 13.802L5.12978 16.2255C4.97807 16.7565 5.28557 17.31 5.81661 17.4617C6.34765 17.6134 6.90113 17.3059 7.05284 16.7749L7.71803 14.4465C8.7408 14.7198 9.86395 14.9068 11.0913 14.9733V17C11.0913 17.5523 11.539 18 12.0913 18C12.6436 18 13.0913 17.5523 13.0913 17V14.9733C14.3261 14.9064 15.4554 14.7176 16.4832 14.4415L17.0633 16.7586C17.1974 17.2944 17.7405 17.6199 18.2762 17.4858C18.812 17.3517 19.1375 16.8086 19.0034 16.2729L18.3825 13.7928C19.3131 13.4025 20.1368 12.9429 20.858 12.4531L22.259 14.5548C22.5653 15.0143 23.1862 15.1385 23.6457 14.8322C24.1053 14.5258 24.2295 13.905 23.9232 13.4454L22.4306 11.2064C23.031 10.6523 23.516 10.1004 23.8913 9.60006C24.2227 9.15823 24.1331 8.53143 23.6913 8.20006C23.2495 7.86869 22.6227 7.95823 22.2913 8.40006C20.9433 10.1974 17.7227 12.9965 12.1019 13.0001L12.0913 13L12.0807 13.0001C6.45995 12.9965 3.23931 10.1974 1.89131 8.40006Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_483">
          <rect width={width} height={height} fill="white" transform="translate(0.0913086)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HidePasswordIcon;
