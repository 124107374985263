import { FC, ReactNode, useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import Button, { ButtonSpacing } from '@common/components/button/Button';

export interface YesNoInputProps {
  name: string;
  label?: string;
  yesText?: string;
  noText?: string;
  isBoldLabel?: boolean;
  labelComponent?: ReactNode;
  externalYesHandler?: () => void;
  externalNoHandler?: () => void;
}

const YesNoInput: FC<YesNoInputProps> = ({
  name,
  label,
  yesText,
  noText,
  isBoldLabel = false,
  labelComponent,
  externalNoHandler,
  externalYesHandler,
}) => {
  useController({
    name,
    rules: {},
  });

  const { getValues, setValue, trigger } = useFormContext();
  const { formatMessage } = useIntl();

  const isYesSelected = getValues(name) === true;
  const hasValue = getValues(name) !== undefined;
  const extraClassNames = 'font-medium w-36 md:w-full';

  const handleYesSelect = useCallback(() => {
    setValue(name, true);

    if (externalYesHandler) {
      externalYesHandler();
    }
    trigger(name);
  }, [setValue, externalYesHandler, name, trigger]);

  const handleNoSelect = useCallback(() => {
    setValue(name, false);
    if (externalNoHandler) {
      externalNoHandler();
    }
    trigger(name);
  }, [setValue, externalNoHandler, name, trigger]);

  return (
    <div className="inline-flex w-full flex-col items-start justify-start gap-2.5 rounded-md bg-white">
      {labelComponent ? (
        labelComponent
      ) : (
        <div
          className={`text-sm font-medium leading-normal text-black ${
            isBoldLabel ? 'text-[15px] text-opacity-100' : 'text-opacity-50'
          }`}
        >
          {label}
        </div>
      )}
      <div className="flex h-20 w-full gap-4">
        <Button
          fullWidth
          type="button"
          color={isYesSelected && hasValue ? 'blue' : 'gray'}
          text={yesText ?? formatMessage({ defaultMessage: 'Yes', description: 'YesNoInput' })}
          spacing={ButtonSpacing.XL}
          onClick={handleYesSelect}
          roundedFull={false}
          extraClassNames={extraClassNames}
        />

        <Button
          fullWidth
          type="button"
          color={!isYesSelected && hasValue ? 'blue' : 'gray'}
          text={noText ?? formatMessage({ defaultMessage: 'No', description: 'YesNoInput' })}
          onClick={handleNoSelect}
          spacing={ButtonSpacing.XL}
          roundedFull={false}
          extraClassNames={extraClassNames}
        />
      </div>
    </div>
  );
};

export default YesNoInput;
