import { createPaymentSession } from '@common/redux/thunks/payment';
import { PaymentSessionCreateResponse } from '@common/types/payment';
import { createSlice } from '@reduxjs/toolkit';

interface PaymentInitialState {
  loading: boolean;
  error: boolean;
  paymentSession: PaymentSessionCreateResponse | null;
}

export const initialState: PaymentInitialState = {
  loading: false,
  error: false,
  paymentSession: null,
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(createPaymentSession.pending, (state) => ({
      ...state,
      loading: true,
      error: false,
    }));

    builder.addCase(createPaymentSession.rejected, (state) => ({
      ...state,
      loading: false,
      error: true,
    }));

    builder.addCase(createPaymentSession.fulfilled, (state, { payload }) => ({
      ...state,
      paymentSession: payload,
      loading: false,
      error: false,
    }));
  },
});

export const { reset } = paymentSlice.actions;

export default paymentSlice.reducer;
