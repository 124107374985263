import { useIntl } from 'react-intl';

import SignupBaseCard from '@common/components/sign-up-layout/SignupBaseCard';
import SignupLayout from '@common/components/sign-up-layout/SignupLayout';
import useTracker from '@common/hooks/useTracker';
import { TrackEvent } from '@common/utils/amplitude/events';
import PhoneSubmitForm from '@monefit-ee/components/phone-submit-form/PhoneSubmitForm';

const PhoneSubmitPage = () => {
  const intl = useIntl();
  useTracker({
    initEventName: TrackEvent.PHONE_PAGE_INIT,
    errorTrackEventName: TrackEvent.PHONE_PAGE_ERROR,
  });

  return (
    <SignupLayout>
      <SignupBaseCard
        headerText={intl.formatMessage({
          defaultMessage: 'Additional contact details',
          description: 'Phone: "Additional contact details"',
        })}
        subHeaderText={intl.formatMessage({
          defaultMessage: 'Feel free to add your mobile number for additional contact.',
          description: 'Phone: "Feel free to add your mobile number for additional contact."',
        })}
      >
        <PhoneSubmitForm />
      </SignupBaseCard>
    </SignupLayout>
  );
};

export default PhoneSubmitPage;
