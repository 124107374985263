import { IconProps } from '@common/components/icons/types';

const LinkedIn = ({ isBlackAndWhite }: IconProps) => {
  return (
    <svg width="44" height="43" viewBox="0 0 44 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="6" y="5" width="33" height="31" fill="white" />
      <path
        d="M34.7715 0H9.15039C4.09754 0 0 3.93364 0 8.78438V33.3806C0 38.2314 4.09754 42.165 9.15039 42.165H34.7715C39.8262 42.165 43.9219 38.2314 43.9219 33.3806V8.78438C43.9219 3.93364 39.8262 0 34.7715 0ZM14.6406 33.3806H9.15039V14.055H14.6406V33.3806ZM11.8955 11.8273C10.1277 11.8273 8.69287 10.4394 8.69287 8.72816C8.69287 7.01696 10.1277 5.62903 11.8955 5.62903C13.6634 5.62903 15.0981 7.01696 15.0981 8.72816C15.0981 10.4394 13.6652 11.8273 11.8955 11.8273ZM36.6016 33.3806H31.1113V23.5351C31.1113 17.6179 23.791 18.0659 23.791 23.5351V33.3806H18.3008V14.055H23.791V17.1559C26.3458 12.6126 36.6016 12.277 36.6016 21.5059V33.3806Z"
        fill={isBlackAndWhite ? '#15171B' : '#0077B5'}
        fillOpacity={isBlackAndWhite ? '48%' : '100%'}
      />
    </svg>
  );
};

export default LinkedIn;
