import { ReactNode, useEffect } from 'react';
import { useIntl } from 'react-intl';
import ReactModal, { Props } from 'react-modal';

import Button from '@common/components/button/Button';

interface ModalProps extends Props {
  children: ReactNode;
  onBackClick?: () => void;
}

// TODO: fallback for storybook, later can be just => ReactModal.setAppElement('#root');
const rootDivExisting = document.getElementById('root');
if (!rootDivExisting) {
  const rootDivCreated = document.createElement('div');
  rootDivCreated.id = 'root';
  ReactModal.setAppElement(rootDivCreated);
} else {
  ReactModal.setAppElement(rootDivExisting);
}

const Modal = ({ children, onBackClick, ...props }: ModalProps) => {
  const { formatMessage } = useIntl();
  useEffect(() => {
    props?.isOpen
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'auto');
  }, [props.isOpen]);

  return (
    <ReactModal
      className="max-h-[80vh] w-full max-w-[610px] overflow-y-auto rounded-3xl bg-white p-12 outline-none"
      overlayClassName="modal-overlay"
      overlayElement={(props, contentEl) => (
        <div {...props} className="modal-overlay flex flex-col gap-6 p-2">
          {!!onBackClick && (
            <div>
              <Button
                onClick={onBackClick}
                type="button"
                text={formatMessage({ defaultMessage: 'Back', description: 'Back button text' })}
              />
            </div>
          )}
          <div>{contentEl}</div>
        </div>
      )}
      {...props}
    >
      <div>{children}</div>
    </ReactModal>
  );
};

export default Modal;
