import { FC, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import Button, { ButtonProps } from '@common/components/button/Button';

import m from './FileUploadInput.messages';

interface FileUploadProps extends ButtonProps {
  name: string;
  multiple?: boolean;
}

const FileUploadInput: FC<FileUploadProps> = ({ name, multiple, ...buttonProps }) => {
  const { formatMessage } = useIntl();
  const {
    register,
    formState: { isSubmitting },
    getValues,
    setValue,
    trigger,
  } = useFormContext();

  const { onChange, ...registration } = register(name);

  const onFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedFiles = getValues(name) ?? [];
      const newFiles = Array.from(event.target.files || []);
      const updatedFiles = [...selectedFiles, ...newFiles];
      setValue(name, updatedFiles);
      trigger(name);
    },
    [trigger, setValue, getValues, name]
  );

  return (
    <>
      <input
        type="file"
        multiple={multiple}
        {...registration}
        onChange={(e) => onFileChange(e)}
        className="hidden"
        id="file-upload"
      />
      <div>
        <Button
          {...buttonProps}
          text={buttonProps?.text ?? formatMessage(m.addBtnTextFallback)}
          disabled={buttonProps.disabled || isSubmitting}
          onClick={() => document.getElementById('file-upload')?.click()}
        />
      </div>
    </>
  );
};

export default FileUploadInput;
