import React from 'react';

interface CheckIconOutlinedProps {
  isFilled?: boolean;
}

const CheckIconOutlined: React.FC<CheckIconOutlinedProps> = ({ isFilled = false }) => {
  return isFilled ? (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
      <circle
        cx="18"
        cy="18"
        r="13.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 15L16.5 21L13.5 18"
        stroke="#05FF00"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
      <circle
        cx="18"
        cy="18"
        r="13.5"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 15L16.5 21L13.5 18"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckIconOutlined;
