import { GroupBase, StylesConfig } from 'react-select';

import { sharedLabelStyles } from '@common/components/input-label/InputLabel.styles';
import { IOption } from '@common/components/select-input/SelectInput';

interface Styles {
  autoFillInput: StylesConfig<IOption, false, GroupBase<IOption>>;
}

type GetStyles = () => Styles;

export const getStyles: GetStyles = () => ({
  autoFillInput: {
    control: (base): any => ({
      ...base,
      cursor: 'text',
      borderColor: '#d1d5db',
      boxShadow: 'none',
      borderRadius: '0.75rem', // rounded-xl
      '&:hover': {
        borderColor: '#d1d5db', // gray-300
      },
    }),
    option: (base, state): any => ({
      ...base,
      cursor: 'pointer',
      color: 'rgba(0, 0, 0, 0.48)',
      padding: '5px 1px',
      backgroundColor: 'transparent',
      fontFamily: state.isFocused ? 'Avenir Next Demibold' : 'Avenir Next',
      '&:active': {
        backgroundColor: 'white',
      },
      '&:hover': {
        backgroundColor: '#F3F4F6', // gray-100
        borderRadius: '0.125rem', // rounded-sm,
      },
    }),
    input: (base) => ({
      ...base,
    }),
    menu: (base): any => ({
      ...base,
      borderRadius: '0.75rem', // rounded-xl,
      border: '1px solid #d1d5db', // border-gray-300
      boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
      marginTop: '4px',
      marginBottom: '4px',
    }),
    valueContainer: (base): any => ({
      ...base,
      display: 'flex',
      paddingTop: sharedLabelStyles.inputTopPaddingPixels,
      paddingLeft: '24px', // pl-6
      fontWeight: '600',
      flexWrap: 'no-wrap',
    }),
    clearIndicator: (base): any => ({
      ...base,
      cursor: 'pointer',
    }),
  },
});
