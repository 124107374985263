/* eslint-disable boundaries/no-private */
import { FC, JSX, ReactNode, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { LocaleProvider as LocaleProviderCommon } from '@common/hooks';
import useApp, { AppId } from '@common/hooks/useApp';
import useCountry from '@common/hooks/useCountry';
import useEnvironment, { Environment } from '@common/hooks/useEnvironment';
import useInitTrackers from '@common/hooks/useInitTrackers';
import useSubApp, { SubAppId } from '@common/hooks/useSubApp';
import CountryNotFoundRedirectPage from '@common/pages/CountryNotFoundRedirectPage';
import CreditstarSpainApp from '@creditstar-es/App';
import MonefitEstoniaApp from '@monefit-ee/App';
import MonefitEstoniaErrorBoundary, {
  ErrorBoundaryPageProps as MonefitEstoniaErrorBoundaryProps,
} from '@monefit-ee/pages/error-boundary-page/ErrorBoundaryPage';
import MonefitSpainApp from '@monefit-es/App';
import { ErrorBoundaryPage as MonefitSpainErrorBoundary } from '@monefit-es/pages';
import SmartSaverApp from '@smartsaver/App';
import { LocaleProvider as LocaleProviderSmartSaver } from '@smartsaver/hooks/useLocale';

function RootApp(): JSX.Element {
  const { appId } = useApp();
  const { subAppId } = useSubApp();
  const { country } = useCountry();
  const { environment } = useEnvironment();

  useInitTrackers();

  const renderApp = useMemo<JSX.Element | null>(() => {
    if (appId === AppId.SMARTSAVER) {
      return <SmartSaverApp />;
    }
    switch (subAppId) {
      case undefined:
        return null;
      case SubAppId.MONEFIT_EE:
        return <MonefitEstoniaApp />;
      case SubAppId.MONEFIT_ES:
        return <MonefitSpainApp />;
      case SubAppId.CREDITSTAR_ES:
        // TODO: remove condition below for production release
        return environment !== Environment.PRODUCTION ? <CreditstarSpainApp /> : null;
      default:
        return <CountryNotFoundRedirectPage />;
    }
  }, [environment, subAppId, appId]);

  const renderErrorBoundary = useMemo<FC<MonefitEstoniaErrorBoundaryProps>>(() => {
    switch (subAppId) {
      case SubAppId.MONEFIT_EE:
        return MonefitEstoniaErrorBoundary;
      case SubAppId.MONEFIT_ES:
        return MonefitSpainErrorBoundary;
      default:
        return () => <div>Unhandled error occurred</div>;
    }
  }, [subAppId]);

  return (
    <BrowserRouter future={{ v7_startTransition: true }}>
      <LocaleProvider country={String(country)}>
        <ToastContainer
          position="top-center"
          theme="colored"
          limit={3}
          autoClose={4000}
          hideProgressBar
          closeButton={false}
          closeOnClick
          transition={Slide}
          style={{
            fontSize: '1.125rem',
            fontWeight: '500',
            lineHeight: '105%',
            marginTop: '7rem',
          }}
        />
        <div data-testid="root-app">
          <ErrorBoundary FallbackComponent={renderErrorBoundary}>{renderApp}</ErrorBoundary>
        </div>
      </LocaleProvider>
    </BrowserRouter>
  );
}

export default RootApp;

const LocaleProvider = ({ children, country }: { children: ReactNode; country?: string }) => {
  const { appId } = useApp();

  if (appId === AppId.SMARTSAVER) {
    return <LocaleProviderSmartSaver>{children}</LocaleProviderSmartSaver>;
  }

  return <LocaleProviderCommon country={String(country)}>{children}</LocaleProviderCommon>;
};
