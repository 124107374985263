const LogoIconText = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="82" height="17" viewBox="0 0 82 17" fill="none">
      <path
        d="M0.15679 16.5403C0.0522624 16.4361 0 16.309 0 16.1595V5.58785C0 5.4387 0.0522624 5.31156 0.15679 5.20704C0.260987 5.10278 0.388126 5.05025 0.5376 5.05025H2.28464C2.43345 5.05025 2.56059 5.10278 2.66545 5.20704C2.76965 5.31156 2.82224 5.4387 2.82224 5.58785V6.34919C3.59822 5.33385 4.68113 4.82617 6.06991 4.82617C7.72739 4.82617 8.88423 5.49064 9.54133 6.81957C9.89985 6.22266 10.4072 5.74095 11.0646 5.37483C11.7214 5.00926 12.4308 4.82617 13.1925 4.82617C14.4165 4.82617 15.4172 5.24428 16.1938 6.0805C16.9701 6.91672 17.3583 8.13381 17.3583 9.73132V16.1595C17.3583 16.309 17.3097 16.4361 17.2128 16.5403C17.1156 16.6448 16.9851 16.6971 16.8209 16.6971H14.9841C14.8349 16.6971 14.7078 16.6448 14.6033 16.5403C14.4987 16.4361 14.4468 16.309 14.4468 16.1595V9.91044C14.4468 8.9697 14.2487 8.28658 13.8532 7.86116C13.4574 7.4354 12.9312 7.22266 12.274 7.22266C11.6918 7.22266 11.1951 7.43936 10.7847 7.87216C10.3739 8.30556 10.1685 8.98499 10.1685 9.91044V16.1595C10.1685 16.309 10.1162 16.4361 10.012 16.5403C9.90717 16.6448 9.78036 16.6971 9.63116 16.6971H7.79434C7.64514 16.6971 7.518 16.6448 7.41381 16.5403C7.30895 16.4361 7.25702 16.309 7.25702 16.1595V9.91044C7.25702 8.9697 7.05165 8.28658 6.64085 7.86116C6.23006 7.4354 5.71138 7.22266 5.08422 7.22266C4.48703 7.22266 3.98305 7.43936 3.57258 7.87216C3.16146 8.30556 2.95642 8.98499 2.95642 9.91044V16.1595C2.95642 16.309 2.90416 16.4361 2.79963 16.5403C2.6951 16.6448 2.56797 16.6971 2.4191 16.6971H0.5376C0.388126 16.6971 0.260987 16.6448 0.15679 16.5403Z"
        fill="black"
      />
      <path
        d="M27.1869 13.9533C27.6197 13.453 27.8587 12.718 27.9037 11.747C27.9184 11.5981 27.9259 11.3069 27.9259 10.8735C27.9259 10.4407 27.9184 10.1494 27.9037 9.99995C27.8587 9.02955 27.6197 8.29423 27.1869 7.79387C26.7535 7.29385 26.1417 7.04353 25.3504 7.04353C24.5588 7.04353 23.9466 7.29385 23.5135 7.79387C23.0804 8.29423 22.8417 9.02955 22.7968 9.99995L22.7745 10.8735L22.7968 11.747C22.8417 12.718 23.0804 13.453 23.5135 13.9533C23.9466 14.4537 24.5588 14.7037 25.3504 14.7037C26.1417 14.7037 26.7535 14.4537 27.1869 13.9533ZM21.2961 15.5659C20.3404 14.6628 19.8254 13.4267 19.7508 11.8592L19.7285 10.8735L19.7508 9.88811C19.8254 8.33516 20.3477 7.10344 21.3187 6.19235C22.2891 5.28186 23.633 4.82617 25.3504 4.82617C27.0671 4.82617 28.411 5.28186 29.3817 6.19235C30.3524 7.10344 30.8747 8.33516 30.9497 9.88811C30.9646 10.0672 30.9723 10.3958 30.9723 10.8735C30.9723 11.3515 30.9646 11.6798 30.9497 11.8592C30.8747 13.4267 30.3597 14.6628 29.4044 15.5659C28.4486 16.4697 27.0974 16.9211 25.3504 16.9211C23.6034 16.9211 22.2515 16.4697 21.2961 15.5659Z"
        fill="black"
      />
      <path
        d="M33.6945 16.5403C33.59 16.4361 33.5377 16.309 33.5377 16.1595V5.58785C33.5377 5.4387 33.59 5.31156 33.6945 5.20704C33.7987 5.10278 33.9259 5.05025 34.0753 5.05025H35.9119C36.061 5.05025 36.1879 5.10278 36.2927 5.20704C36.3969 5.31156 36.4495 5.4387 36.4495 5.58785V6.50598C37.3304 5.38611 38.577 4.82617 40.1898 4.82617C41.5634 4.82617 42.6536 5.2779 43.4599 6.1814C44.2662 7.08485 44.6696 8.31288 44.6696 9.86583V16.1595C44.6696 16.309 44.6207 16.4361 44.5238 16.5403C44.4266 16.6448 44.2961 16.6971 44.132 16.6971H42.1609C42.0115 16.6971 41.8843 16.6448 41.7801 16.5403C41.6756 16.4361 41.6233 16.309 41.6233 16.1595V10C41.6233 9.11917 41.4106 8.43606 40.9851 7.95072C40.5594 7.46566 39.9436 7.22266 39.1373 7.22266C38.3457 7.22266 37.7222 7.46901 37.2668 7.962C36.8114 8.45471 36.584 9.13441 36.584 10V16.1595C36.584 16.309 36.5351 16.4361 36.4382 16.5403C36.341 16.6448 36.2102 16.6971 36.046 16.6971H34.0753C33.9259 16.6971 33.7987 16.6448 33.6945 16.5403Z"
        fill="black"
      />
      <path
        d="M54.8735 9.86583V9.79854C54.8735 8.91771 54.6531 8.20835 54.2127 7.67075C53.7722 7.13342 53.1637 6.86446 52.3874 6.86446C51.6108 6.86446 51.0059 7.13342 50.5731 7.67075C50.1398 8.20835 49.9237 8.91771 49.9237 9.79854V9.86583H54.8735ZM48.434 15.4767C47.456 14.5136 46.937 13.1584 46.8774 11.4114L46.8551 10.8512L46.8774 10.2913C46.952 8.58916 47.4783 7.25291 48.4564 6.28224C49.4343 5.31151 50.7446 4.82617 52.3874 4.82617C54.1491 4.82617 55.512 5.36377 56.475 6.43903C57.4381 7.51423 57.9197 8.94736 57.9197 10.7393V11.2097C57.9197 11.3591 57.8672 11.4863 57.7629 11.5905C57.6581 11.6951 57.5239 11.7473 57.3598 11.7473H49.9237V11.9264C49.9533 12.748 50.1813 13.4384 50.6065 13.9983C51.0322 14.5582 51.6181 14.8382 52.3648 14.8382C53.246 14.8382 53.955 14.495 54.4927 13.8079C54.6271 13.6438 54.7353 13.5393 54.8175 13.4943C54.8994 13.4494 55.0226 13.4271 55.187 13.4271H57.1132C57.2477 13.4271 57.3632 13.4683 57.4603 13.5503C57.5572 13.6324 57.6059 13.7333 57.6059 13.8525C57.6059 14.211 57.3935 14.6331 56.9677 15.1182C56.5422 15.6035 55.9337 16.0253 55.1421 16.3835C54.3508 16.742 53.4324 16.9211 52.3874 16.9211C50.7299 16.9211 49.4117 16.4397 48.434 15.4767Z"
        fill="black"
      />
      <path
        d="M61.6904 16.4976C61.5859 16.3934 61.5336 16.2663 61.5336 16.1168V7.38171H59.7194C59.5699 7.38171 59.4431 7.32576 59.3386 7.21364C59.234 7.10174 59.1818 6.97125 59.1818 6.82177V5.54516C59.1818 5.39602 59.234 5.26888 59.3386 5.16435C59.4431 5.0601 59.5699 5.00756 59.7194 5.00756H61.5336V4.08909C61.5336 1.41632 62.997 0.0800781 65.9238 0.0800781H70.4927C70.6569 0.0800781 70.7874 0.128656 70.8849 0.225536C70.9818 0.322747 71.0303 0.453241 71.0303 0.617404V1.89435C71.0303 2.05878 70.9778 2.19291 70.8735 2.29716C70.769 2.40196 70.6419 2.45423 70.4927 2.45423H66.013C65.4308 2.45423 65.0237 2.59605 64.7926 2.87971C64.561 3.16331 64.4454 3.60403 64.4454 4.20127V5.00756H70.4927C70.6569 5.00756 70.7874 5.05614 70.8849 5.15302C70.9818 5.2505 71.0303 5.381 71.0303 5.54516V16.1168C71.0303 16.2663 70.9818 16.3934 70.8849 16.4976C70.7874 16.6021 70.6569 16.6544 70.4927 16.6544H68.6788C68.5291 16.6544 68.4022 16.6021 68.298 16.4976C68.1931 16.3934 68.1409 16.2663 68.1409 16.1168V7.38171H64.4454V16.1168C64.4454 16.2663 64.3928 16.3934 64.2886 16.4976C64.1838 16.6021 64.057 16.6544 63.9078 16.6544H62.0712C61.9218 16.6544 61.7947 16.6021 61.6904 16.4976Z"
        fill="black"
      />
      <path
        d="M76.071 15.5964C75.3916 14.8873 75.052 13.8458 75.052 12.4719V7.38766H73.2824C73.133 7.38766 73.0061 7.33144 72.9019 7.21954C72.7971 7.10736 72.7451 6.97687 72.7451 6.82772V5.55078C72.7451 5.40164 72.7971 5.27478 72.9019 5.16997C73.0061 5.06572 73.133 5.01346 73.2824 5.01346H75.052V1.29508C75.052 1.13098 75.104 1.00048 75.2088 0.903271C75.3131 0.80639 75.4402 0.757812 75.5894 0.757812H77.4039C77.568 0.757812 77.6986 0.80639 77.7957 0.903271C77.8926 1.00048 77.9412 1.13098 77.9412 1.29508V5.01346H80.7411C80.905 5.01346 81.0358 5.06209 81.133 5.15897C81.2299 5.25613 81.2787 5.38667 81.2787 5.55078V6.82772C81.2787 6.99216 81.2262 7.12634 81.122 7.23087C81.0172 7.3354 80.89 7.38766 80.7411 7.38766H77.9412V12.2479C77.9412 12.89 78.0531 13.3754 78.2771 13.7036C78.5012 14.0325 78.8743 14.1966 79.397 14.1966H80.9426C81.0917 14.1966 81.2189 14.2488 81.3234 14.3534C81.4276 14.4579 81.4802 14.5851 81.4802 14.7339V16.1227C81.4802 16.2722 81.4316 16.3993 81.3344 16.5036C81.2372 16.6081 81.1067 16.6603 80.9426 16.6603H79.1283C77.7695 16.6603 76.7504 16.3058 76.071 15.5964Z"
        fill="black"
      />
    </svg>
  );
};

export default LogoIconText;
