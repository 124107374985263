import { FC, ReactNode } from 'react';

import Footer from '@common/components/footer/Footer';
import Header from '@common/components/header/Header';
import MainLoader from '@common/components/loader/MainLoader';
import { useSelector } from '@common/hooks/useSelector';

export interface SignupLayoutProps {
  children: ReactNode;
  hideProgressBar?: boolean;
  title?: ReactNode;
  subTitle?: ReactNode;
}

const SignupLayout: FC<SignupLayoutProps> = ({ children, hideProgressBar, title, subTitle }) => {
  const { loading, meta } = useSelector((state) => state.application.flowInstance);

  return (
    <div className="flex h-screen flex-col justify-between gap-6 bg-stone-50 p-2 md:p-12 ">
      <Header hideProgressBar={hideProgressBar} />
      <div className="flex w-full flex-col items-center justify-center">
        {!!title && <h2 className="mb-3 text-center text-4xl font-bold">{title}</h2>}
        {!!subTitle && <h3 className="mb-12 text-center text-xl font-medium">{subTitle}</h3>}
        {loading ? meta?.showLoader ? <MainLoader /> : children : children}
      </div>
      <Footer />
    </div>
  );
};

export default SignupLayout;
