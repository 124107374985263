import { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import BackButtonIcon from '@common/components/icons/BackButtonIcon';
import useLinks from '@smartsaver/hooks/useLinks';
import { SmartSaverLocales, useLocale } from '@smartsaver/hooks/useLocale';
import { MOBILE_MENU_ANIMATION_VARIANTS } from '@src/assets/animations/motion.animations';
import { motion } from 'framer-motion';

import m from './MobileMenu.messages';

interface MobileMenuProps {
  isMenuOpen: boolean;
  isInLanguageSelectMode: boolean;
  setIsInLanguageSelectMode: (v: boolean) => void;
  setIsMenuOpen: (v: boolean) => void;
}

const MobileMenu: FC<MobileMenuProps> = ({
  isMenuOpen,
  isInLanguageSelectMode,
  setIsInLanguageSelectMode,
  setIsMenuOpen,
}) => {
  const { locale, languageMeta, allowedLocales, setLocale } = useLocale();
  const { links } = useLinks();
  const navigate = useNavigate();
  const location = useLocation();
  const { formatMessage } = useIntl();

  const getFlagComponent = useCallback(
    (locale: SmartSaverLocales, width: string, height: string) => {
      const Component = languageMeta[locale].flag;
      return <Component {...{ width, height }} />;
    },
    [languageMeta]
  );

  const handleLocaleSelect = useCallback(
    (newLocale: string) => {
      const { pathname: currentLocation } = location;
      const newLocation = currentLocation.replace(locale, newLocale);
      setLocale(newLocale);
      setIsInLanguageSelectMode(false);
      setIsMenuOpen(false);
      navigate(newLocation, { replace: true });
    },
    [navigate, locale, setLocale, setIsInLanguageSelectMode, setIsMenuOpen, location]
  );

  return (
    <motion.div
      className="fixed left-0 top-0 z-50 flex size-full flex-col items-center justify-center bg-white"
      initial="closed"
      animate={isMenuOpen ? 'open' : 'closed'}
      variants={MOBILE_MENU_ANIMATION_VARIANTS}
    >
      {isInLanguageSelectMode ? (
        <div className="flex size-full justify-center px-4 py-28">
          <div className="flex size-full max-w-80 flex-col justify-around">
            <div className="flex w-full items-center justify-around font-semibold">
              <button type="button" onClick={() => setIsInLanguageSelectMode(false)}>
                <BackButtonIcon />
              </button>
              {formatMessage(m.language)}
            </div>
            <div className="flex w-full justify-center">
              <div className="flex  flex-col justify-center gap-4">
                {allowedLocales
                  ?.filter((x) => x !== locale)
                  .map((l) => (
                    <div key={l}>
                      <button
                        onClick={() => handleLocaleSelect(l)}
                        type="button"
                        className="flex items-center gap-3"
                      >
                        <div>{getFlagComponent(l as SmartSaverLocales, '35', '35')}</div>
                        <span className="font-semibold">{languageMeta[l].name}</span>
                      </button>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <nav className="flex flex-col items-center justify-center gap-7 text-xl">
          <div className="flex flex-col items-center justify-center gap-5 self-stretch">
            <a className="hover:underline" href={links?.aboutSmartSaver}>
              {formatMessage(m.aboutSmartSaver)}
            </a>
            <a className="hover:underline" href={links?.faq}>
              {formatMessage(m.faq)}
            </a>
            <a className="hover:underline" href={links?.termsOfUse}>
              {formatMessage(m.termsOfUse)}
            </a>
            <a className="hover:underline" href={links?.privacyPolicy}>
              {formatMessage(m.privacyPolicy)}
            </a>
            <a className="hover:underline" href={links?.cookiesPolicy}>
              {formatMessage(m.cookiesPolicy)}
            </a>
            <a className="hover:underline" href={links?.referAFriend}>
              {formatMessage(m.referAFriend)}
            </a>
            <a className="hover:underline" href={links?.aboutUs}>
              {formatMessage(m.aboutUs)}
            </a>
            <a className="hover:underline" href={links?.careers}>
              {formatMessage(m.careers)}
            </a>
          </div>
          <div>
            <button
              onClick={() => setIsInLanguageSelectMode(true)}
              className="rounded-full bg-stone-100 px-4 py-2"
            >
              {
                <div className="flex items-center gap-2">
                  {getFlagComponent(locale as SmartSaverLocales, '31', '31')}
                  <span>{locale.toUpperCase()}</span>
                </div>
              }
            </button>
          </div>
        </nav>
      )}
    </motion.div>
  );
};

export default MobileMenu;
