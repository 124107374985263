import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import useOptimove from '@common/hooks/useOptimove';
import LoginForm from '@smartsaver/components/login-form/LoginForm';
import { LoginFormSchema } from '@smartsaver/components/login-form/LoginForm.schema';
import MainLayout from '@smartsaver/components/main-layout/MainLayout';
import { OPTIMOVE_EVENTS } from '@smartsaver/constants/optimoveEvents';
import { handleLogin } from '@smartsaver/services/auth';
import { useMutation } from '@tanstack/react-query';

const LoginPage = () => {
  const { reportEvent } = useOptimove();
  const { formatMessage } = useIntl();
  const loginMutation = useMutation({
    mutationFn: ({ email, password }: LoginFormSchema) => {
      return handleLogin({ email, password });
    },
    onSuccess: (data) => {
      // TODO: Handler
      reportEvent({ eventName: OPTIMOVE_EVENTS.login });
    },
    onError: (error) => {
      // TODO: message
      toast.error(String(error));
    },
  });

  const { setPageVisit } = useOptimove();

  useEffect(() => {
    setPageVisit({});
  }, []);

  return (
    <MainLayout
      loadingProp={loginMutation?.isPending}
      pageTitle={formatMessage({ defaultMessage: 'Log in' })}
    >
      <LoginForm loginMutation={loginMutation} />
    </MainLayout>
  );
};

export default LoginPage;
