export const colorStyles = {
  regular: {
    baseColor: 'text-gray-400',
    minimizedColor: 'text-gray-500',
  },
  smartsaver: {
    baseColor: 'text-black',
    minimizedColor: 'text-gray-500',
  },
  account: {
    baseColor: 'text-gray-400',
    minimizedColor: 'text-gray-500',
  },
};

export const sharedLabelStyles = {
  base: 'floating-label absolute left-[26px] inline-block w-[calc(80%)] truncate transition-all duration-300',
  minimized: 'top-[15px] text-xs',
  notMinimized: 'top-5 h-full text-base',
  inputTopPaddingClassname: 'pt-[20px]',
  inputTopPaddingPixels: '20px',
};
