// import Button, { ButtonSpacing } from '@common/components/button/Button';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import CloseIcon from '@common/components/icons/CloseIcon';
import LogoIcon from '@common/components/icons/LogoIcon';
import LogoIconText from '@common/components/icons/LogoIconText';
import MenuIcon from '@common/components/icons/MenuIcon';
import useTailwindBreakpoints from '@common/hooks/useTailwindBreakpoints';
import LanguageSelect from '@smartsaver/components/language-select/LanguageSelect';
import { getClassNames } from '@smartsaver/components/main-layout/MainLayout.classes';
import MobileMenu from '@smartsaver/components/mobile-menu/MobileMenu';
import { useLocale } from '@smartsaver/hooks/useLocale';
import { MENU_BUTTON_ANIMATION_VARIANTS } from '@src/assets/animations/motion.animations';
import clsx from 'clsx';
import { motion } from 'framer-motion';

const Header = () => {
  const { isMobile } = useTailwindBreakpoints();
  const { locale } = useLocale();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isInLanguageSelectMode, setIsInLanguageSelectMode] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const classes = useMemo(() => getClassNames(), []);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isMenuOpen]);

  useEffect(() => {
    if (!isMobile) {
      setIsMenuOpen(false);
      setIsInLanguageSelectMode(false);
    }
  }, [isMobile]);

  return (
    <div className={clsx(classes.headerFooterContainer, ' bg-transparent md:bg-white')}>
      <div
        className={clsx(
          classes.headerFooterWidth,
          'flex w-full items-center justify-between self-stretch'
        )}
      >
        <div className="z-100">
          <Link to={`/${locale}/login`}>
            {isMobile ? (
              <LogoIcon
                width={isInLanguageSelectMode ? '0' : '44'}
                height={isInLanguageSelectMode ? '0' : '44'}
              />
            ) : (
              <LogoIconText />
            )}
          </Link>
        </div>

        {isMobile ? (
          <>
            {!isInLanguageSelectMode && (
              <motion.button
                type="button"
                onClick={toggleMenu}
                className="z-100"
                animate={isMenuOpen ? 'open' : 'closed'}
                variants={MENU_BUTTON_ANIMATION_VARIANTS}
              >
                {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
              </motion.button>
            )}

            <MobileMenu
              isMenuOpen={isMenuOpen}
              isInLanguageSelectMode={isInLanguageSelectMode}
              setIsInLanguageSelectMode={setIsInLanguageSelectMode}
              setIsMenuOpen={setIsMenuOpen}
            />
          </>
        ) : (
          <div className="flex items-center gap-3">
            <LanguageSelect />
            {/* TODO: Update when auth implemented */}
            {/* <Button spacing={ButtonSpacing.XS} text="Log out" /> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
