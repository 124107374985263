export const styles = {
  regular: {
    borderColor: '#d1d5db',
    borderColorError: '#FF0000',
  },
  smartsaver: {
    borderColor: 'black',
    borderColorError: '#FF0000',
  },
  account: {
    borderColor: '#d1d5db',
    borderColorError: '#FF0000',
  },
};
