import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import Loader from '@common/components/loader/Loader';
import { LINKS } from '@common/constants';
import { useLocale } from '@common/hooks';
import useSubApp, { SubAppId } from '@common/hooks/useSubApp';
import { selectFlowInstanceStep } from '@common/redux/selectors';
import { FlowSteps } from '@common/services/application';
// eslint-disable-next-line boundaries/no-private
import { FlowStepsAllowCancel } from '@monefit-ee/service/application';
import cslx from 'clsx';

import { getClassNames } from './SignupBaseCard.classes';
import { SignupBaseCardProps } from './SignupBaseCard.types';

const SignupBaseCard = ({
  children,
  headerText,
  subHeaderText,
  headerExtraClassNames,
  extraClassNames = '',
  showHeaderLoader = false,
  headerLoaderProps,
  imgSrc = '',
}: SignupBaseCardProps) => {
  const c = useMemo(() => getClassNames({ showHeaderLoader }), [showHeaderLoader]);
  const flowStepId = useSelector(selectFlowInstanceStep);
  const { subAppId } = useSubApp();
  const { localeWithCountry } = useLocale();

  const canCancel = useMemo(
    () => (currentStep: FlowSteps) => {
      return FlowStepsAllowCancel.includes(currentStep);
    },
    []
  );

  const navigateHandler = useCallback(() => {
    window.location.href = `${LINKS.SELF_SERVICE_URL}${localeWithCountry}/credit-line/`;
  }, [localeWithCountry]);

  const shouldShowCancelButton = useMemo(() => {
    return subAppId === SubAppId.MONEFIT_EE && flowStepId && canCancel(flowStepId);
  }, [subAppId, flowStepId, canCancel]);

  return (
    <div className={cslx(c.container, extraClassNames)}>
      <div className={c.headerWrapper}>
        <div className={c.headerLeft}>
          {!!headerText && <div className={cslx(c.title, headerExtraClassNames)}>{headerText}</div>}
          {!!subHeaderText && <div className={c.subTitle}>{subHeaderText}</div>}
        </div>
        {!!imgSrc && (
          <div className="h-16 justify-end">
            <img className="h-full w-auto max-w-none" alt="logo" src={imgSrc} />
          </div>
        )}
        {showHeaderLoader && headerLoaderProps && (
          <div className={c.headerRight}>
            <Loader.Indicator {...headerLoaderProps} />
          </div>
        )}
      </div>
      {children}
      {shouldShowCancelButton && (
        <button className={c.cancelButton} onClick={() => navigateHandler()}>
          Cancel
        </button>
      )}
    </div>
  );
};

export default SignupBaseCard;
