import { useIntl } from 'react-intl';

import ImageCard from '@common/components/image-card/ImageCard';
import reviewImg from '@src/assets/img/monefit-es/declined_1.webp';

import m from './ManualReviewCard.messages';

const ManualReviewCard = () => {
  const { formatMessage } = useIntl();
  return (
    <ImageCard imageSrc={reviewImg} maxWClassName="max-w-[1100px]">
      <div className="flex size-full flex-col justify-normal gap-8 sm:justify-between sm:gap-5">
        <div className="flex flex-col items-start justify-start gap-2">
          <div className="font-heavy text-4xl text-black">{formatMessage(m.heading2)}</div>
          <div className="mt-2">{formatMessage(m.headingDescription)}</div>
        </div>
        <div className="flex flex-col justify-center gap-2.5">
          <div className="text-base font-bold leading-normal text-black">
            {formatMessage(m.subTitle1)}
          </div>
          <div>{formatMessage(m.subText1)}</div>
        </div>
        <div className="flex flex-col justify-center gap-2.5">
          <div className="text-base font-bold leading-normal text-black">
            {formatMessage(m.subTitle2)}
          </div>
          <div>{formatMessage(m.subText2)}</div>
        </div>
        <div className="text-base font-medium text-black">{formatMessage(m.subText3)}</div>
      </div>
    </ImageCard>
  );
};

export default ManualReviewCard;
