import { downloadDocument, getUserDocuments } from '@common/redux/thunks/documents';
import { UserDocument } from '@common/types/user-document';
import { createSlice } from '@reduxjs/toolkit';

export interface DocumentsInitialState {
  loading: boolean;
  error: boolean;
  documents: UserDocument[] | null;
  documentSigningURL: string | null;
}

export const initialState: DocumentsInitialState = {
  loading: false,
  error: false,
  documents: null,
  documentSigningURL: null,
};

const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
    }),
    setLoading: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
    setDocumentSigningURL: (state, action) => ({
      ...state,
      documentSigningURL: action.payload,
    }),
  },
  extraReducers: (builder) => {
    // Get user documents
    builder.addCase(getUserDocuments.pending, (state) => ({
      ...state,
      loading: true,
      error: false,
    }));

    builder.addCase(getUserDocuments.fulfilled, (state, action) => ({
      ...state,
      documents: action.payload,
      loading: false,
      error: false,
    }));

    builder.addCase(getUserDocuments.rejected, (state) => ({
      ...state,
      loading: false,
      error: true,
    }));

    // Download document
    builder.addCase(downloadDocument.pending, (state) => ({
      ...state,
      loading: true,
      error: false,
    }));

    builder.addCase(downloadDocument.fulfilled, (state, action) => ({
      ...state,
      loading: false,
      error: false,
    }));

    builder.addCase(downloadDocument.rejected, (state) => ({
      ...state,
      loading: false,
      error: true,
    }));
  },
});

export const { reset, setLoading, setDocumentSigningURL } = documentsSlice.actions;

export default documentsSlice.reducer;
