import { FC, useEffect, useMemo, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';

import { MainLayoutProps } from '@common/components/layout/Layout.Main';
import MainLoader from '@common/components/main-loader/MainLoader';
import MetaTags from '@common/components/meta-tags/MetaTags';
import Footer from '@smartsaver/components/footer/Footer';
import Header from '@smartsaver/components/header/Header';
import useLinks from '@smartsaver/hooks/useLinks';
import { useLocale } from '@smartsaver/hooks/useLocale';
import clsx from 'clsx';

const MainLayout: FC<MainLayoutProps> = ({
  children,
  hideHeaderAndFooter = false,
  hideHeader = false,
  showLoader: showLoaderProp = true,
  type,
  loadingProp,
  childrenWrapperAlign = 'center',
  loaderType = 'dots',
  loaderComponent,
  pageTitle,
  pageDescription,
}) => {
  const { locale } = useLocale();
  const { REACT_APP_PUBLIC_URL } = process.env;
  const shouldShowLoader = useMemo(() => {
    // TODO: add other conditions (store loading state, etc )
    return showLoaderProp && loadingProp;
  }, [showLoaderProp, loadingProp]);

  return (
    <>
      <HelmetProvider>
        <MetaTags
          title={pageTitle ?? 'Smartsaver'}
          description={pageDescription ?? ''}
          url={`${REACT_APP_PUBLIC_URL}/${locale}`}
        />
      </HelmetProvider>
      <div
        className={clsx('flex min-h-svh flex-col gap-6 bg-stone-50', {
          'justify-center': hideHeaderAndFooter,
          'justify-between': !hideHeaderAndFooter,
        })}
      >
        <>
          {!(hideHeaderAndFooter || hideHeader) && <Header />}

          <div
            className={clsx(
              'flex size-full flex-1 grow flex-col items-center p-2 md:px-12 md:py-6',
              {
                'justify-center': childrenWrapperAlign === 'center',
                'justify-start': childrenWrapperAlign === 'start',
              }
            )}
          >
            {shouldShowLoader
              ? (loaderComponent ?? <MainLoader type={loaderType} heightClassName="" />)
              : children}
          </div>

          {!hideHeaderAndFooter && <Footer />}
        </>
      </div>
    </>
  );
};

export default MainLayout;
