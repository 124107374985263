import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  cardTitle: {
    defaultMessage: 'Verification code',
    description: 'OtpCode: "Verification code"',
  },
  cardSubtitleEmail: {
    defaultMessage: 'We sent {codeLength}-digit code to {value}',
    description: 'OtpCode: "We sent {codeLength}-digit code to {value}"',
  },
  cardSubtitleMobile: {
    defaultMessage: 'We sent {codeLength}-digit code to {value}',
    description: 'OtpCode: "We sent {codeLength}-digit code to {value}"',
  },
  continue: {
    defaultMessage: 'Continue',
    description: 'OtpCode: "Continue"',
  },
  otpResent: {
    defaultMessage: 'We’ve sent a new code to your {type}',
    description: 'OtpCode: "We’ve sent a new code to your {type}"',
  },
  email: {
    defaultMessage: 'email',
    description: 'email',
  },
  phone: {
    defaultMessage: 'phone',
    description: 'phone',
  },
  timerMessage: {
    defaultMessage: 'You can request a new code in {time} minutes if needed',
    desciption: 'You can request a new code in {time} minutes if needed',
  },
});
