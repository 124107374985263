import { FC } from 'react';

import { IconProps } from '@common/components/icons/types';

const SpanishFlag: FC<IconProps> = ({ height = '16', width = '16' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3176_7843)">
        <path
          d="M0 7.99973C0 8.9783 0.176031 9.91567 0.497594 10.7823L8 11.478L15.5024 10.7823C15.824 9.91567 16 8.9783 16 7.99973C16 7.02117 15.824 6.0838 15.5024 5.21714L8 4.52148L0.497594 5.21714C0.176031 6.0838 0 7.02117 0 7.99973H0Z"
          fill="#FFDA44"
        />
        <path
          d="M15.5019 5.21741C14.3715 2.17103 11.4392 0 7.99947 0C4.55976 0 1.62741 2.17103 0.49707 5.21741H15.5019Z"
          fill="#D80027"
        />
        <path
          d="M0.49707 10.7832C1.62741 13.8296 4.55976 16.0006 7.99947 16.0006C11.4392 16.0006 14.3715 13.8296 15.5019 10.7832H0.49707Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0_3176_7843">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SpanishFlag;
