import { FC, ReactNode } from 'react';

import AccountLoader from '@common/components/account-loader/AccountLoader';
import { useSelector } from '@common/hooks';
import { selectUserLoading } from '@common/redux/selectors/user';
import { TRANSITION_ANIMATION } from '@src/assets/animations/motion.animations';
import { motion } from 'framer-motion';

interface AccountCardProps {
  children?: ReactNode;
}

const AccountCard: FC<AccountCardProps> = ({ children }) => {
  const loading = useSelector(selectUserLoading);

  return (
    <motion.div
      {...TRANSITION_ANIMATION}
      className="flex w-full flex-1  flex-col items-center gap-6 rounded-2xl bg-white px-3 py-7 sm:p-7 "
    >
      {loading ? <AccountLoader /> : children}
    </motion.div>
  );
};

export default AccountCard;
