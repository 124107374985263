import { FC } from 'react';

import { IconProps } from './types';

const ArrowLeftIcon: FC<IconProps> = ({ width = '16', height = '16' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M6.86193 13.1384C7.12228 13.3987 7.54439 13.3987 7.80474 13.1384C8.06509 12.878 8.06509 12.4559 7.80474 12.1956L4.27614 8.66699H13.3333C13.7015 8.66699 14 8.36852 14 8.00033C14 7.63214 13.7015 7.33366 13.3333 7.33366H4.27614L7.80474 3.80506C8.06509 3.54471 8.06509 3.1226 7.80474 2.86225C7.54439 2.6019 7.12228 2.6019 6.86193 2.86225L2.19526 7.52892C1.93491 7.78927 1.93491 8.21138 2.19526 8.47173L6.86193 13.1384Z"
        fill="black"
      />
    </svg>
  );
};

export default ArrowLeftIcon;
