import { FC, useMemo } from 'react';

import BlackDotsLoader from '@common/components/loader/BlackDotsLoader';

import { getClassNames } from './InfoRow.classes';

interface InfoSectionItemProps {
  title: string;
  value: any;
  loading?: boolean;
}

const InfoRow: FC<InfoSectionItemProps> = ({ title, value, loading }) => {
  const classes = useMemo(() => getClassNames(), []);

  return (
    <div className={classes.infoSectionItemWrapper}>
      <div className={classes.infoSectionItemTitle}>{title}</div>
      {loading ? (
        <div className="max-w-[70px]">
          <BlackDotsLoader style={{ height: 20 }} />
        </div>
      ) : (
        <div className={classes.infoSectionItemValue}>{value}</div>
      )}
    </div>
  );
};

export default InfoRow;
