import { FC } from 'react';

import clsx from 'clsx';

import { colorStyles, sharedLabelStyles } from './InputLabel.styles';

export interface InputLabelProps {
  shouldMinimize: boolean;
  name?: string; // TODO: Remove this prop, not used
  text: string;
  styling?: 'regular' | 'smartsaver' | 'account';
}

const InputLabel: FC<InputLabelProps> = ({ shouldMinimize, name, text, styling = 'regular' }) => {
  return (
    <span
      className={clsx(
        sharedLabelStyles.base,
        shouldMinimize ? colorStyles[styling].minimizedColor : colorStyles[styling].baseColor,
        {
          [sharedLabelStyles.minimized]: shouldMinimize,
          [sharedLabelStyles.notMinimized]: !shouldMinimize,
        }
      )}
    >
      {text}
    </span>
  );
};

export default InputLabel;
