import { RootState } from '@common/redux';
import { initialState } from '@common/redux/slices/application';
import { createSelector } from '@reduxjs/toolkit';

export const makeSelectApplication = (state: RootState) =>
  'application' in state ? state.application : initialState;

export const selectApplication = (state: RootState) =>
  createSelector(makeSelectApplication, (application) => application);

export const selectFlowInstance = createSelector(
  makeSelectApplication,
  (application) => application.flowInstance
);

export const selectFlowIntstanceData = createSelector(
  makeSelectApplication,
  (application) => application.flowInstance?.data
);

export const selectFlowInstanceStep = createSelector(
  makeSelectApplication,
  (application) => application.flowInstance.data?.currentStepId
);

export const selectFlowInstanceId = createSelector(
  makeSelectApplication,
  (application) => application.flowInstance.data?.id
);

export const selectFlowInstanceLoading = createSelector(
  makeSelectApplication,
  (application) => application.flowInstance.loading
);

export const selectPrefilledData = createSelector(
  makeSelectApplication,
  (application) => application.flowInstance.data?.prefilledData ?? {}
);

export const selectMeta = createSelector(
  makeSelectApplication,
  (application) => application.flowInstance.meta ?? {}
);

export const selectResponseData = createSelector(
  makeSelectApplication,
  (application) => application.flowInstance.data?.responseData ?? {}
);
