import { useCallback, useEffect, useState } from 'react';

import LogoIcon from '@common/components/icons/LogoIcon';
import { delay } from '@common/utils';
import MainLayout from '@monefit-es/components/layout/MainLayout';
import defaultLoaderSrc from '@src/assets/img/monefit-es/main_loader.webp';

import MainLoader from './MainLoader';

const AnimatedWelcomeLoaderStart = ({ onAnimationEnd }: { onAnimationEnd: () => void }) => {
  const [step, setStep] = useState(0);

  const handleAnimation = useCallback(async () => {
    setStep(1);
    await delay(2300);
    setStep(2);
    await delay(500);
    setStep(3);
    await delay(1000);
    onAnimationEnd();
  }, [onAnimationEnd]);

  useEffect(() => {
    handleAnimation();
  }, [handleAnimation]);

  return (
    <MainLayout hideHeaderAndFooter>
      <>
        {step === 1 && (
          <div className="animate-zoomIn">
            <LogoIcon width="126" height="126" />
          </div>
        )}
        {step === 2 && (
          <div
            className="animate-fadeIn rounded-full"
            style={{
              backgroundImage: `url(${defaultLoaderSrc})`,
              width: '126px',
              height: '126px',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }}
          ></div>
        )}
        {step === 3 && (
          <MainLoader type="image" showIndicator={false} extraClassNames="animate-growToSquare" />
        )}
      </>
    </MainLayout>
  );
};

export default AnimatedWelcomeLoaderStart;
