import { SmartSaverLocales } from '@smartsaver/hooks/useLocale';

export const LINKS = {
  [SmartSaverLocales.EN]: {
    aboutSmartSaver: 'https://monefit.com/smartsaver/',
    faq: 'https://help.monefit.com/en/collections/6537335-smartsaver',
    termsOfUse: 'https://monefit.com/wp-content/uploads/2024/11/Terms_of_Use_EN_1.pdf',
    privacyPolicy: 'https://monefit.com/wp-content/uploads/2024/11/Privacy_Policy_EN_1.pdf',
    cookiesPolicy: 'https://monefit.com/wp-content/uploads/2024/10/Cookie_Policy_EN_1.pdf',
    referAFriend: 'https://smartsaver-stage.monefit.com/en/login',
    aboutUs: 'https://monefit.com/about/',
    careers: 'https://jobs.workable.com/company/6AeksDM9cnH5AgYE9nUGnC/jobs-at-creditstar',
    publicSite: 'https://monefit.com',
    contactUs: 'https://monefit.com/contact-us/',
    legal: 'https://monefit.com/legal/',
  },
  [SmartSaverLocales.ET]: {
    aboutSmartSaver: 'https://monefit.com/ee-ee/smartsaver/',
    faq: 'https://help.monefit.com/en/collections/6537335-smartsaver',
    termsOfUse: 'https://monefit.com/wp-content/uploads/2024/11/Terms_of_Use_EE_1.pdf',
    privacyPolicy: 'https://monefit.com/wp-content/uploads/2024/11/Privacy_Policy_EE_1.pdf',
    cookiesPolicy: 'https://monefit.com/wp-content/uploads/2024/10/Cookie_Policy_EE_1.pdf',
    referAFriend: 'https://smartsaver-stage.monefit.com/ee/login',
    aboutUs: 'https://monefit.com/ee-ee/meist/',
    careers: 'https://jobs.workable.com/company/6AeksDM9cnH5AgYE9nUGnC/jobs-at-creditstar',
    publicSite: 'https://monefit.com/ee-ee',
    contactUs: 'https://monefit.com/ee-ee/kontakt/',
    legal: 'https://monefit.com/ee-ee/dokumendid/',
  },
  [SmartSaverLocales.DE]: {
    aboutSmartSaver: 'https://monefit.com/de-de/smartsaver/',
    faq: 'https://help.monefit.com/de/collections/6537335-smartsaver',
    termsOfUse: 'https://monefit.com/wp-content/uploads/2024/11/Terms_of_Use_DE_1.pdf',
    privacyPolicy: 'https://monefit.com/wp-content/uploads/2024/11/Privacy_Policy_DE_1.pdf',
    cookiesPolicy: 'https://monefit.com/wp-content/uploads/2024/10/Cookie_Policy_DE_1.pdf',
    referAFriend: 'https://smartsaver-stage.monefit.com/de/login',
    aboutUs: 'https://monefit.com/de-de/uber-uns/',
    careers: 'https://jobs.workable.com/company/6AeksDM9cnH5AgYE9nUGnC/jobs-at-creditstar',
    publicSite: 'https://monefit.com/de-de',
    contactUs: 'https://monefit.com/de-de/kontakt',
    legal: 'https://monefit.com/de-de/legal/',
  },
  [SmartSaverLocales.ES]: {
    aboutSmartSaver: 'https://monefit.com/es-es/smartsaver/',
    faq: 'https://help.monefit.com/es/collections/6537335-smartsaver',
    termsOfUse: 'https://monefit.com/wp-content/uploads/2024/11/Terms_of_Use_ES_1.pdf',
    privacyPolicy: 'https://monefit.com/wp-content/uploads/2024/11/Privacy_Policy_ES_1.pdf',
    cookiesPolicy: 'https://monefit.com/wp-content/uploads/2024/10/Cookie_Policy_ES_1.pdf',
    referAFriend: 'https://smartsaver-stage.monefit.com/es/login',
    aboutUs: 'https://monefit.com/es-es/sobre-nosotros/',
    careers: 'https://jobs.workable.com/company/6AeksDM9cnH5AgYE9nUGnC/jobs-at-creditstar',
    publicSite: 'https://monefit.com/es-es',
    contactUs: 'https://monefit.com/es-es/contacto/',
    legal: 'https://monefit.com/es-es/legal/',
  },
  [SmartSaverLocales.FR]: {
    aboutSmartSaver: 'https://monefit.com/fr-fr/smartsaver/',
    faq: 'https://help.monefit.com/fr/collections/6537335-smartsaver',
    termsOfUse: 'https://monefit.com/wp-content/uploads/2024/11/Terms_of_Use_FR_1.pdf',
    privacyPolicy: 'https://monefit.com/wp-content/uploads/2024/11/Privacy_Policy_FR_1.pdf',
    cookiesPolicy: 'https://monefit.com/wp-content/uploads/2024/10/Cookie_Policy_FR_1.pdf',
    referAFriend: 'https://smartsaver-stage.monefit.com/fr/login',
    aboutUs: 'https://monefit.com/fr-fr/a-propos-de-nous/',
    careers: 'https://jobs.workable.com/company/6AeksDM9cnH5AgYE9nUGnC/jobs-at-creditstar',
    publicSite: 'https://monefit.com/fr-fr',
    contactUs: 'https://monefit.com/fr-fr/contactez-nous/',
    legal: 'https://monefit.com/fr-fr/page-juridique/',
  },
};
