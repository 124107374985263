import OtpCodeForm, { OtpFormType } from '@common/components/otp-code-form/OtpCodeForm';
import useUserSessionData from '@common/hooks/useUserSessionData';
import MainLayout from '@monefit-es/components/layout/MainLayout';

const PhoneOtpPage = () => {
  const { getFromStorage } = useUserSessionData();

  return (
    <MainLayout type="onboarding">
      <OtpCodeForm
        emailOrPhone={getFromStorage()?.phone ?? ''}
        type={OtpFormType.PHONE}
        hasTimer
        codeLength={4}
        submitOnCodeEnter
      />
    </MainLayout>
  );
};

export default PhoneOtpPage;
