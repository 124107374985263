import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  AddButtonTemplate,
  ArrayFieldItemTemplate,
  FieldTemplate,
  SubmitButtonTemplate,
} from '@common/components/json-form-templates';
import {
  CurrencyWidget,
  SelectWidget,
  TextWidget,
  YesNoWidget,
} from '@common/components/json-form-widgets';
import SignupBaseCard from '@common/components/sign-up-layout/SignupBaseCard';
import { useApplicationFlowInstance, useDispatch, useSelector } from '@common/hooks';
import { RootDispatch } from '@common/redux';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions } from '@common/services/application';
import Form, { IChangeEvent } from '@rjsf/core';
import { RJSFSchema, RJSFValidationError, RegistryWidgetsType, UiSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { isEmpty } from 'lodash';

import m from './QuestionnaireForm.messages';
import useQuestionnaireForm from './useQuestionnaireForm';

interface AdditionalIncome {
  additionalIncomeType:
    | 'part_time'
    | 'self_employed'
    | 'social_benefits'
    | 'interest_from_Investment'
    | 'rent';
  additionalMonthlyNetIncome: number;
}

interface ActiveCredit {
  activeCreditAmount: number;
}

interface FormData {
  employmentType?:
    | 'full_time'
    | 'part_time'
    | 'self_employed'
    | 'social_benefits'
    | 'pension'
    | 'unemployed';
  employerName?: string;
  taxIdentificationNumber?: string;
  monthlyNetIncome?: number;
  hasAdditionalIncome?: boolean;
  additionalIncomes?: AdditionalIncome[];
  monthlyExpenses?: number;
  monthlyExpensesText?: string;
  hasActiveCredit?: boolean;
  activeCredits?: ActiveCredit[];
}

const QuestionnaireForm = () => {
  const { formatMessage } = useIntl();
  const [instanceId] = useApplicationFlowInstance();
  const dispatch = useDispatch<RootDispatch>();
  const [formData, setFormData] = useState<FormData>({} as FormData);
  const { data } = useSelector((st) => st.application.flowInstance);
  const [touchedFields, setTouchedFields] = useState<{ [key: string]: boolean }>({});
  const { enumMapper } = useQuestionnaireForm();
  const schema = useMemo(() => data?.responseData?.schema || null, [data?.responseData?.schema]);

  const handleBlur = (field: string) => {
    setTouchedFields((prevTouchedFields) => ({
      ...prevTouchedFields,
      [field]: true,
    }));
  };

  const handleSubmit = useCallback(
    async (data: IChangeEvent<FormData, RJSFSchema, any>) => {
      await dispatch(
        performApplicationFlowAction({
          action: FlowActions.SUBMIT,
          instanceId,
          data: {
            questionnaire: {
              ...data.formData,
              activeCredits: data?.formData?.activeCredits?.filter((x) => !isEmpty(x)),
              additionalIncomes: data?.formData?.additionalIncomes?.filter((x) => !isEmpty(x)),
            },
          },
        })
      );
    },
    [dispatch, instanceId]
  );

  const removeFromTouchedFiedsByKey = useCallback(
    (keyValue: string) => {
      const filteredTouched = Object.fromEntries(
        Object.entries(touchedFields).filter(([key]) => !key.includes(keyValue))
      );
      setTouchedFields(filteredTouched);
    },
    [touchedFields]
  );

  const showEmployerName = useMemo(
    () => formData.employmentType && ['part_time', 'full_time'].includes(formData.employmentType),
    [formData.employmentType]
  );

  useEffect(() => {
    if (formData.employmentType !== 'self_employed') {
      removeFromTouchedFiedsByKey('taxIdentificationNumber');
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        taxIdentificationNumber: '',
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.employmentType]);

  useEffect(() => {
    if (!showEmployerName) {
      removeFromTouchedFiedsByKey('employerName');
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        employerName: '',
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEmployerName]);

  useEffect(() => {
    if (formData.hasAdditionalIncome === false) {
      removeFromTouchedFiedsByKey('additionalIncomes_');
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        additionalIncomes: [{}],
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.hasAdditionalIncome]);

  useEffect(() => {
    if (formData.hasActiveCredit === false) {
      removeFromTouchedFiedsByKey('activeCredits_');
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        activeCredits: [{}],
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.hasActiveCredit]);

  const uiSchema: UiSchema<any, RJSFSchema, any> = {
    employmentType: {
      'ui:description': formatMessage(m.mainSourceIncomeText),
      'ui:title': formatMessage(m.dropdownSelectLabel),
      'ui:touched': touchedFields,
    },
    employerName: {
      'ui:widget': showEmployerName ? TextWidget : 'hidden',
      'ui:title': formatMessage(m.employerLabel),
      'ui:touched': touchedFields,
    },
    monthlyNetIncome: {
      'ui:title': formatMessage(m.monthlyIncomeLablel),
      'ui:widget': CurrencyWidget,
      'ui:touched': touchedFields,
    },
    hasAdditionalIncome: {
      'ui:title': formatMessage(m.hasAdditionalIncomeLabel),
      'ui:classNames': 'mt-7',
      'ui:touched': touchedFields,
    },
    taxIdentificationNumber: {
      'ui:widget': formData.employmentType === 'self_employed' ? TextWidget : 'hidden',
      'ui:title': formatMessage(m.taxIdentificationNumberLabel),
      'ui:touched': touchedFields,
    },

    additionalIncomes: {
      ...(!formData.hasAdditionalIncome && { 'ui:field': () => <></> }),
      'ui:ArrayFieldDescriptionTemplate': 'hidden',
      'ui:ArrayFieldTitleTemplate': 'hidden',
      items: {
        'ui:title': '',
        additionalMonthlyNetIncome: {
          'ui:widget': CurrencyWidget,
          'ui:title': formatMessage(m.additionalMonthlyNetIncome),
          'ui:touched': touchedFields,
        },
        additionalIncomeType: {
          'ui:title': formatMessage(m.sourceDropdownSelectLabel),
          'ui:touched': touchedFields,
        },
      },
    },

    monthlyExpenses: {
      'ui:widget': CurrencyWidget,
      'ui:classNames': 'mt-10',
      'ui:description': formatMessage(m.totalMonthlyExpensesText),
      'ui:help': formatMessage(m.monthlyExpensesHelpText),
      'ui:title': formatMessage(m.monthlyExpensesLabel),
      'ui:touched': touchedFields,
    },
    monthlyExpensesText: {
      'ui:widget': 'hidden',
    },
    hasActiveCredit: {
      'ui:title': formatMessage(m.hasActiveCreditsLabel),
      'ui:touched': touchedFields,
    },
    activeCredits: {
      ...(!formData.hasActiveCredit && { 'ui:field': () => <></> }),
      'ui:ArrayFieldDescriptionTemplate': 'hidden',
      'ui:ArrayFieldTitleTemplate': 'hidden',
      items: {
        'ui:title': '',
        activeCreditAmount: {
          'ui:widget': CurrencyWidget,
          'ui:title': formatMessage(m.activeCreditAmountLabel),
          'ui:touched': touchedFields,
        },
      },
    },
  };

  const widgets: RegistryWidgetsType = {
    SelectWidget: (props) => SelectWidget({ ...props, enumMapper }),
    CheckboxWidget: YesNoWidget,
  };

  const handleFormChange = (data: IChangeEvent<FormData, RJSFSchema, any>, id?: string) => {
    data.formData && setFormData(data.formData as FormData);
  };

  const errorMessageMapper = useCallback(
    (error: RJSFValidationError) => {
      switch (error.name) {
        case 'required':
          return formatMessage(m.mandatoryFieldError);
        case 'minimum':
          return formatMessage(m.minimumFieldError);
        default:
          return error.message;
      }
    },
    [formatMessage]
  );

  const transformErrors = (errors: RJSFValidationError[]) => {
    const mappedErrors = errors.map((error) => ({
      ...error,
      message: errorMessageMapper(error),
    }));

    let filteredErrors = mappedErrors;

    if (!formData.hasAdditionalIncome) {
      filteredErrors = filteredErrors.filter(
        (error) => !error?.property?.startsWith('.additionalIncomes')
      );
    }

    if (!formData.hasActiveCredit) {
      filteredErrors = filteredErrors.filter(
        (error) => !error?.property?.startsWith('.activeCredits')
      );
    }

    if (showEmployerName && !formData.employerName) {
      filteredErrors.push({
        name: 'required',
        property: '.employerName',
        stack: "must have required property 'employerName'",
        schemaPath: '#/required',
        params: { missingProperty: 'employerName' },
        message: formatMessage(m.mandatoryFieldError),
      });
    } else {
      filteredErrors = filteredErrors.filter(
        (error) => !error.property?.startsWith('.employerName')
      );
    }

    if (
      formData.employmentType === 'self_employed' &&
      (!formData.taxIdentificationNumber || formData.taxIdentificationNumber === '')
    ) {
      filteredErrors.push({
        name: 'required',
        property: '.taxIdentificationNumber',
        stack: "must have required property 'taxIdentificationNumber'",
        schemaPath: '#/required',
        params: { missingProperty: 'taxIdentificationNumber' },
        message: formatMessage(m.mandatoryFieldError),
      });
    } else {
      filteredErrors = filteredErrors.filter(
        (error) => !error.property?.startsWith('.taxIdentificationNumber')
      );
    }

    return filteredErrors;
  };

  const formRef = useRef<Form | null>(null);

  if (!schema) {
    return null;
  }

  return (
    <SignupBaseCard
      headerText={formatMessage(m.headerText)}
      subHeaderText={formatMessage(m.subHeaderText)}
    >
      <Form
        className="w-full"
        schema={schema as any}
        validator={validator}
        uiSchema={uiSchema}
        templates={{
          FieldTemplate,
          ButtonTemplates: {
            SubmitButton: (props) =>
              SubmitButtonTemplate({
                ...props,
                text: formatMessage(m.continue),
                disabled: !formRef?.current?.validateForm(),
              }),

            AddButton: (props) =>
              AddButtonTemplate({ ...props, buttonText: formatMessage(m.addButtonText) }),
          },
          ErrorListTemplate: () => <></>,
          ArrayFieldItemTemplate,
        }}
        widgets={widgets}
        noHtml5Validate
        ref={formRef}
        transformErrors={transformErrors}
        formData={formData}
        onSubmit={(data) => handleSubmit(data)}
        onChange={handleFormChange}
        onBlur={(id) => handleBlur(id)}
        liveValidate
      />
    </SignupBaseCard>
  );
};

export default QuestionnaireForm;
