import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  credit: {
    defaultMessage: 'Credit',
    description: '@monefit-es Credit',
  },
  subscription: {
    defaultMessage: 'Credit+',
    description: '@monefit-es Credit+',
  },
  bestValue: {
    defaultMessage: 'Best value',
    description: '@monefit-es Best value',
  },
  percentageText: {
    defaultMessage: 'credit limit with {percentage}% p.a.',
    description: '@monefit-es {percentage}% p.a.',
  },
  subscrptionPercentageText: {
    defaultMessage: 'credit limit with 0% p.a.*',
    description: '@monefit-es 0% p.a.',
  },
  buttonText: {
    defaultMessage: 'Select',
    description: '@monefit-es Select',
  },
  subscriptionFeeText: {
    defaultMessage: '{fee} € monthly subscription applies',
    description: '@monefit-es {fee} € monthly subscription applies',
  },
  termsText: {
    defaultMessage: 'Subscription terms and conditions',
    description: '@monefit-es Subscription terms and conditions',
  },
});
