import { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import Button, { ButtonSpacing } from '@common/components/button/Button';
import FileUploadInput from '@common/components/file-upload-input/FileUploadInput';
import AddFilesIcon from '@common/components/icons/AddFilesIcon';
import ArrowLeftIcon from '@common/components/icons/ArrowLeftIcon';
import CloseIcon2 from '@common/components/icons/CloseIcon2';
import PaperClipIcon from '@common/components/icons/PaperClipIcon';
import InputError from '@common/components/input-error/InputError';
import useTailwindBreakpoints from '@common/hooks/useTailwindBreakpoints';
import { yupResolver } from '@hookform/resolvers/yup';
import DataProtectedBlock from '@smartsaver/components/data-protected-block/DataProtectedBlock';
import { useLocale } from '@smartsaver/hooks/useLocale';
import clsx from 'clsx';

import m from './SignupCompanyDocuments.messages';
import { FormSchema, getFormSchema } from './SignupCompanyDocuments.schema';

const SignupCompanyDocuments = () => {
  const { isMobile } = useTailwindBreakpoints();
  const { formatMessage } = useIntl();
  const { locale } = useLocale();
  const navigate = useNavigate();
  const schema = useMemo(() => getFormSchema({ formatMessage }), [formatMessage]);

  const documentsRequirements = useMemo(
    () => [
      m.documentRequirement1,
      m.documentRequirement2,
      m.documentRequirement3,
      m.documentRequirement4,
    ],
    []
  );

  const onBackClick = useCallback(() => navigate(`/${locale}/signup-company`), [navigate, locale]);

  const createFileList = useCallback((files: File[]) => {
    const dataTransfer = new DataTransfer();
    files.forEach((file) => dataTransfer.items.add(file));
    return dataTransfer.files;
  }, []);

  const ctx = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const {
    formState: { isSubmitting, isValid, errors },
  } = ctx;

  const files = ctx.watch('files');
  const filesArray = useMemo(() => Array.from(files ?? []), [files]);

  const handleFileDelete = useCallback(
    (index: number) => {
      filesArray.splice(index, 1);
      const fileList = createFileList(filesArray);
      ctx.setValue('files', fileList);
      ctx.trigger('files');
    },

    [filesArray, ctx, createFileList]
  );

  return (
    <form
      autoComplete="off"
      className="flex w-full flex-col items-center gap-3"
      onSubmit={ctx.handleSubmit(async (v: FormSchema) => {
        try {
          // TODO: handler
          console.log('Files:', v);
        } catch (_) {}
      })}
    >
      <FormProvider {...ctx}>
        <div className="flex w-full max-w-[750px] flex-col gap-8 rounded-2xl bg-white px-6 py-11 sm:gap-11 sm:px-16">
          {!isMobile && (
            <div>
              <Button
                spacing={ButtonSpacing.NONE}
                color="underlined-black"
                fontClassName="font-semibold"
                text={formatMessage(m.backButton)}
                onClick={onBackClick}
                type="button"
                icon={<ArrowLeftIcon width="18" height="18" />}
              />
            </div>
          )}
          <div className="text-2xl font-bold tracking-tight sm:text-3xl">
            {formatMessage(m.tilte)}
          </div>
          <div>
            <ul className="ml-6 flex  list-disc flex-col gap-6">
              {documentsRequirements.map((r, i) => (
                <li key={`${String(r.defaultMessage)}_${i}`}>{formatMessage(r)}</li>
              ))}
            </ul>
          </div>

          <div className="flex w-full justify-center">
            <FileUploadInput
              name="files"
              multiple
              fullWidth={isMobile ?? false}
              color={(files?.length ?? 0) > 0 ? 'blue' : 'black'}
              text={formatMessage(m.addDocumentsButton)}
              icon={<AddFilesIcon />}
            />
          </div>
          {isMobile && <DataProtectedBlock />}
        </div>

        <div className="flex w-full max-w-[750px] flex-col items-center justify-center gap-7">
          {!!filesArray.length && (
            <div
              className={clsx(
                {
                  'justify-start': filesArray?.length > 2 && !isMobile,
                  'justify-center': filesArray?.length <= 2 || isMobile,
                },
                'flex w-full flex-wrap items-start justify-start gap-1.5'
              )}
            >
              {filesArray.map((file: File, index: number) => (
                <UploadedFileItem
                  key={`${file?.name}_${index}`}
                  index={index}
                  fileName={file?.name}
                  handleDelete={handleFileDelete}
                  isMobile={isMobile}
                />
              ))}
            </div>
          )}

          {errors.files && !!files?.length && (
            <div className="px-6">
              <InputError message={errors.files.message ?? ''} />
            </div>
          )}

          {!!files?.length && (
            <div className="flex w-full justify-center">
              <Button
                text={formatMessage(m.submitButton)}
                type="submit"
                disabled={!isValid || isSubmitting}
              />
            </div>
          )}
          <div className="flex w-full justify-center">
            {isMobile && (
              <Button
                spacing={ButtonSpacing.NONE}
                color="underlined-black"
                fontClassName="font-semibold"
                text={formatMessage(m.backButton)}
                onClick={onBackClick}
                type="button"
              />
            )}
          </div>
          {!isMobile && <DataProtectedBlock />}
        </div>
      </FormProvider>
    </form>
  );
};

export default SignupCompanyDocuments;

const UploadedFileItem = ({
  fileName,
  index,
  handleDelete,
  isMobile,
}: {
  fileName: string;
  index: number;
  handleDelete: (index: number) => void;
  isMobile: boolean;
}) => {
  return (
    <div
      className={clsx(
        { 'w-[245px]': !isMobile, 'w-[325px]': isMobile },
        'flex h-16 items-center justify-start gap-2 rounded-xl bg-white px-3 py-5'
      )}
    >
      <div className="flex w-full items-center justify-between gap-4">
        <PaperClipIcon />
        <div className="relative w-full overflow-hidden ">
          <div className="truncate">{fileName}</div>
        </div>
        <button onClick={() => handleDelete(index)} type="button">
          <CloseIcon2 />
        </button>
      </div>
    </div>
  );
};
