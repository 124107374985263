const PencilDrawIcon = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7071 0.292893C15.3166 -0.0976311 14.6834 -0.0976311 14.2929 0.292893L0.292893 14.2929C0.105357 14.4804 0 14.7348 0 15V19C0 19.5523 0.447715 20 1 20H5C5.26522 20 5.51957 19.8946 5.70711 19.7071L19.7071 5.70711C20.0976 5.31658 20.0976 4.68342 19.7071 4.29289L15.7071 0.292893ZM16 6.58579L13.4142 4L15 2.41421L17.5858 5L16 6.58579ZM12 5.41421L14.5858 8L6 16.5858L3.41421 14L12 5.41421ZM2 15.4142L4.58579 18H2V15.4142Z"
        fill="currentColor"
      />
      <path
        d="M11 18C10.4477 18 10 18.4477 10 19C10 19.5523 10.4477 20 11 20H19C19.5523 20 20 19.5523 20 19C20 18.4477 19.5523 18 19 18H11Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PencilDrawIcon;
