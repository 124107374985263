import { FC, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { FormattedNumber, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useIntercom } from 'react-use-intercom';

import Button, { ButtonSpacing } from '@common/components/button/Button';
import BlackDotsLoader from '@common/components/loader/BlackDotsLoader';
import { Currency, LINKS } from '@common/constants';
import { SubscriptionName } from '@common/enums';
import { useDispatch } from '@common/hooks';
import { useCookieConsents } from '@common/hooks/useCookieConsents';
import useEnvironment from '@common/hooks/useEnvironment';
import useSubsciptions from '@common/hooks/useSubscriptions';
import { RootDispatch } from '@common/redux';
import {
  selectActiveAgreement,
  selectLimitLoading,
  selectUserLimit,
} from '@common/redux/selectors/banking';
import { selectDocuments } from '@common/redux/selectors/documents';
import { getUserLimit } from '@common/redux/thunks/banking';
import { downloadDocument } from '@common/redux/thunks/documents';
import clsx from 'clsx';

import m from './CreditDetails.messages';

interface CreditDetailsRowProps {
  title: string;
  children: ReactNode;
  extraClassNames?: string;
}

const CreditDetailsRow: FC<CreditDetailsRowProps> = ({ title, children, extraClassNames }) => {
  return (
    <div className={clsx('flex justify-between', extraClassNames)}>
      <span className="text-base font-medium leading-normal text-black/60">{title}</span>
      {children}
    </div>
  );
};

const CreditDetails = () => {
  const { formatMessage } = useIntl();
  const userLimit = useSelector(selectUserLimit);
  const { isDevOrStagingEnv } = useEnvironment();
  const userLimitLoading = useSelector(selectLimitLoading);
  const dispatch = useDispatch<RootDispatch>();
  const documents = useSelector(selectDocuments);
  const activeAgreement = useSelector(selectActiveAgreement);
  const { loading, getHasActiveSubsctiption } = useSubsciptions();
  const { consents, loaded: consentsLoaded } = useCookieConsents();
  const { showNewMessage } = useIntercom();
  const canOpenIntercom = useMemo(
    () => (isDevOrStagingEnv ? true : consentsLoaded && consents.necessary),
    [isDevOrStagingEnv, consentsLoaded, consents.necessary]
  );
  const hasSubscription = getHasActiveSubsctiption(SubscriptionName.CREDIT_LINE_PREMIUM);

  const fetchLimit = useCallback(
    async (agreementId?: string) => {
      if (userLimit || !agreementId || userLimitLoading) {
        return;
      }

      await dispatch(getUserLimit(agreementId)).unwrap();
    },
    [dispatch, userLimit, userLimitLoading]
  );

  const handleDocumentDownload = useCallback(
    async (id?: string, name?: string) => {
      if (!id || !name) {
        toast.error(formatMessage(m.downloadError));
        return;
      }
      await dispatch(downloadDocument({ id: id, name: name }))
        .unwrap()
        .catch(() => toast.error(formatMessage(m.downloadError)));
    },
    [dispatch, formatMessage]
  );

  const handleSubscriptionUpdate = useCallback(() => {
    if (canOpenIntercom) {
      showNewMessage();
    } else {
      window.location.href = LINKS.ES_MAILTO;
    }
  }, [showNewMessage, canOpenIntercom]);

  const agreementDocument = useMemo(
    () => documents?.find((doc) => doc.type === 'agreement' && doc.isSigned),
    [documents]
  );
  const secciDocument = useMemo(() => documents?.find((doc) => doc.type === 'secci'), [documents]);

  useEffect(() => {
    fetchLimit(activeAgreement?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAgreement?.id]);

  return (
    <div className="flex w-full flex-col gap-5 rounded-2xl bg-white px-3 py-4 sm:p-12">
      <CreditDetailsRow title={formatMessage(m.approvedCredit)}>
        <span className="font-semibold">
          {userLimitLoading ? (
            <BlackDotsLoader style={{ height: 25 }} />
          ) : (
            <FormattedNumber
              // eslint-disable-next-line react/style-prop-object
              style="currency"
              currency={Currency.EUR}
              currencyDisplay="symbol"
              minimumFractionDigits={0}
              maximumFractionDigits={0}
              value={userLimit?.limit ?? 0}
            />
          )}
        </span>
      </CreditDetailsRow>
      <CreditDetailsRow title={formatMessage(m.usedCredit)}>
        <span className="font-semibold">
          {userLimitLoading ? (
            <BlackDotsLoader style={{ height: 25 }} />
          ) : (
            <FormattedNumber
              // eslint-disable-next-line react/style-prop-object
              style="currency"
              currency={Currency.EUR}
              currencyDisplay="symbol"
              minimumFractionDigits={2}
              maximumFractionDigits={2}
              value={userLimit?.balance ?? 0}
            />
          )}
        </span>
      </CreditDetailsRow>
      {!!secciDocument && (
        <CreditDetailsRow extraClassNames="items-center" title={formatMessage(m.secci)}>
          <span className="font-semibold">
            <Button
              spacing={ButtonSpacing.SM}
              color="gray"
              text={formatMessage(m.download)}
              onClick={() => handleDocumentDownload(secciDocument?.id, secciDocument?.name)}
            />
          </span>
        </CreditDetailsRow>
      )}
      {!!agreementDocument && (
        <CreditDetailsRow extraClassNames="items-center" title={formatMessage(m.loanAgreement)}>
          <span className="font-semibold">
            <Button
              spacing={ButtonSpacing.SM}
              color="gray"
              type="button"
              text={formatMessage(m.download)}
              onClick={() => handleDocumentDownload(agreementDocument?.id, agreementDocument?.name)}
            />
          </span>
        </CreditDetailsRow>
      )}
      <CreditDetailsRow title={formatMessage(m.subscription)} extraClassNames="items-center">
        <span className="font-semibold">
          {loading ? (
            <BlackDotsLoader style={{ height: 25 }} />
          ) : (
            <Button
              type="button"
              onClick={handleSubscriptionUpdate}
              spacing={ButtonSpacing.SM}
              color="gray"
              text={formatMessage(hasSubscription ? m.downgradeButton : m.upgradeBtnText)}
            />
          )}
        </span>
      </CreditDetailsRow>
    </div>
  );
};

export default CreditDetails;
