import { LocalStorageKeys } from '@common/constants';

import { getDataFromStorage } from './sessionStorage';

export const storeToken = (token: string) => {
  const sessionStorageData = getDataFromStorage();
  const newData = { ...sessionStorageData, token };
  sessionStorage.setItem(LocalStorageKeys.SMARTSAVER, JSON.stringify(newData));
};

export const getToken = () => {
  const sessionStorageData = getDataFromStorage();
  return sessionStorageData?.token ?? null;
};
