import { useIntl } from 'react-intl';

import AccountCard from '@common/components/account-card/AccountCard';
import Button from '@common/components/button/Button';
import AlertIcon from '@common/components/icons/AlertIcon';
import { useLocale } from '@common/hooks';
import { handleLogout } from '@common/utils';

import m from './AccountLogout.messages';

const AccountLogout = () => {
  const { localeWithCountry } = useLocale();
  const { formatMessage } = useIntl();
  return (
    <AccountCard>
      <AlertIcon />

      <div className="max-w-72 text-center text-base font-normal text-black ">
        {formatMessage(m.descriptionText)}
      </div>
      <Button
        fullWidth
        text={formatMessage(m.buttonText)}
        onClick={() => handleLogout(localeWithCountry)}
      />
    </AccountCard>
  );
};

export default AccountLogout;
