import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import Button from '@common/components/button/Button';
import DatePicker from '@common/components/datepicker/Datepicker';
import SelectInput from '@common/components/select-input/SelectInput';
import TextInput from '@common/components/text-input/TextInput';
import useCountries from '@common/hooks/useCountries';
import { yupResolver } from '@hookform/resolvers/yup';
import BaseCard from '@smartsaver/components/base-card/BaseCard';
import DataProtectedBlock from '@smartsaver/components/data-protected-block/DataProtectedBlock';
import IndividualCompanySingupSwitch from '@smartsaver/components/individual-company-signup-switch/IndividualCompanySingupSwitch';
import SignupFormSection from '@smartsaver/components/sign-up-form-section/SignupFormSection';
import { useLocale } from '@smartsaver/hooks/useLocale';
import { UseMutationResult } from '@tanstack/react-query';
import { subYears } from 'date-fns';

import m from './IndividualForm.messages';
import { InvididualFormSchema, getFormSchema } from './IndividualForm.schema';

interface IndividualFormProps {
  signupMutation: UseMutationResult<void, Error, InvididualFormSchema>;
}

const IndividualForm = ({ signupMutation }: IndividualFormProps) => {
  const { countriesOptionsForSelect } = useCountries();
  const { locale } = useLocale();
  const { formatMessage } = useIntl();
  const schema = useMemo(() => getFormSchema({ formatMessage }), [formatMessage]);

  const ctx = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const {
    formState: { isSubmitting, isValid },
  } = ctx;

  return (
    <form
      autoComplete="off"
      className="flex size-full justify-center "
      onSubmit={ctx.handleSubmit(async (v: InvididualFormSchema) => {
        try {
          signupMutation.mutate(v);
        } catch (_) {}
      })}
    >
      <BaseCard
        widthClassName="max-w-[500px]"
        title={formatMessage(m.title)}
        subTitle={formatMessage(m.subTitle)}
        gapClassName="gap-8"
      >
        <FormProvider {...ctx}>
          <div className="flex size-full flex-col gap-8">
            <IndividualCompanySingupSwitch />
            <SignupFormSection title={formatMessage(m.generalInfo)}>
              <TextInput
                aria-label="firstName"
                name="firstName"
                styling="smartsaver"
                label={formatMessage(m.firstName)}
              />
              <TextInput
                styling="smartsaver"
                aria-label="lastName"
                name="lastName"
                label={formatMessage(m.lastName)}
              />

              <DatePicker
                aria-label="birthdate"
                styling="smartsaver"
                showMonthDropdown
                dropdownMode="scroll"
                showYearDropdown
                scrollableYearDropdown
                maxDate={subYears(new Date(), 18)}
                yearDropdownItemNumber={90}
                locale={locale}
                dateFormat="dd.MM.yyyy"
                name="birthdate"
                label={formatMessage(m.birthDay)}
              />
              <SelectInput
                aria-label="taxCountry"
                name="taxCountry"
                styling="smartsaver"
                options={countriesOptionsForSelect}
                label={formatMessage(m.taxResidency)}
              />
            </SignupFormSection>
            <SignupFormSection title={formatMessage(m.yourAddress)}>
              <SelectInput
                aria-label="country"
                styling="smartsaver"
                name="country"
                data-testid="country"
                options={countriesOptionsForSelect}
                label={formatMessage(m.selectCountry)}
              />
              <TextInput
                styling="smartsaver"
                aria-label="city"
                name="city"
                label={formatMessage(m.city)}
              />
              <TextInput
                styling="smartsaver"
                aria-label="street"
                name="street"
                label={formatMessage(m.address)}
              />
              <TextInput
                styling="smartsaver"
                aria-label="zipcode"
                name="zipcode"
                label={formatMessage(m.postalCode)}
              />
            </SignupFormSection>
            <SignupFormSection title={formatMessage(m.promoCodeTitle)}>
              <TextInput
                styling="smartsaver"
                aria-label="promoCode"
                name="promoCode"
                label={formatMessage(m.promoCodeLabel)}
              />
            </SignupFormSection>
            <Button
              text={formatMessage(m.ctaButton)}
              type="submit"
              disabled={isSubmitting || !isValid}
            />
            <DataProtectedBlock />
          </div>
        </FormProvider>
      </BaseCard>
    </form>
  );
};

export default IndividualForm;
