import { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import Button from '@common/components/button/Button';
import BlackDotsLoader from '@common/components/loader/BlackDotsLoader';
import { useLocale } from '@common/hooks';
import DashboardInvoiceInformation from '@monefit-es/components/dashboard-invoice-information/DashboardInvoiceInformation';
import { getClassNames } from '@monefit-es/components/dashboard/Dashboard.classes';
import m from '@monefit-es/components/dashboard/Dashboard.messages';
import useLimitAndBalance from '@monefit-es/hooks/useLimitAndBalance';
import clsx from 'clsx';

interface DashboardInvoiceSectionProps {
  hasSubscription: boolean;
}

const DashboardInvoiceSection: FC<DashboardInvoiceSectionProps> = ({ hasSubscription }) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { localeWithCountry } = useLocale();

  const { balanceData, balanceError, balanceLoading } = useLimitAndBalance(
    { disableAgreementFetch: true, disableLimitFetch: true }
  );

  const noBalanceData = useMemo(
    () => !balanceLoading && balanceError,
    [balanceError, balanceLoading]
  );

  const usedCredit = balanceData?.totalOutstandingAmount ?? 0;

  const disableActionButtons = useMemo(
    () =>  balanceLoading || noBalanceData || usedCredit === 0,
    [balanceLoading, noBalanceData, usedCredit]
  );

  const classNames = useMemo(() => getClassNames(), []);

  const handleStartPayment = useCallback(() => {
    navigate(`/${localeWithCountry}/payment`);
  }, [navigate, localeWithCountry]);

  return (
    <div className={clsx('bg-[#E7E6E1]', classNames.card)}>
      <div className="flex flex-col items-start justify-start gap-3 self-stretch">
        <div className="inline-flex items-start justify-between gap-2 self-stretch">
          {balanceLoading ? (
            <BlackDotsLoader style={{ height: 60 }} />
          ) : (
            <DashboardInvoiceInformation
              disableDetailsButton={disableActionButtons}
              hasSubscription={hasSubscription}
            />
          )}
        </div>
      </div>
      <Button
        onClick={handleStartPayment}
        disabled={disableActionButtons}
        className="w-full"
        buttonNode={
          <div
            className={clsx(
              'border-transparent bg-white px-3 py-4 text-xl text-black',
              classNames.button,
              {
                [classNames.invoiceButtonsHover]: !disableActionButtons,
                [classNames.disabledInvoiceButtonText]: disableActionButtons,
              }
            )}
          >
            {formatMessage(m.paymentOptionsBtnText)}
          </div>
        }
      />
    </div>
  );
};

export default DashboardInvoiceSection;
