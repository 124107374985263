import { useCallback, useEffect, useState } from 'react';

import { useLocale } from '@common/hooks';
import useInactivityDetection from '@common/hooks/useInactivityDetection';
import { refreshToken } from '@common/services/protectedHttpService';
import { getSessionTimeLeft, handleLogout } from '@common/utils';

const INACTIVITY_TIME_LIMIT = 60; // 60s
const SILENT_TOKEN_REFRESH_TIME_TO_TRIGGER = 60; // 60s
const SILENT_TOKEN_REFRESH_ATTEMPTS_LIMIT = 3;

const useAuth = () => {
  const userTimeWithoutActions = useInactivityDetection();
  const [sessionTimeLeft, setSessionTimeLeft] = useState<number | null>(null);
  const { localeWithCountry } = useLocale();
  const [refreshIsInProgress, setRefreshIsInProgress] = useState(false);
  const [silentTokenRefreshAttempts, setSilentTokenRefreshAttempts] = useState(0);

  useEffect(() => {
    const handleSessionTime = () => {
      const timeLeft = getSessionTimeLeft();

      if (timeLeft) {
        setSessionTimeLeft(timeLeft);
      } else {
        setSessionTimeLeft(null);
        handleLogout(localeWithCountry);
      }
    };

    handleSessionTime();

    const interval = setInterval(handleSessionTime, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSilentTokenRefresh = useCallback(async () => {
    if (refreshIsInProgress || silentTokenRefreshAttempts >= SILENT_TOKEN_REFRESH_ATTEMPTS_LIMIT) {
      return;
    }

    const timeLeft = getSessionTimeLeft();

    if (
      userTimeWithoutActions <= INACTIVITY_TIME_LIMIT &&
      timeLeft &&
      timeLeft <= SILENT_TOKEN_REFRESH_TIME_TO_TRIGGER
    ) {
      try {
        setRefreshIsInProgress(true);
        await refreshToken();
        setSilentTokenRefreshAttempts(0);
      } catch (_) {
        setSilentTokenRefreshAttempts((prev) => prev + 1);
      } finally {
        setRefreshIsInProgress(false);
      }
    }
  }, [userTimeWithoutActions, refreshIsInProgress, silentTokenRefreshAttempts]);

  useEffect(() => {
    handleSilentTokenRefresh();
  }, [handleSilentTokenRefresh]);

  useEffect(() => {
    const timeLeft = getSessionTimeLeft();
    setSessionTimeLeft(timeLeft);
  }, []);

  return { sessionTimeLeft };
};

export default useAuth;
