import { getSmartSaverServiceBaseUrls } from '@smartsaver/utils/url';
import axios from 'axios';

const { accountBaseUrl } = getSmartSaverServiceBaseUrls();

export const accountService = axios.create({
  baseURL: accountBaseUrl,
  headers: {
    'Content-type': 'application/json',
  },
});

export const requestPasswordChange = async (email: string) => {
  const formData = new FormData();
  formData.append('email', email);
  await accountService.post('/forgot-password', formData);
};
