import { RootState } from '@common/redux';
import { initialState } from '@common/redux/slices/banking';
import { createSelector } from '@reduxjs/toolkit';

export const makeSelectBanking = (state: RootState) =>
  'banking' in state ? state.banking : initialState;

// loading state
export const selectMainLoading = createSelector(makeSelectBanking, (b) => b.loading);
export const selectLimitLoading = createSelector(makeSelectBanking, (b) => b.userLimit.loading);

export const selectBalanceLoading = createSelector(
  makeSelectBanking,
  (b) => b.agreementBalance.loading
);
export const selectBalanceSummaryLoading = createSelector(
  makeSelectBanking,
  (b) => b.agreementBalanceSummary.loading
);
export const selectInvoicesLoading = createSelector(makeSelectBanking, (b) => b.invoices.loading);

// error state
export const selectMainError = createSelector(makeSelectBanking, (b) => b.error);

export const selectBalanceError = createSelector(
  makeSelectBanking,
  (b) => b.agreementBalance.error
);
export const selectLimitError = createSelector(makeSelectBanking, (b) => b.userLimit.error);
export const selectBalanceSummaryError = createSelector(
  makeSelectBanking,
  (b) => b.agreementBalanceSummary.error
);
export const selectInvoicesError = createSelector(makeSelectBanking, (b) => b.invoices.error);

// data
export const selectActiveAgreement = createSelector(makeSelectBanking, (b) => b.activeAgreement);
export const selectBalanceSummary = createSelector(
  makeSelectBanking,
  (b) => b.agreementBalanceSummary.data
);
export const selectUserLimit = createSelector(makeSelectBanking, (b) => b.userLimit.data);
export const selectBalance = createSelector(makeSelectBanking, (b) => b.agreementBalance.data);
export const selectInvoices = createSelector(makeSelectBanking, (b) => b.invoices.data);
