import { FC } from 'react';

import { IconProps } from '@common/components/icons/types';

const EstonianFlag: FC<IconProps> = ({ height = '16', width = '16' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_983_8871)">
        <path
          d="M45 22.5411C45 25.2934 44.5049 27.9298 43.6005 30.3673L22.4997 31.3456L1.39888 30.3674C0.494381 27.9298 -0.00062561 25.2934 -0.00062561 22.5411C-0.00062561 19.7889 0.494381 17.1525 1.39888 14.715L22.4997 13.7366L43.6005 14.7149C44.5049 17.1525 45 19.7889 45 22.5411V22.5411Z"
          fill="black"
        />
        <path
          d="M22.4988 0.0407715C32.1731 0.0407715 40.4205 6.14679 43.5996 14.7149H1.3979C4.57713 6.14679 12.8245 0.0407715 22.4988 0.0407715Z"
          fill="#0052B4"
        />
        <path
          d="M1.39799 30.3672H43.5996C40.4205 38.9352 32.1731 45.0413 22.4988 45.0413C12.8245 45.0413 4.57713 38.9352 1.39799 30.3672Z"
          fill="#F0F0F0"
        />
      </g>
      <defs>
        <clipPath id="clip0_983_8871">
          <rect width="45" height="45" fill="white" transform="matrix(-1 0 0 1 45 0.0407715)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EstonianFlag;
