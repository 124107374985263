import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import EnglishFlag from '@common/components/icons/country-flags/EnglishFlag';
import EstonianFlag from '@common/components/icons/country-flags/EstonianFlag';
import FrenchFlag from '@common/components/icons/country-flags/FrenchFlag';
import GermanFlag from '@common/components/icons/country-flags/GermanFlag';
import SpanishFlag from '@common/components/icons/country-flags/SpanishFlag';
import { IconProps } from '@common/components/icons/types';
import { LocalStorageKeys } from '@common/constants';
import { AppId } from '@common/hooks';

interface LocaleContextType {
  locale: string;
  setLocale: (locale: string) => void;
  allowedLocales: string[] | null;
  languageMeta: Record<string, { flag: FC<IconProps>; name: string }>;
}

interface LocaleProviderProps {
  children: ReactNode;
  ingoreRedirect?: boolean; // NB! this prop is ONLY for storybook
}

export enum SmartSaverLocales {
  EN = 'en',
  ET = 'et',
  FR = 'fr',
  DE = 'de',
  ES = 'es',
}

const ALLOWED_LOCALES = [
  SmartSaverLocales.EN,
  SmartSaverLocales.DE,
  SmartSaverLocales.ES,
  SmartSaverLocales.FR,
  SmartSaverLocales.ET,
];

const FALLBACK_LOCALE = SmartSaverLocales.EN;

const LocaleContext = createContext<LocaleContextType | undefined>(undefined);

export const useLocale = () => {
  const context = useContext(LocaleContext);
  if (!context) {
    throw new Error('useLocale must be used within a LocaleProvider');
  }
  return context;
};

export const LocaleProvider = ({ children, ingoreRedirect }: LocaleProviderProps) => {
  const localeFromUrl = window.location.pathname.split('/').slice(1)[0];
  const [allowedLocales] = useState<string[]>(ALLOWED_LOCALES);
  const sessionStorageLocaleKey = `${LocalStorageKeys.LOCALE}_${AppId.SMARTSAVER}`;
  const [locale, setLocaleState] = useState<string>(
    () => sessionStorage.getItem(sessionStorageLocaleKey) || FALLBACK_LOCALE
  );

  const languageMeta = useMemo(
    () => ({
      [SmartSaverLocales.DE]: { flag: GermanFlag, name: 'Deutsch' },
      [SmartSaverLocales.EN]: { flag: EnglishFlag, name: 'English' },
      [SmartSaverLocales.ET]: { flag: EstonianFlag, name: 'Eesti' },
      [SmartSaverLocales.FR]: { flag: FrenchFlag, name: 'Français' },
      [SmartSaverLocales.ES]: { flag: SpanishFlag, name: 'Español' },
    }),
    []
  );

  const setLocale = useCallback(
    async (newLocale: string) => {
      const storedLocale = sessionStorage.getItem(sessionStorageLocaleKey);
      if (storedLocale === newLocale) {
        return;
      }

      setLocaleState(newLocale);
      sessionStorage.setItem(sessionStorageLocaleKey, newLocale);
    },
    [sessionStorageLocaleKey]
  );

  useEffect(() => {
    if (!allowedLocales || ingoreRedirect) {
      return;
    }
    const isAllowedLocale = allowedLocales?.indexOf(localeFromUrl) !== -1;
    // Store new locale in case of url was changed
    if (localeFromUrl && isAllowedLocale) {
      const newLocale = localeFromUrl.split('-')[0];
      setLocale(newLocale);
    } else if (localeFromUrl && !isAllowedLocale) {
      // Redirect back to valid url with allowed locale in case of wrong locale entered in url
      const location = window.location.pathname;
      const storedLocale = sessionStorage.getItem(sessionStorageLocaleKey);
      const newLocation = location.replace(localeFromUrl, `${storedLocale ?? FALLBACK_LOCALE}`);
      window.location.replace(newLocation);
    }
  }, [localeFromUrl, allowedLocales, sessionStorageLocaleKey, setLocale, ingoreRedirect]);

  return (
    <LocaleContext.Provider
      value={{
        allowedLocales,
        locale,
        setLocale,
        languageMeta,
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
};
