import { useIntl } from 'react-intl';

import Layout from '@common/components/layout/Layout';
import useInitialFlowActionPerform from '@common/hooks/useInitialFlowActionPerform';
import DrawdownConfirmForm from '@monefit-es/components/drawdown-confirm-form/DrawdownConfirmForm';
import m from '@monefit-es/components/drawdown-confirm-form/DrawdownConfirmForm.messages';

const ConfirmationPage = () => {
  const { formatMessage } = useIntl();
  useInitialFlowActionPerform({});

  return (
    <Layout.WithNavigation
      backButtonText={formatMessage(m.headerText)}
      maxWDesktop={545}
      loaderType="empty"
    >
      <DrawdownConfirmForm />
    </Layout.WithNavigation>
  );
};

export default ConfirmationPage;
