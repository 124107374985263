import { useMemo } from 'react';

import { LINKS } from '@smartsaver/constants/links';
import { SmartSaverLocales, useLocale } from '@smartsaver/hooks/useLocale';

const useLinks = () => {
  const { locale } = useLocale();

  const links = useMemo(() => LINKS[locale as SmartSaverLocales] ?? LINKS.en, [locale]);
  return {
    links,
  };
};

export default useLinks;
