import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import Button from '@common/components/button/Button';
import HidePasswordIcon from '@common/components/icons/HidePasswordIcon';
import ShowPasswordIcon from '@common/components/icons/ShowPasswordIcon';
import ImageCard from '@common/components/image-card/ImageCard';
import PasswordInput from '@common/components/password-form/PasswordInput';
import TextInput from '@common/components/text-input/TextInput';
import { yupResolver } from '@hookform/resolvers/yup';
import BaseCard from '@smartsaver/components/base-card/BaseCard';
import DataProtectedBlock from '@smartsaver/components/data-protected-block/DataProtectedBlock';
import { SmartSaverRoute } from '@smartsaver/constants/routes';
import { useLocale } from '@smartsaver/hooks/useLocale';
import img from '@src/assets/img/smartsaver/signup.webp';
import { UseMutationResult } from '@tanstack/react-query';

import m from './LoginForm.messages';
import { LoginFormSchema, getFormSchema } from './LoginForm.schema';

interface LoginFormProps {
  loginMutation: UseMutationResult<void, Error, LoginFormSchema>;
}

const LoginForm = ({ loginMutation }: LoginFormProps) => {
  const { formatMessage } = useIntl();
  const schema = useMemo(() => getFormSchema({ formatMessage }), [formatMessage]);
  const { locale } = useLocale();

  const ctx = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const {
    formState: { isSubmitting, isValid },
  } = ctx;

  return (
    <form
      autoComplete="off"
      className="flex size-full justify-center"
      onSubmit={ctx.handleSubmit(async (v: LoginFormSchema) => {
        try {
          loginMutation.mutate({ ...v });
        } catch (_) {}
      })}
    >
      <FormProvider {...ctx}>
        <ImageCard imagePosition="right" imageSrc={img} hideImageOnMobile>
          <BaseCard
            paddingClassName="p-0"
            widthClassName="w-full"
            gapClassName="gap-6"
            title={formatMessage(m.title)}
            subTitle={formatMessage(m.subTitle)}
          >
            <div className="flex flex-col gap-8 self-stretch">
              <div className="flex flex-col gap-3.5 self-stretch">
                <TextInput
                  styling="smartsaver"
                  aria-label="email"
                  name="email"
                  autoComplete="new-password"
                  label={formatMessage(m.email)}
                />
                <PasswordInput
                  styling="smartsaver"
                  aria-label="password"
                  name="password"
                  label={formatMessage(m.password)}
                  showHideIcons={{ hide: <ShowPasswordIcon />, show: <HidePasswordIcon /> }}
                />
                <div>
                  <Link to={`/${locale}/${SmartSaverRoute.FORGOT_PASSWORD}`}>
                    <span className="font-semibold underline">
                      {formatMessage(m.forgotPassword)}
                    </span>
                  </Link>
                </div>
              </div>
              <div className="flex flex-col gap-7 self-stretch">
                <Button
                  type="submit"
                  text={formatMessage(m.ctaButton)}
                  disabled={isSubmitting || !isValid || loginMutation.isPending}
                />
                <div className="w-full text-center font-semibold">
                  <FormattedMessage
                    {...m.haventRegistered}
                    values={{
                      signupLink: (
                        <Link
                          className="font-semibold underline"
                          to={`/${locale}/${SmartSaverRoute.SIGNUP}`}
                        >
                          {formatMessage(m.signup)}
                        </Link>
                      ),
                    }}
                  />
                </div>
                <DataProtectedBlock />
              </div>
            </div>
          </BaseCard>
        </ImageCard>
      </FormProvider>
    </form>
  );
};

export default LoginForm;
