import { FC } from 'react';

import { IconProps } from '@common/components/icons/types';

const GermanFlag: FC<IconProps> = ({ height = '16', width = '16' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3176_7800)">
        <path
          d="M0.498047 10.7835C1.62839 13.8299 4.56073 16.0009 8.00045 16.0009C11.4402 16.0009 14.3725 13.8299 15.5029 10.7835L8.00045 10.0879L0.498047 10.7835Z"
          fill="#FFDA44"
        />
        <path
          d="M8.00045 0C4.56073 0 1.62839 2.171 0.498047 5.21741L8.00045 5.91303L15.5029 5.21737C14.3725 2.171 11.4402 0 8.00045 0Z"
          fill="black"
        />
        <path
          d="M0.497594 5.2168C0.176031 6.08345 0 7.02083 0 7.99939C0 8.97795 0.176031 9.91533 0.497594 10.782H15.5024C15.824 9.91533 16 8.97795 16 7.99939C16 7.02083 15.824 6.08345 15.5024 5.2168H0.497594Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0_3176_7800">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GermanFlag;
