import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Tooltip } from 'react-tooltip';
import { useIntercom } from 'react-use-intercom';

import AccountCard from '@common/components/account-card/AccountCard';
import Button from '@common/components/button/Button';
import TextInput from '@common/components/text-input/TextInput';
import { useDispatch, useSelector } from '@common/hooks';
import { RootDispatch } from '@common/redux';
import { selectUserData } from '@common/redux/selectors/user';
import { fetchUser } from '@common/redux/thunks/user';

import m from './AccountSettings.messages';

const AccountSettings = () => {
  const dispatch = useDispatch<RootDispatch>();
  const userData = useSelector(selectUserData);
  const { showNewMessage } = useIntercom();
  const { formatMessage } = useIntl();
  const ctx = useForm({
    mode: 'onBlur',
    // resolver: yupResolver(schema),
  });

  // TODO: implementation and types. For now form is static and disabled
  const handleFormSubmit = useCallback(async (v: any) => {}, []);

  const fetchUserData = useCallback(async () => {
    if (userData) {
      return;
    }
    await dispatch(fetchUser()).unwrap();
  }, [dispatch, userData]);

  const handleOpenIntercom = useCallback(() => {
    showNewMessage();
  }, [showNewMessage]);

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <AccountCard>
      <form
        autoComplete="off"
        className="flex size-full flex-col gap-6"
        onSubmit={ctx.handleSubmit(async (v: any) => {
          try {
            await handleFormSubmit(v);
          } catch (_) {}
        })}
      >
        <FormProvider {...ctx}>
          <div
            className="flex w-full flex-col gap-3"
            data-tooltip-id="account-settings-tooltip"
            data-tooltip-content={formatMessage(m.tooltipText)}
          >
            <TextInput
              styling="account"
              name="email"
              disabled
              defaultValue={userData?.email}
              label={formatMessage(m.emailLabel)}
            />
            <TextInput
              styling="account"
              name="phone"
              disabled
              defaultValue={userData?.activePhone?.number}
              label={formatMessage(m.phoneLabel)}
            />
          </div>
          <Button onClick={handleOpenIntercom} text={formatMessage(m.btnText)} />
        </FormProvider>
        <Tooltip id="account-settings-tooltip" />
      </form>
    </AccountCard>
  );
};

export default AccountSettings;
