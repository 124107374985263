import { useIntl } from 'react-intl';

import Layout from '@common/components/layout/Layout';
import useInitialFlowActionPerform from '@common/hooks/useInitialFlowActionPerform';
import DrawdownCompleteCard from '@monefit-es/components/drawdown-complete-card/DrawdownCompleteCard';
import m from '@monefit-es/components/drawdown-complete-card/DrawdownCompleteCard.messages';

const DrawdownEndPage = () => {
  const { formatMessage } = useIntl();
  useInitialFlowActionPerform({});

  return (
    <Layout.WithNavigation
      hideProgressBar
      backButtonText={formatMessage(m.headerText)}
      maxWDesktop={545}
      loaderType="empty"
    >
      <DrawdownCompleteCard />
    </Layout.WithNavigation>
  );
};

export default DrawdownEndPage;
