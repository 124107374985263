import { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from '@common/hooks';
import { RootDispatch } from '@common/redux';
import {
  selectActiveAgreement,
  selectBalance,
  selectBalanceError,
  selectBalanceLoading,
  selectBalanceSummary,
  selectBalanceSummaryError,
  selectBalanceSummaryLoading,
  selectLimitError,
  selectLimitLoading,
  selectMainError,
  selectMainLoading,
  selectUserLimit,
} from '@common/redux/selectors/banking';
import {
  getActiveAgreement,
  getAgreementBalance,
  getAgreementBalanceSummary,
  getUserLimit,
} from '@common/redux/thunks/banking';
import { AgreementOriginator } from '@common/types/agreement';

interface UseLimitAndBalanceProps {
  disableAgreementFetch?: boolean;
  disableLimitFetch?: boolean;
  disableBalanceFetch?: boolean;
}

const useLimitAndBalance = ({
  disableAgreementFetch = false,
  disableBalanceFetch = false,
  disableLimitFetch = false,
}: UseLimitAndBalanceProps) => {
  const dispatch = useDispatch<RootDispatch>();
  const agreement = useSelector(selectActiveAgreement);
  const agreementError = useSelector(selectMainError);
  const agreementLoading = useSelector(selectMainLoading);

  const balanceLoading = useSelector(selectBalanceLoading);
  const balanceError = useSelector(selectBalanceError);
  const balanceData = useSelector(selectBalance);

  const balanceSummaryLoading = useSelector(selectBalanceSummaryLoading);
  const balanceSummaryError = useSelector(selectBalanceSummaryError);
  const balanceSummary = useSelector(selectBalanceSummary);

  const limitLoading = useSelector(selectLimitLoading);
  const limitData = useSelector(selectUserLimit);
  const limitError = useSelector(selectLimitError);

  const fetchAgreement = useCallback(async () => {
    if (agreement || agreementLoading) {
      return;
    }
    await dispatch(getActiveAgreement({ originaror: AgreementOriginator.MONEFIT_ES })).unwrap();
  }, [dispatch, agreement, agreementLoading]);

  const fetchLimit = useCallback(
    async (agreementId?: string) => {
      if (!agreementId || limitLoading) {
        return;
      }
      await dispatch(getUserLimit(agreementId)).unwrap();
    },
    [dispatch, limitLoading]
  );

  const fetchBalance = useCallback(
    async (agreementId?: string) => {
      if (!agreementId || balanceLoading) {
        return;
      }
      await dispatch(getAgreementBalance(agreementId)).unwrap();
    },
    [dispatch, balanceLoading]
  );

  const fetchBalanceSummary = useCallback(
    async (agreementId?: string, amount?: number, signal?: AbortSignal) => {
      if (!agreementId) {
        return;
      }
      await dispatch(getAgreementBalanceSummary({ agreementId, amount, signal })).unwrap();
    },
    [dispatch]
  );

  useEffect(() => {
    if (disableAgreementFetch) {
      return;
    }
    fetchAgreement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableAgreementFetch]);

  useEffect(() => {
    if (disableBalanceFetch) {
      return;
    }
    fetchBalance(agreement?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreement?.id, disableBalanceFetch]);

  useEffect(() => {
    if (disableLimitFetch) {
      return;
    }
    fetchLimit(agreement?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreement?.id, disableLimitFetch]);

  return {
    agreement,
    agreementLoading,
    agreementError,
    limitData,
    limitLoading,
    limitError,
    balanceData,
    balanceError,
    balanceLoading,
    balanceSummaryLoading,
    balanceSummaryError,
    balanceSummary,
    fetchAgreement,
    fetchBalance,
    fetchLimit,
    fetchBalanceSummary,
  };
};

export default useLimitAndBalance;
