import { FormatMessage } from '@common/types';
import * as yup from 'yup';

import m from './IndividualForm.messages';

interface GetFormSchemaProps {
  formatMessage: FormatMessage;
}

export const getFormSchema = ({ formatMessage }: GetFormSchemaProps) =>
  yup.object({
    firstName: yup.string().required(formatMessage(m.fieldIsMandatory)),
    lastName: yup.string().required(formatMessage(m.fieldIsMandatory)),
    birthdate: yup.string().required(formatMessage(m.fieldIsMandatory)),
    taxCountry: yup.string().required(formatMessage(m.fieldIsMandatory)),
    country: yup.string().required(formatMessage(m.fieldIsMandatory)),
    city: yup.string().required(formatMessage(m.fieldIsMandatory)),
    street: yup.string().required(formatMessage(m.fieldIsMandatory)),
    zipcode: yup.string().required(formatMessage(m.fieldIsMandatory)),
    promoCode: yup.string().optional().nullable(),
  });

export type GetIndividualFormSchema = typeof getFormSchema;

export type InvididualFormSchema = yup.InferType<ReturnType<GetIndividualFormSchema>>;
