import { Suspense } from 'react';
import { useIntl } from 'react-intl';

import IndividualForm from '@smartsaver/components/individual-form/IndividualForm';
import { InvididualFormSchema } from '@smartsaver/components/individual-form/IndividualForm.schema';
import MainLayout from '@smartsaver/components/main-layout/MainLayout';
import { registerInvididual } from '@smartsaver/services/register';
import { useMutation } from '@tanstack/react-query';
import { format } from 'date-fns';

const IndividualSignupPage = () => {
  const { formatMessage } = useIntl();
  const signupMutation = useMutation({
    mutationFn: (v: InvididualFormSchema) => {
      return registerInvididual({
        ...v,
        birthdate: format(new Date(v.birthdate), 'dd.MM.yyyy'),
      });
    },
    onSuccess: (data) => {
      // TODO: Handler
      // reportEvent({ eventName: OPTIMOVE_EVENTS.signup });
      console.log('SUCCESS', data);
    },
    onError: (error) => {
      // TODO: message
      console.log('ERR', error);
    },
  });

  return (
    <Suspense fallback={<div className="text-6xl">LOAD</div>}>
      <MainLayout
        loadingProp={signupMutation?.isPending}
        pageTitle={formatMessage({ defaultMessage: 'Sign up' })}
      >
        <IndividualForm signupMutation={signupMutation} />
      </MainLayout>
    </Suspense>
  );
};

export default IndividualSignupPage;
