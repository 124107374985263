import { FC, JSX, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Button from '@common/components/button/Button';
import ImageCard from '@common/components/image-card/ImageCard';
import { BaseError } from '@common/utils';
import MainLayout from '@monefit-es/components/layout/MainLayout';
import declinedImg from '@src/assets/img/monefit-es/declined.webp';

export interface ErrorBoundaryPageProps {
  error?: BaseError;
  hasSignupLayout?: boolean;
}

interface ErrorCardError {
  name: string;
  message: string;
  stack?: string;
}

export const ErrorCard = ({ error }: { error: ErrorCardError }) => {
  const intl = useIntl();

  const handleRefresh = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <ImageCard imageSrc={declinedImg} maxWClassName="max-w-[900px]">
      <div className="mb-20 sm:mb-0">{error.message}</div>
      <Button
        fullWidth
        text={intl.formatMessage({
          defaultMessage: 'Try again',
          description: 'ErrorBoundaryPage: Try again button text',
        })}
        onClick={handleRefresh}
      />
    </ImageCard>
  );
};

const ErrorBoundaryPage: FC<ErrorBoundaryPageProps> = ({
  error: propsError,
  hasSignupLayout = true,
}): JSX.Element => {
  const intl = useIntl();
  const [error, setError] = useState<ErrorCardError>();

  useEffect(() => {
    const fallbackName = intl.formatMessage({
      defaultMessage: 'Oops!',
      description: 'ErrorBoundaryPage: Error fallbackName (oops)',
    });
    const fallbackMessage = intl.formatMessage({
      defaultMessage:
        'An error occurred while processing the request. Please contact us if you believe this is a server error. Thank you.',
      description: 'ErrorBoundaryPage: Error fallbackMessage',
    });

    setError({
      name: fallbackName,
      message: fallbackMessage,
      stack: propsError?.stack,
    });
  }, [intl, propsError, setError]);

  if (!error) {
    return <></>;
  }

  return hasSignupLayout ? (
    <MainLayout hideProgressBar>
      <ErrorCard error={error} />
    </MainLayout>
  ) : (
    <ErrorCard error={error} />
  );
};

export default ErrorBoundaryPage;
