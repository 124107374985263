import { emailValidationRegex } from '@common/components/form/Form.helpers';
import { FormatMessage } from '@common/types';
import * as yup from 'yup';

import m from './LoginForm.messages';

interface GetFormSchemaProps {
  formatMessage: FormatMessage;
}

export const getFormSchema = ({ formatMessage }: GetFormSchemaProps) =>
  yup.object({
    email: yup
      .string()
      .required(formatMessage(m.blankEmail))
      .matches(emailValidationRegex, formatMessage(m.wrongEmail)),
    password: yup.string().required(formatMessage(m.passwordRequired)),
  });

export type GetLoginFormSchema = typeof getFormSchema;

export type LoginFormSchema = yup.InferType<ReturnType<GetLoginFormSchema>>;
