import useApp, { AppId } from './useApp';

// TODO: move to common enums
export enum Country {
  EE = 'ee',
  ES = 'es',
}

// TODO: move to generic config constants maybe?
export const ALLOWED_COUNTRIES = {
  [AppId.MONEFIT]: [Country.EE, Country.ES],
  [AppId.CREDITSTAR]: [Country.ES],
  [AppId.SMARTSAVER]: [],
};

const useCountry = () => {
  const { appId } = useApp();
  const localeFromUrl = window.location.pathname.split('/').slice(1)[0];
  const countryFromUrl = (localeFromUrl.split('-')?.[1] as Country) ?? null; // et-ee -> ee; en-es -> es ..etc
  const allowedCountries = appId && ALLOWED_COUNTRIES[appId] ? ALLOWED_COUNTRIES[appId] : [];
  const country =
    allowedCountries.indexOf(countryFromUrl) === -1 ? null : (countryFromUrl as Country);

  return { country };
};

export default useCountry;
