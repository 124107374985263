import { FC, ReactNode, useMemo } from 'react';

import clsx from 'clsx';

import { getClassNames } from './BaseCard.classes';

interface BaseCardProps {
  children?: ReactNode;
  title?: string;
  subTitle?: string;
  paddingClassName?: string;
  widthClassName?: string;
  gapClassName?: string;
}

const BaseCard: FC<BaseCardProps> = ({
  children,
  title,
  subTitle,
  paddingClassName,
  widthClassName,
  gapClassName,
}) => {
  const classNames = useMemo(() => getClassNames(), []);

  return (
    <div
      className={clsx(
        classNames.container,
        paddingClassName ?? 'px-[13px] py-7 sm:p-12',
        widthClassName ?? 'w-full sm:w-[610px]',
        gapClassName ?? 'gap-12'
      )}
    >
      {!!(title || subTitle) && (
        <div className={classNames.titleContainer}>
          {!!title && <div className={classNames.title}>{title}</div>}
          {!!subTitle && <div className={classNames.subTitle}>{subTitle}</div>}
        </div>
      )}
      <div className="w-full">{children}</div>
    </div>
  );
};

export default BaseCard;
