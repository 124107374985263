import { FC } from 'react';

import { IconProps } from './types';

const CloseIcon: FC<IconProps> = ({ width = '30', height = '30' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="20.4995"
        cy="20.5"
        r="20.0444"
        fill="#E2E0DF"
        stroke="#E2E0DF"
        strokeWidth="0.911111"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5704 13.2227L13.2261 14.567L19.1579 20.4988L13.2274 26.4293L14.5717 27.7736L20.5022 21.8431L26.4304 27.7713L27.7747 26.4269L21.8465 20.4988L27.776 14.5694L26.4317 13.225L20.5022 19.1545L14.5704 13.2227Z"
        fill="black"
      />
    </svg>
  );
};

export default CloseIcon;
