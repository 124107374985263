import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  headerSubText: {
    defaultMessage: "It's us, not you",
    description: "@monefit-es It's us, not you",
  },
  headerMainText: {
    defaultMessage: 'We can’t approve your loan right now',
    description: '@monefit-es  We can’t approve your loan right now',
  },
  headerDescription: {
    defaultMessage:
      'Here are some possible reasons for our decision. Either now or in the last 12 months...',
    description:
      '@monefit-es Here are some possible reasons for our decision. Either now or in the last 12 months...',
  },
  declineReason1: {
    defaultMessage: 'You might be on a debt management plan',
    description: '@monefit-es  You might be on a debt management plan',
  },
  declineReason2: {
    defaultMessage: 'You may have missed payments on another loan',
    description: '@monefit-es  You may have missed payments on another loan',
  },
  declineReason3: {
    defaultMessage: 'You could be behind on payments on two or more products',
    description: '@monefit-es You could be behind on payments on two or more products',
  },
  declineReason4: {
    defaultMessage: 'You might have missed a payment over €50',
    description: '@monefit-es You might have missed a payment over €50',
  },
  declineReason5: {
    defaultMessage: 'You may have a recent court judgment',
    description: '@monefit-es You may have a recent court judgment',
  },
  declineReason6: {
    defaultMessage: 'You might have had a debt relief order in the last 24 months',
    description: '@monefit-es You might have had a debt relief order in the last 24 months',
  },
  getInTouchText: {
    defaultMessage:
      'If this doesn’t seem right or you’d like to discuss it, please chat with us. ',
    description:
      '@monefit-es If this doesn’t seem right or you’d like to discuss it, please chat with us. ',
  },
  chatWithUs: {
    defaultMessage: 'Chat with us',
  },
  failedVerificationText: {
    defaultMessage:
      "Unfortunately, we can't proceed with your application until we have completed your Identity verification.",
    description:
      "@monefit-es Unfortunately, we can't proceed with your application until we have completed your Identity verification.",
  },
  referenceNo: {
    defaultMessage:
    "Reference no: ",
  description:
    "@monefit-es Reference no: ",
  }
});
