import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-tooltip/dist/react-tooltip.css';

import RootApp from './RootApp';
import reportWebVitals from './reportWebVitals';
import { initializeSentry } from './sentry';

import './index.css';

if (process.env.REACT_APP_SENTRY_DSN) {
  initializeSentry(process.env.REACT_APP_SENTRY_DSN);
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <RootApp />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// temporarily disabled
// if (process.env.NODE_ENV === 'development') {
//   const { serviceWorker } = require('./__mocks__/browser');
//   serviceWorker.start();
// }
