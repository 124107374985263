import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  cardTitle: {
    defaultMessage: 'Where do you currently live?',
    description: '@monefit-es Where do you currently live?',
  },
  cardSubTitle: {
    defaultMessage: 'Please begin by typing your street address and number to locate your address.',
    description:
      '@monefit-es Please begin by typing your street address and number to locate your address.',
  },
  manualCardSubTitle: {
    defaultMessage: 'Please enter your address.',
    description: '@monefit-es Please enter your address.',
  },
  addressLabel: {
    defaultMessage: 'Address',
  },
  helpButtonText: {
    defaultMessage: "I can't find my address",
    description: "@monefit-es  I can't find my address",
  },
  floorNumberLabel: {
    defaultMessage: 'Floor number',
  },
  doorLabel: {
    defaultMessage: 'Door',
  },
  goBackButton: {
    defaultMessage: 'Back',
  },
  continueButton: {
    defaultMessage: 'Continue',
  },
  houseNameOrNumberLabel: {
    defaultMessage: 'House name or number',
  },
  postalCodeLabel: {
    defaultMessage: 'Postal code',
  },
  townMunicipalityLabel: {
    defaultMessage: 'Town / Municipality',
  },
  regionLabel: {
    defaultMessage: 'Region',
  },
  // ERRORS & VALIDATON
  minThreeLettersError: {
    defaultMessage: 'Please enter at least 3 letters',
  },
  oneLetterOneNumberError: {
    defaultMessage: 'Please include at least one letter or number',
  },
  floorNumberRangeError: {
    defaultMessage: 'Enter a number between -5 and 120',
  },
  postalCodeError: {
    defaultMessage: 'Please enter a 5-digit postal code',
  },
  mandatoryFieldError: {
    defaultMessage: 'This field is mandatory',
  },
  missingAddressError: {
    defaultMessage: 'Please add your address to continue',
  },
});
