import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  footerText: {
    defaultMessage:
      'The verification is processed by Docusign Read more about your personal data processing in ',
    description:
      '@monefit-es The verification is processed by Docusign Read more about your personal data processing in ',
  },
  privacyPolicy: {
    defaultMessage: "Docusign's Privacy Policy.",
    description: "@monefit-es Docusign's Privacy Policy.",
  },
});
