import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { IOption } from '@common/components/select-input/SelectInput';
import {
  EducationType,
  EmploymentStatus,
  FamilyStatus,
  FieldOfActivity,
  FinancesFormValues,
  LoanPurpose,
  LoanType,
  PropertyType,
} from '@monefit-ee/components/finances-form/FinancesForm.types';
import * as yup from 'yup';

const useFinancesForm = () => {
  const { formatMessage } = useIntl();

  const loanPurposeOptions = useMemo(
    () =>
      [
        {
          label: formatMessage({
            defaultMessage: 'Bills',
            description: 'Finances: loanPurposeOptions.Bills',
          }),
          value: LoanPurpose.Bills,
        },
        {
          label: formatMessage({
            defaultMessage: 'Travelling',
            description: 'Finances: loanPurposeOptions.Travelling',
          }),
          value: LoanPurpose.Travelling,
        },
        {
          label: formatMessage({
            defaultMessage: 'Rent',
            description: 'Finances: loanPurposeOptions.RentOrLoan',
          }),
          value: LoanPurpose.Rent_or_loan,
        },
        {
          label: formatMessage({
            defaultMessage: 'Car',
            description: 'Finances: loanPurposeOptions.Car',
          }),
          value: LoanPurpose.Car,
        },
        {
          label: formatMessage({
            defaultMessage: 'Entertainment',
            description: 'Finances: loanPurposeOptions.Entertainment',
          }),
          value: LoanPurpose.Entertainment,
        },
        {
          label: formatMessage({
            defaultMessage: 'Groceries',
            description: 'Finances: loanPurposeOptions.Groceries',
          }),
          value: LoanPurpose.Groceries,
        },
        {
          label: formatMessage({
            defaultMessage: 'Repair',
            description: 'Finances: loanPurposeOptions.Repair',
          }),
          value: LoanPurpose.Repair,
        },
        {
          label: formatMessage({
            defaultMessage: 'Home appliance',
            description: 'Finances: loanPurposeOptions.Home_appliance',
          }),
          value: LoanPurpose.Home_appliance,
        },
        {
          label: formatMessage({
            defaultMessage: 'Furniture',
            description: 'Finances: loanPurposeOptions.Furniture',
          }),
          value: LoanPurpose.Furniture,
        },
        {
          label: formatMessage({
            defaultMessage: 'Education',
            description: 'Finances: loanPurposeOptions.Education',
          }),
          value: LoanPurpose.Education,
        },
        {
          label: formatMessage({
            defaultMessage: 'Other',
            description: 'Finances: loanPurposeOptions.Other',
          }),
          value: LoanPurpose.Other,
        },
      ] as IOption[],
    [formatMessage]
  );

  const educationOptions = useMemo(
    () =>
      [
        {
          label: formatMessage({
            defaultMessage: 'Basic Education',
            description: 'Finances: Basic education',
          }),
          value: EducationType.Basic_education,
        },
        {
          label: formatMessage({
            defaultMessage: 'Primary Education',
            description: 'Finances: Primary education',
          }),
          value: EducationType.Primary_education,
        },
        {
          label: formatMessage({
            defaultMessage: 'Secondary Education',
            description: 'Finances: Secondary education',
          }),
          value: EducationType.Secondary_education,
        },
        {
          label: formatMessage({
            defaultMessage: 'Vocational Education',
            description: 'Finances: Vocational education',
          }),
          value: EducationType.Vocational_education,
        },
        {
          label: formatMessage({
            defaultMessage: 'Applied Higher Education',
            description: 'Finances: Applied Higher Education',
          }),
          value: EducationType.Applied_higher_education,
        },
        {
          label: formatMessage({
            defaultMessage: 'Higher Education',
            description: 'Finances: Higher education',
          }),
          value: EducationType.Higher_education,
        },
      ] as IOption[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const employmentTypeOptions = useMemo(
    () =>
      [
        {
          label: formatMessage({
            defaultMessage: 'Salary',
            description: 'Finances: employmentStatusOptions.Salary',
          }),
          value: EmploymentStatus.Salary,
        },
        {
          label: formatMessage({
            defaultMessage: 'Self employed',
            description: 'Finances: employmentStatusOptions.Self_employed',
          }),
          value: EmploymentStatus.Self_employed,
        },
        {
          label: formatMessage({
            defaultMessage: 'Scholarship',
            description: 'Finances: employmentStatusOptions.Scholarship',
          }),
          value: EmploymentStatus.Scholarship,
        },
        {
          label: formatMessage({
            defaultMessage: 'Pension',
            description: 'Finances: employmentStatusOptions.Pension',
          }),
          value: EmploymentStatus.Pension,
        },
        {
          label: formatMessage({
            defaultMessage: 'Grants/Benefits',
            description: 'Finances: employmentStatusOptions.Grants/Benefits',
          }),
          value: EmploymentStatus.Grants_Benefits,
        },
        {
          label: formatMessage({
            defaultMessage: 'No income',
            description: 'Finances: employmentStatusOptions.No income',
          }),
          value: EmploymentStatus.No_income,
        },
      ] as IOption[],
    [formatMessage]
  );

  const familyStatusOptions = useMemo(
    () =>
      [
        {
          label: formatMessage({
            defaultMessage: 'Married',
            description: 'Finances: familyStatusOptions.Married',
          }),
          value: FamilyStatus.Married,
        },
        {
          label: formatMessage({
            defaultMessage: 'Single',
            description: 'Finances: familyStatusOptions.Single',
          }),
          value: FamilyStatus.Single,
        },
        {
          label: formatMessage({
            defaultMessage: 'Divorced',
            description: 'Finances: familyStatusOptions.Divorced',
          }),
          value: FamilyStatus.Divorced,
        },
        {
          label: formatMessage({
            defaultMessage: 'Domestic partnership',
            description: 'Finances: familyStatusOptions.Domestic_partnership',
          }),
          value: FamilyStatus.Domestic_partnership,
        },
      ] as IOption[],
    [formatMessage]
  );

  const employmentDurationOptions = useMemo(
    () =>
      [
        {
          label: formatMessage({
            defaultMessage: '1 year',
            description: 'Finances: employmentDurationOptions.1year',
          }),
          value: 1,
        },
        {
          label: formatMessage({
            defaultMessage: '2 years',
            description: 'Finances: employmentDurationOptions.2years',
          }),
          value: 2,
        },
        {
          label: formatMessage({
            defaultMessage: '3+ years',
            description: 'Finances: employmentDurationOptions.3+years',
          }),
          value: 3,
        },
      ] as IOption[],
    [formatMessage]
  );

  const dependetsOptions = useMemo(
    () => [
      { value: 0, label: '0' },
      { value: 1, label: '1' },
      { value: 2, label: '2' },
      { value: 3, label: '3+' },
    ],
    []
  );

  const loanTypeOptions = useMemo(
    () =>
      [
        {
          label: formatMessage({
            defaultMessage: 'Consumer credit',
            description: 'Finances: loanTypeOptions.Consumer_credit',
          }),
          value: LoanType.Consumer_credit,
        },
        {
          label: formatMessage({
            defaultMessage: 'House loan',
            description: 'Finances: loanTypeOptions.House_loan',
          }),
          value: LoanType.House_loan,
        },
        {
          label: formatMessage({
            defaultMessage: 'Leasing',
            description: 'Finances: loanTypeOptions.Leasing',
          }),
          value: LoanType.Leasing,
        },
        {
          label: formatMessage({
            defaultMessage: 'Credit card',
            description: 'Finances: loanTypeOptions.Credit_card',
          }),
          value: LoanType.Credit_card,
        },
        {
          label: formatMessage({
            defaultMessage: 'Other',
            description: 'Finances: loanTypeOptions.Other',
          }),
          value: LoanType.Other,
        },
      ] as IOption[],
    [formatMessage]
  );

  const pepExplanationText = useMemo(
    () =>
      formatMessage({
        description: 'Finances: PEP explanation',
        defaultMessage:
          "Politically exposed person is a natural person, who at the moment of filling in this application or has performed material functions of public authority (For example: head of state, head of government, minister and deputy or assistant minister, member of parliament, member of supreme court, of constitutional court, member of court of auditors or of the board of central bank, ambassador, chargé d'affaires and high-ranking officer in the armed forces, member of the administrative, management or supervisory body of state-owned enterprise) and his/her family members (spouse/partner equal to spouse, child and spouse or partner of child, parent) and his/her close associates",
      }),
    [formatMessage]
  );

  const schema: yup.ObjectSchema<FinancesFormValues> = useMemo(
    () =>
      yup.object().shape({
        loanPurpose: yup.string().required(
          formatMessage({
            defaultMessage: 'Please fill in this information to continue.',
            description: 'Finances: "Please fill in this information to continue." (loanType)',
          })
        ),
        education: yup.string().required(
          formatMessage({
            defaultMessage: 'Please fill in this information to continue.',
            description: 'Finances: "Please fill in this information to continue." (education)',
          })
        ),
        maritalStatus: yup.string().required(
          formatMessage({
            defaultMessage: 'Please fill in this information to continue.',
            description: 'Finances: "Please fill in this information to continue." (maritalStatus)',
          })
        ),
        monthlyExpenses: yup
          .number()
          .typeError(
            formatMessage({
              defaultMessage: 'Please enter whole numbers only, without decimals.',
              description:
                'Finances: "Please enter whole numbers only, without decimals." (monthlyExpenses)',
            })
          )
          .min(0)
          .required(
            formatMessage({
              defaultMessage: 'Please fill in this information to continue.',
              description:
                'Finances: "Please fill in this information to continue." (monthlyExpenses)',
            })
          ),
        monthlyNetIncome: yup
          .number()
          .typeError(
            formatMessage({
              defaultMessage: 'Please enter whole numbers only, without decimals.',
              description:
                'Finances: "Please enter whole numbers only, without decimals." (monthlyNetIncome)',
            })
          )
          .min(0)
          .required(
            formatMessage({
              defaultMessage: 'Please fill in this information to continue.',
              description:
                'Finances: "Please fill in this information to continue." (monthlyNetIncome)',
            })
          ),
        employmentDuration: yup.number().when('employmentType', {
          is: (employmentType: EmploymentStatus) =>
            [EmploymentStatus.Salary, EmploymentStatus.Self_employed].includes(employmentType),
          then: () =>
            yup.number().required(
              formatMessage({
                defaultMessage: 'Please fill in this information to continue.',
                description:
                  'Finances: "Please fill in this information to continue." (employmentDuration)',
              })
            ),
          otherwise: () => yup.number(),
        }),
        employmentType: yup.string().required(
          formatMessage({
            defaultMessage: 'Please fill in this information to continue.',
            description:
              'Finances: "Please fill in this information to continue." (employmentType)',
          })
        ),
        hasCreditHistory: yup.bool().required(
          formatMessage({
            defaultMessage: 'Please fill in this information to continue.',
            description:
              'Finances: "Please fill in this information to continue." (hasCreditHistory)',
          })
        ),

        hasActiveCredit: yup.bool().required(
          formatMessage({
            defaultMessage: 'Please fill in this information to continue.',
            description:
              'Finances: "Please fill in this information to continue." (hasActiveCredit)',
          })
        ),
        financialDependents: yup.number().required(
          formatMessage({
            defaultMessage: 'Please fill in this information to continue.',
            description:
              'Finances: "Please fill in this information to continue." (financialDependents)',
          })
        ),
        isPep: yup.bool().required(
          formatMessage({
            defaultMessage: 'Please fill in this information to continue.',
            description: 'Finances: "Please fill in this information to continue." (isPep)',
          })
        ),
        propertyOwner: yup.bool().required(
          formatMessage({
            defaultMessage: 'Please fill in this information to continue.',
            description: 'Finances: "Please fill in this information to continue." (isPep)',
          })
        ),
        propertyTypes: yup.array().when('propertyOwner', {
          is: true,
          then: () => yup.array().of(yup.string()).min(1).required(),
          otherwise: () => yup.array().min(0),
        }),
        employerName: yup.string().when('employmentType', {
          is: (employmentType: EmploymentStatus) =>
            [EmploymentStatus.Salary, EmploymentStatus.Self_employed].includes(employmentType),
          then: () =>
            yup.string().required(
              formatMessage({
                defaultMessage: 'Please fill in this information to continue.',
                description:
                  'Finances: "Please fill in this information to continue." (employerName)',
              })
            ),
          otherwise: () => yup.string(),
        }),
        jobTitle: yup.string().when('employmentType', {
          is: (employmentType: EmploymentStatus) =>
            [EmploymentStatus.Salary].includes(employmentType),
          then: () =>
            yup.string().required(
              formatMessage({
                defaultMessage: 'Please fill in this information to continue.',
                description: 'Finances: "Please fill in this information to continue." (jobTitle)',
              })
            ),
          otherwise: () => yup.string(),
        }),
        fieldOfActivity: yup.string().when('employmentType', {
          is: (employmentType: EmploymentStatus) =>
            [EmploymentStatus.Salary, EmploymentStatus.Self_employed].includes(employmentType),
          then: () =>
            yup.string().required(
              formatMessage({
                defaultMessage: 'Please fill in this information to continue.',
                description:
                  'Finances: "Please fill in this information to continue." (fieldOfActivity)',
              })
            ),
          otherwise: () => yup.string(),
        }),
        activeCredits: yup.array().when('hasActiveCredit', {
          is: true,
          then: () =>
            yup
              .array()
              .of(
                yup.object({
                  activeCreditType: yup.string().required(
                    formatMessage({
                      defaultMessage: 'Please fill in this information to continue.',
                      description:
                        'Finances: "Please fill in this information to continue." (activeCredits)',
                    })
                  ),
                  activeCreditAmount: yup
                    .string()
                    .typeError(
                      formatMessage({
                        defaultMessage: 'Please enter whole numbers only, without decimals.',
                        description:
                          'Finances: "Please enter whole numbers only, without decimals." (activeCreditAmount)',
                      })
                    )
                    .required(
                      formatMessage({
                        defaultMessage: 'Please fill in this information to continue.',
                        description:
                          'Finances: "Please fill in this information to continue." (activeCreditAmount)',
                      })
                    ),
                })
              )
              .min(1)
              .required(),
          otherwise: () => yup.array().min(0),
        }),
      }),
    [formatMessage]
  );

  const propertyOptions = useMemo(() => {
    return [
      {
        label: formatMessage({
          defaultMessage: 'Real estate',
          description: 'Finances: property options (Real estate)',
        }),
        value: PropertyType.REAL_ESTATE,
      },
      {
        label: formatMessage({
          defaultMessage: 'Vehicle',
          description: 'Finances: property options (Vehicle)',
        }),
        value: PropertyType.VEHICLE,
      },
      {
        label: formatMessage({
          defaultMessage: 'Stock',
          description: 'Finances: property options (Stock)',
        }),
        value: PropertyType.STOCK,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fieldOfActivityOptions = useMemo(
    () =>
      [
        {
          label: formatMessage({
            defaultMessage: 'Agriculture, Forestry, Fishing',
            description: 'Finances: fieldOfActivityOpts.Agriculture, Forestry, Fishing',
          }),
          value: FieldOfActivity.Agriculture_forestry_fishing,
        },
        {
          label: formatMessage({
            defaultMessage: 'Construction',
            description: 'Finances: fieldOfActivityOpts.Construction',
          }),
          value: FieldOfActivity.Construction,
        },
        {
          label: formatMessage({
            defaultMessage: 'Wholesale and Retail Trade',
            description: 'Finances: fieldOfActivityOpts.Wholesale and Retail Trade',
          }),
          value: FieldOfActivity.Wholesale_and_retail_trade,
        },
        {
          label: formatMessage({
            defaultMessage: 'Transport and Logistics',
            description: 'Finances: fieldOfActivityOpts.Transport and Logistics',
          }),
          value: FieldOfActivity.Transport_and_logistics,
        },
        {
          label: formatMessage({
            defaultMessage: 'Tourism, Accommodation, and Catering',
            description: 'Finances: fieldOfActivityOpts.Tourism, Accommodation, and Catering',
          }),
          value: FieldOfActivity.Tourism_accommodation_and_catering,
        },
        {
          label: formatMessage({
            defaultMessage: 'Information and Communication',
            description: 'Finances: fieldOfActivityOpts.Information and Communication',
          }),
          value: FieldOfActivity.Information_and_communication,
        },
        {
          label: formatMessage({
            defaultMessage: 'Finance and Insurance',
            description: 'Finances: fieldOfActivityOpts.Finance and Insurance',
          }),
          value: FieldOfActivity.Finance_and_insurance,
        },
        {
          label: formatMessage({
            defaultMessage: 'Public Administration and National Defense',
            description: 'Finances: fieldOfActivityOpts.Public Administration and National Defense',
          }),
          value: FieldOfActivity.Public_administration_and_national_defense,
        },
        {
          label: formatMessage({
            defaultMessage: 'Education',
            description: 'Finances: fieldOfActivityOpts.Education',
          }),
          value: FieldOfActivity.Education,
        },
        {
          label: formatMessage({
            defaultMessage: 'Health Care and Social Welfare',
            description: 'Finances: fieldOfActivityOpts.Health Care and Social Welfare',
          }),
          value: FieldOfActivity.Health_care_and_social_welfare,
        },
        {
          label: formatMessage({
            defaultMessage: 'Culture, Arts, and Entertainment',
            description: 'Finances: fieldOfActivityOpts.Culture, Arts, and Entertainment',
          }),
          value: FieldOfActivity.Culture_arts_and_entertainment,
        },
        {
          label: formatMessage({
            defaultMessage: 'Other Service Activities',
            description: 'Finances: fieldOfActivityOpts.Other Service Activities',
          }),
          value: FieldOfActivity.Other_service_activities,
        },
      ] as IOption[],
    [formatMessage]
  );

  return {
    loanPurposeOptions,
    educationOptions,
    employmentTypeOptions,
    familyStatusOptions,
    employmentDurationOptions,
    dependetsOptions,
    loanTypeOptions,
    schema,
    pepExplanationText,
    propertyOptions,
    fieldOfActivityOptions,
  };
};

export default useFinancesForm;
