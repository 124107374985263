import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  headingText: {
    defaultMessage: 'Something not right.',
    description: '@monefit-es Something not right.',
  },
  descriptionText: {
    defaultMessage: 'We’re not able to process your payment at the moment.',
  },
  buttonText: {
    defaultMessage: 'Chat with us',
  },
});
