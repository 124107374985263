import AddressForm from '@monefit-es/components/address-form/AddressForm';
import MainLayout from '@monefit-es/components/layout/MainLayout';

const AddressPage = () => {
  return (
    <MainLayout type="onboarding">
      <AddressForm />
    </MainLayout>
  );
};

export default AddressPage;
