import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import Button from '@common/components/button/Button';
import TextInput from '@common/components/text-input/TextInput';
import { yupResolver } from '@hookform/resolvers/yup';
import BaseCard from '@smartsaver/components/base-card/BaseCard';
import DataProtectedBlock from '@smartsaver/components/data-protected-block/DataProtectedBlock';
import { UseMutationResult } from '@tanstack/react-query';

import m from './ForgotPasswordForm.messages';
import { ForgotPasswordFormSchema, getFormSchema } from './ForgotPasswordForm.schema';

interface ForgotPassowordFormProps {
  requestPasswordChangeMutation: UseMutationResult<void, Error, ForgotPasswordFormSchema>;
}

const ForgotPassowordForm = ({ requestPasswordChangeMutation }: ForgotPassowordFormProps) => {
  const { formatMessage } = useIntl();
  const schema = useMemo(() => getFormSchema({ formatMessage }), [formatMessage]);
  const ctx = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const {
    formState: { isSubmitting, isValid },
  } = ctx;
  return (
    <form
      autoComplete="off"
      className="flex size-full justify-center"
      onSubmit={ctx.handleSubmit(async (v: ForgotPasswordFormSchema) => {
        try {
          requestPasswordChangeMutation.mutate(v);
        } catch (_) {}
      })}
    >
      <BaseCard
        widthClassName="max-w-[500px]"
        title={formatMessage(m.title)}
        subTitle={formatMessage(m.subTitle)}
      >
        <FormProvider {...ctx}>
          <div className="flex flex-col gap-3.5 self-stretch">
            <TextInput
              name="identifier"
              styling="smartsaver"
              aria-label="email"
              label={formatMessage(m.emailLabel)}
            />
            <Button
              extraClassNames="mt-8"
              type="submit"
              text={formatMessage(m.ctaButton)}
              disabled={!isValid || isSubmitting || requestPasswordChangeMutation.isPending}
            />
            <div className="my-6 sm:my-2">
              <DataProtectedBlock />
            </div>
          </div>
        </FormProvider>
      </BaseCard>
    </form>
  );
};

export default ForgotPassowordForm;
