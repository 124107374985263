import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  tilte: {
    defaultMessage: 'Upload required business documents',
  },
  backButton: {
    defaultMessage: 'Back',
  },
  documentRequirement1: {
    defaultMessage:
      'Evidence to show the authority of the representative to act on behalf of the company.',
  },
  documentRequirement2: {
    defaultMessage: 'A copy / photo of Certificate of Incorporation.',
  },
  documentRequirement3: {
    defaultMessage:
      'A copy of commercial register within the last 3 months confirming that the company remains registered on that registry’s register of Companies and / or is in good standing, registered office address, country of tax residence.',
  },
  documentRequirement4: {
    defaultMessage:
      'A copy of both sides of valid local identity card or copy of passport from all Ultimate Beneficial Owners.',
  },
  addDocumentsButton: {
    defaultMessage: 'Add documents',
  },
  submitButton: {
    defaultMessage: 'Submit and continue',
  },
  missingDocumentsMessage: {
    defaultMessage: 'Please upload at least {amount} documents to proceed.',
  },
  maxDocumentsAmountMessage: {
    defaultMessage: 'Please select no more than {amount} files.',
  },
  documentsMaxSizeMessage: {
    defaultMessage: ' Each file should be less than {size} MB.',
  },
});
