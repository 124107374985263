import { FC, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Select, { MenuProps, OptionProps, SingleValue, components } from 'react-select';

import IconDown from '@common/components/icons/IconDown';
import { IconProps } from '@common/components/icons/types';
import { useLocale } from '@smartsaver/hooks/useLocale';
import { SELECT_ANIMATION } from '@src/assets/animations/motion.animations';
import clsx from 'clsx';
import { motion } from 'framer-motion';

interface LagnuageSelectOption {
  value: string;
  FlagIcon: FC<IconProps>;
  label: string;
}

const LanguageSelect = () => {
  const { locale, languageMeta, allowedLocales, setLocale } = useLocale();
  const navigate = useNavigate();

  const options: LagnuageSelectOption[] | undefined = useMemo(
    () =>
      allowedLocales?.map((l) => ({
        value: l,
        FlagIcon: languageMeta[l].flag,
        label: languageMeta[l].name,
      })),
    [allowedLocales, languageMeta]
  );

  const optionsToDisplay = useMemo(
    () => options?.filter((o) => o.value !== locale),
    [options, locale]
  );
  const location = useLocation();

  const handleSelect = useCallback(
    (selectedOption: SingleValue<LagnuageSelectOption> | null) => {
      const { pathname: currentLocation } = location;
      if (locale === selectedOption?.value) {
        return;
      }
      if (locale && selectedOption?.value) {
        const newLocation = currentLocation.replace(locale, selectedOption?.value);
        setLocale(selectedOption.value);
        navigate(newLocation, { replace: true });
      }
    },
    [location, navigate, setLocale, locale]
  );

  return (
    <Select
      isSearchable={false}
      menuPlacement="auto"
      menuPosition="fixed"
      menuPortalTarget={document.body}
      options={optionsToDisplay}
      value={options?.find((x) => x.value === locale)}
      onChange={(v) => handleSelect(v as SingleValue<LagnuageSelectOption>)}
      components={{
        IndicatorSeparator: () => null,
        SingleValue: ({ data: { FlagIcon, label } }) => (
          <div className="flex items-center gap-2 font-semibold">
            <div>
              <FlagIcon />
            </div>
            {label}
          </div>
        ),
        Option,
        Menu,
        DropdownIndicator: (props) => (
          <IconDown
            className={clsx('ml-1 mt-0.5 transition-transform duration-300', {
              'rotate-180': props.selectProps.menuIsOpen,
              'rotate-0': !props.selectProps.menuIsOpen,
            })}
            height="14"
            width="14"
            strokeWidth={'2.5'}
          />
        ),
      }}
      styles={{
        control: (base, props): any => ({
          ...base,
          borderColor: '#d1d5db',
          boxShadow: 'none',
          backgroundColor: 'transparent',
          border: 'none',
          cursor: 'pointer',
          fontSize: '14px',
          fontFamily: 'Avenir Next Demibold',
        }),

        menu: (base): any => ({
          ...base,
          borderRadius: '0.75rem',
          width: '120px',
          fontSize: '14px',
          right: -12,
          border: 'none',
          boxShadow: 'none',
          marginTop: 0,
        }),
        valueContainer: (base): any => ({
          ...base,
          display: 'flex',
          padding: 0,
          flexWrap: 'no-wrap',
        }),
      }}
    />
  );
};

export default LanguageSelect;

const Option = ({ data: { FlagIcon, label }, innerRef, innerProps }: OptionProps<any>) => {
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={clsx('flex cursor-pointer items-center gap-2 px-4 py-2 text-[14px]')}
    >
      <div>
        <FlagIcon />
      </div>
      <span>{label}</span>
    </div>
  );
};

const Menu = (props: MenuProps<any>) => {
  return (
    <motion.div {...SELECT_ANIMATION} style={{ zIndex: 9999 }}>
      <components.Menu {...props} />
    </motion.div>
  );
};
