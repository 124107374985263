import { useState } from 'react';

import InputLabel from '@common/components/input-label/InputLabel';
import { sharedLabelStyles } from '@common/components/input-label/InputLabel.styles';
import { WidgetProps } from '@rjsf/utils';
import clsx from 'clsx';

const TextWidget = (props: WidgetProps) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className="relative">
      <input
        className={clsx(
          { [sharedLabelStyles.inputTopPaddingClassname]: !!props.label },
          'h-16 w-full rounded-xl border border-gray-300 px-7 font-semibold  placeholder:font-normal placeholder:text-gray-400 focus:outline-none'
        )}
        disabled={props.disabled}
        {...props}
        onChange={(e) => props.onChange(e.target.value)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setIsFocused(false);
          props.onBlur(props.id, props.value);
        }}
        placeholder={props.label ? '' : (props.placeholder ?? '')}
      />
      {!!props.label && <InputLabel text={props.label} shouldMinimize={isFocused || props.value} />}
    </div>
  );
};

export default TextWidget;
