import { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import NoInvoiceIcon from '@common/components/icons/NoInvoice';
import Layout from '@common/components/layout/Layout';
import BlackDotsLoader from '@common/components/loader/BlackDotsLoader';
import { LocalStorageKeys } from '@common/constants';
import { useDispatch, useLocale, useSelector } from '@common/hooks';
import { RootDispatch } from '@common/redux';
import {
  selectBalance,
  selectInvoices,
  selectInvoicesLoading,
} from '@common/redux/selectors/banking';
import { getInvoices } from '@common/redux/thunks/banking';
import { Invoice } from '@common/types/banking';
import InvoiceItem from '@monefit-es/components/invoice-page/InvoiceItem';
import useLimitAndBalance from '@monefit-es/hooks/useLimitAndBalance';

import m from './InvoicePage.Messages';

const InvoicePage = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { localeWithCountry } = useLocale();
  const dispatch = useDispatch<RootDispatch>();
  const invoices = useSelector(selectInvoices);
  const invoicesLoading = useSelector(selectInvoicesLoading);
  const { balanceData } = useLimitAndBalance({
    disableLimitFetch: true,
  });
  const fetchInvoices = useCallback(
    async (agreementId: string | null) => {
      if (!agreementId) {
        return;
      }
      await dispatch(getInvoices({ agreementId })).unwrap();
    },
    [dispatch]
  );

  useEffect(() => {
    const agreementId = sessionStorage.getItem(LocalStorageKeys.AGREEMENT_ID);
    fetchInvoices(agreementId);
  }, []);

  const handleBackClick = useCallback(() => {
    navigate(`/${localeWithCountry}/user`);
  }, [localeWithCountry, navigate]);

  const invoicesExist = useMemo(() => invoices && invoices.length > 0, [invoices]);
  const isOverdueHighlight = !!(
    balanceData &&
    balanceData.componentDaysPastDues &&
    balanceData.componentDaysPastDues.length > 0
  );

  return (
    <Layout.WithNavigation
      childrenWrapperAlign="center"
      maxWDesktop={700}
      maxWMobile={550}
      hideProgressBar
      onBackButtonClick={handleBackClick}
      backButtonText={formatMessage(m.previousInvoices)}
    >
      {invoicesLoading && <BlackDotsLoader style={{ height: 40 }} />}
      <div className="box-border flex w-full flex-col items-center justify-center rounded-2xl bg-white p-8">
        {!invoicesLoading &&
          (invoicesExist ? (
            <div className="w-full">
              <ul className="flex flex-col gap-5">
                {invoices?.map((invoice: Invoice, index: number) => (
                  <InvoiceItem
                    key={invoice.id}
                    invoice={invoice}
                    isOverdue={isOverdueHighlight && index === invoices.length - 1}
                  />
                ))}
              </ul>
            </div>
          ) : (
            <div className="flex flex-col items-center">
              <NoInvoiceIcon />
              <div className="mt-4 text-2xl font-semibold">{formatMessage(m.noInvoicesYet)}</div>
              <div className="self-stretch p-4 text-center">
                {formatMessage(m.noInvoicesMessage)}
              </div>
            </div>
          ))}
      </div>
    </Layout.WithNavigation>
  );
};

export default InvoicePage;
