import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Button from '@common/components/button/Button';
import { FooterProps } from '@common/components/footer/Footer';
import FooterLogo from '@common/components/icons/FooterLogo';
import { useLocale } from '@common/hooks';
import useTailwindBreakpoints from '@common/hooks/useTailwindBreakpoints';
import { handleLogout } from '@common/utils';

const Footer: FC<FooterProps> = ({ type }) => {
  const { formatMessage } = useIntl();
  const { localeWithCountry } = useLocale();
  const { isMobile } = useTailwindBreakpoints();
  return (
    <div className="flex w-full flex-col items-center gap-5">
      {type === 'onboarding' && isMobile && (
        <Button
          extraClassNames="font-medium"
          color="new-gray"
          onClick={() => handleLogout(localeWithCountry, true)}
          text={formatMessage({
            defaultMessage: 'Log out',
            description: 'Monefit-ES: logout',
          })}
        />
      )}
      <div className="flex flex-col items-center p-2 opacity-50 sm:h-9 sm:flex-row sm:justify-center">
        <div className="flex flex-col items-center gap-2.5 sm:flex-row sm:gap-5">
          <FooterLogo />
          <span className="text-center font-normal text-black text-opacity-50 sm:text-base">
            <FormattedMessage defaultMessage="© Creditstar Group. All Rights Reserved." />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
