import { FC, useMemo, useRef, useState } from 'react';
import ReactDatePicker, { DatePickerProps as ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useController, useFormContext } from 'react-hook-form';

import CalendarIcon from '@common/components/icons/CalendarIcon';
import InputError from '@common/components/input-error/InputError';
import { inputErrorStyles } from '@common/components/input-error/InputError.styles';
import InputLabel from '@common/components/input-label/InputLabel';
import { sharedLabelStyles } from '@common/components/input-label/InputLabel.styles';
import styles from '@common/components/text-input/TextInput.styles';
import clsx from 'clsx';
import { de, enGB, es, et, fr, ru } from 'date-fns/locale';

import './datepicker.styles.css';

type DatePickerProps = ReactDatePickerProps & {
  label?: string;
  name: string;
  styling?: 'regular' | 'account' | 'smartsaver';
  locale?: string;
};

const DatePicker: FC<DatePickerProps> = ({ label, styling, locale, name, ...props }) => {
  const {
    fieldState: { error },
  } = useController({
    name,
    rules: {},
  });

  const {
    register,
    formState: { isSubmitting },
    getValues,
    setValue,
    trigger,
  } = useFormContext();

  // React-datepicker uses date-fns locales
  const mappedDateFnsLocale = useMemo(() => {
    switch (locale) {
      case 'et':
        return et;
      case 'fr':
        return fr;
      case 'es':
        return es;
      case 'de':
        return de;
      case 'ru':
        return ru;
      case 'en':
        return enGB;
      default:
        return enGB;
    }
  }, [locale]);

  const { onChange, ...registration } = register(name);
  const [canShowError, setCanShowError] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const datePickerRef = useRef(null);
  const isErrored = error?.message && canShowError && !getValues(name);

  return (
    <div className="relative w-full">
      <div className="relative">
        <ReactDatePicker
          {...props}
          {...registration}
          className={clsx(styles.base, 'w-full text-black', styles[styling ?? 'regular'], {
            [sharedLabelStyles.inputTopPaddingClassname]: !!label,
            [inputErrorStyles.border]: isErrored,
          })}
          disabled={isSubmitting}
          onChange={(d: any) => setValue(name, d)}
          selected={getValues(name)}
          onFocus={() => setIsFocused(true)}
          ref={datePickerRef}
          customInput={<input type="text" data-testid={name} />}
          locale={mappedDateFnsLocale}
          onBlur={() => {
            setIsFocused(false);
            setCanShowError(true);
            trigger(name);
          }}
          popperClassName="datepicker-popper"
        />
        <button
          type="button"
          onClick={() => (datePickerRef.current as any)?.setFocus()}
          className="absolute right-2 top-[30%] cursor-default sm:right-8"
        >
          <CalendarIcon />
        </button>
      </div>

      {!!label && (
        <InputLabel
          name={name}
          styling={styling}
          text={label}
          shouldMinimize={isFocused || props.value || getValues(name)}
        />
      )}

      {isErrored && <InputError message={error?.message?.toString() ?? ''} />}
    </div>
  );
};

export default DatePicker;
