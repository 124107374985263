import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  aboutSmartSaver: {
    defaultMessage: 'About SmartSaver',
  },
  faq: {
    defaultMessage: 'FAQ',
  },
  termsOfUse: {
    defaultMessage: 'Terms of Use',
  },
  privacyPolicy: {
    defaultMessage: 'Privacy Policy',
  },
  cookiesPolicy: {
    defaultMessage: 'Cookies policy',
  },
  referAFriend: {
    defaultMessage: 'Refer a friend',
  },
  aboutUs: {
    defaultMessage: 'About us',
  },
  careers: {
    defaultMessage: 'Careers',
  },
  language: {
    defaultMessage: 'Language',
  },
});
