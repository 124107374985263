import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import Button from '@common/components/button/Button';
import ArrowRightIcon from '@common/components/icons/ArrowRightIcon';
import { LINKS } from '@common/constants';
import { useLocale } from '@common/hooks';
import useTailwindBreakpoints from '@common/hooks/useTailwindBreakpoints';
import clsx from 'clsx';

import m from './AccountNavBar.messages';
import { NavBarItem } from './AccoutNavBar.types';

interface AccountNavBarItem {
  title: string;
  type: NavBarItem;
}

interface AccountNavbarProps {
  selectedItem: NavBarItem | null;
  selectNavbarItem: (item: NavBarItem | null) => void;
}

const AccountNavBar = ({ selectedItem, selectNavbarItem }: AccountNavbarProps) => {
  const { locale } = useLocale();
  const { isMobile } = useTailwindBreakpoints();

  const { formatMessage } = useIntl();
  const navbarItems: AccountNavBarItem[] = useMemo(
    () => [
      { title: formatMessage(m.personalDetails), type: NavBarItem.PERSONAL_DETAILS },
      { title: formatMessage(m.accountSettings), type: NavBarItem.ACCOUNT_SETTINGS },
      { title: formatMessage(m.aboutUs), type: NavBarItem.ABOUT_US },
      { title: formatMessage(m.getHelp), type: NavBarItem.GET_HELP },
      { title: formatMessage(m.logout), type: NavBarItem.LOG_OUT },
    ],
    [formatMessage]
  );

  const getLink = useCallback(
    (item: NavBarItem) => {
      switch (item) {
        case NavBarItem.ABOUT_US:
        case NavBarItem.GET_HELP:
          // TODO: handle other locales/links if needed
          return locale === 'en' ? LINKS.ABOUT : LINKS.ES_ABOUT;
        default:
          return LINKS.ABOUT;
      }
    },
    [locale]
  );

  const handleNavbarSelect = useCallback(
    (item: NavBarItem) => {
      const links = [
        { item: NavBarItem.GET_HELP, link: getLink(NavBarItem.GET_HELP) },
        { item: NavBarItem.ABOUT_US, link: getLink(NavBarItem.ABOUT_US) },
      ];

      const linkItem = links.find((x) => x.item === item);

      if (!!linkItem) {
        window.open(linkItem.link, '_blank');
        return;
      }

      selectNavbarItem(item);
    },
    [selectNavbarItem, getLink]
  );

  return (
    <nav
      className={clsx('flex w-full flex-col gap-6 rounded-2xl bg-white p-7', {
        hidden: isMobile && selectedItem,
      })}
    >
      {navbarItems.map(({ title, type }) => (
        <Button
          key={type}
          buttonNode={
            isMobile ? (
              <div className="flex w-full items-center justify-between px-1 py-4 font-semibold">
                <span>{title}</span>
                <ArrowRightIcon />
              </div>
            ) : (
              <div
                className={clsx('rounded-full px-7 py-4', {
                  'bg-[#EEE6DB]': selectedItem === type,
                })}
              >
                {title}
              </div>
            )
          }
          onClick={() => handleNavbarSelect(type)}
        />
      ))}
    </nav>
  );
};

export default AccountNavBar;
