import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import FooterLogo from '@common/components/icons/FooterLogo';
// eslint-disable-next-line boundaries/no-private
import useLinks from '@monefit-ee/hooks/useLinks';

const linkClasses = ['text-black text-base font-medium underline leading-tight tracking-tight'];

export interface FooterProps {
  type?: 'common' | 'dashboard' | 'onboarding';
}

const Footer: FC<FooterProps> = () => {
  const { contactUsLink, faqLink, creditLineLink } = useLinks();

  return (
    <div className="flex flex-col items-center p-2 opacity-50 sm:h-9 sm:flex-row sm:justify-between">
      <div className="flex flex-col items-center gap-2.5 sm:flex-row sm:gap-5">
        <FooterLogo />
        <span className="text-center font-normal text-black text-opacity-50 sm:text-base">
          <FormattedMessage defaultMessage="© Creditstar Group. All Rights Reserved." />
        </span>
        <a href={contactUsLink} className={`${linkClasses.join(' ')} sm:hidden`}>
          <FormattedMessage defaultMessage="Contact us" description="Footer: Contact us" />
        </a>
      </div>
      <div className="hidden flex-col items-center sm:flex sm:flex-row sm:gap-5">
        <a href={faqLink} className={`${linkClasses.join(' ')}`}>
          <FormattedMessage defaultMessage="FAQ" description="Footer: FAQ" />
        </a>
        <a href={contactUsLink} className={`${linkClasses.join(' ')}`}>
          <FormattedMessage defaultMessage="Contact us" description="Footer: Contact us" />
        </a>
        <a href={creditLineLink} className={`${linkClasses.join(' ')}`}>
          <FormattedMessage
            defaultMessage="About creditline"
            description="Footer: About creditline"
          />
        </a>
      </div>
    </div>
  );
};

export default Footer;
