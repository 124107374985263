import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import Button from '@common/components/button/Button';
import Checkbox from '@common/components/checkbox/Checkbox';
import CheckmarkIcon from '@common/components/icons/CheckmarkIcon';
import HidePasswordIcon from '@common/components/icons/HidePasswordIcon';
import ShowPasswordIcon from '@common/components/icons/ShowPasswordIcon';
import ImageCard from '@common/components/image-card/ImageCard';
import PasswordInput from '@common/components/password-form/PasswordInput';
import TextInput from '@common/components/text-input/TextInput';
import { yupResolver } from '@hookform/resolvers/yup';
import BaseCard from '@smartsaver/components/base-card/BaseCard';
import DataProtectedBlock from '@smartsaver/components/data-protected-block/DataProtectedBlock';
import { SmartSaverRoute } from '@smartsaver/constants/routes';
import useLinks from '@smartsaver/hooks/useLinks';
import { useLocale } from '@smartsaver/hooks/useLocale';
import img from '@src/assets/img/smartsaver/signup.webp';
import { UseMutationResult } from '@tanstack/react-query';

import m from './SignUpForm.messages';
import { SignupFormSchema, getFormSchema } from './SignUpForm.schema';

interface SignupFormProps {
  signupMutation: UseMutationResult<void, Error, SignupFormSchema>;
}

const SignUpForm = ({ signupMutation }: SignupFormProps) => {
  const { formatMessage } = useIntl();
  const schema = useMemo(() => getFormSchema({ formatMessage }), [formatMessage]);
  const { locale } = useLocale();
  const { links } = useLinks();
  const ctx = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const {
    formState: { isSubmitting, isValid },
  } = ctx;

  return (
    <form
      autoComplete="off"
      className="flex size-full justify-center"
      onSubmit={ctx.handleSubmit(async (v: SignupFormSchema) => {
        try {
          signupMutation.mutate(v);
        } catch (_) {}
      })}
    >
      <FormProvider {...ctx}>
        <ImageCard imagePosition="right" imageSrc={img} hideImageOnMobile>
          <BaseCard
            paddingClassName="p-0"
            widthClassName="w-full"
            gapClassName="gap-6"
            title={formatMessage(m.title)}
            subTitle={formatMessage(m.subTitle)}
          >
            <div className="flex flex-col gap-3.5 self-stretch">
              <TextInput
                aria-label="email"
                styling="smartsaver"
                name="identifier"
                autoComplete="new-password"
                label={formatMessage(m.emailLabel)}
              />
              <PasswordInput
                aria-label="password"
                name="password"
                styling="smartsaver"
                label={formatMessage(m.passwordLabel)}
                showHideIcons={{ hide: <ShowPasswordIcon />, show: <HidePasswordIcon /> }}
              />
              <div className="mb-2 mt-7">
                <Checkbox
                  aria-label="consent"
                  name="consent"
                  checkedMarkComponent={
                    <div className="rotate-180">
                      <CheckmarkIcon />
                    </div>
                  }
                  labelComponent={
                    <div className="text-[15px] font-medium leading-normal text-black">
                      <FormattedMessage
                        {...m.consentLabel}
                        values={{
                          termsOfUselink: (
                            <a
                              className="underline"
                              rel="noreferrer"
                              target="_blank"
                              href={links.termsOfUse}
                            >
                              <FormattedMessage {...m.termsOfUse} />
                            </a>
                          ),
                          privacyPolicyLink: (
                            <a
                              className="underline"
                              rel="noreferrer"
                              target="_blank"
                              href={links.privacyPolicy}
                            >
                              <FormattedMessage {...m.privacyPolicy} />
                            </a>
                          ),
                        }}
                      />
                    </div>
                  }
                />
              </div>
              <Button
                text={formatMessage(m.continue)}
                type="submit"
                disabled={!isValid || isSubmitting}
              />
            </div>
            <div className="mt-7  flex w-full flex-col gap-7">
              <div className="w-full text-center font-semibold">
                <FormattedMessage
                  {...m.haveAccount}
                  values={{
                    loginLink: (
                      <Link
                        className="font-semibold underline"
                        to={`/${locale}/${SmartSaverRoute.LOGIN}`}
                      >
                        {formatMessage(m.login)}
                      </Link>
                    ),
                  }}
                />
              </div>
              <div className="flex text-left  text-sm font-normal leading-normal text-black sm:hidden">
                {formatMessage(m.cancelEmail)}
              </div>
              <DataProtectedBlock />
            </div>
          </BaseCard>
        </ImageCard>
      </FormProvider>
    </form>
  );
};

export default SignUpForm;
