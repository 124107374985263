import { getUserSubscriptions } from '@common/redux/thunks/subscription';
import { Subscription } from '@common/types/subscription';
import { createSlice } from '@reduxjs/toolkit';

interface SubscriptionInitialState {
  loading: boolean;
  error: boolean;
  userSubscriptions: Subscription[] | null;
}

export const initialState: SubscriptionInitialState = {
  loading: false,
  error: false,
  userSubscriptions: null,
};

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
    }),
    setLoading: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getUserSubscriptions.pending, (state) => ({
      ...state,
      loading: true,
      error: false,
    }));

    builder.addCase(getUserSubscriptions.fulfilled, (state, action) => ({
      userSubscriptions: action.payload,
      loading: false,
      error: false,
    }));

    builder.addCase(getUserSubscriptions.rejected, (state) => ({
      ...state,
      loading: false,
      error: true,
    }));
  },
});

export const { reset, setLoading } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
