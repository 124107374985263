import { useState } from 'react';

export enum Environment {
  PRODUCTION = 'production',
  STAGING = 'staging',
  DEVELOPMENT = 'development',
}

const useEnvironment = () => {
  const definedEnv = process.env.REACT_APP_ENVIRONMENT as Environment;
  const [environment] = useState<Environment | null>(() =>
    Object.values(Environment).includes(definedEnv) ? definedEnv : null
  );

  const isDevEnv = environment ? environment === Environment.DEVELOPMENT : undefined;
  const isProduction = environment ? environment === Environment.PRODUCTION : undefined;
  const isDevOrStagingEnv = environment
    ? [Environment.DEVELOPMENT, Environment.STAGING].includes(environment)
    : undefined;

  return { environment, isDevEnv, isDevOrStagingEnv, isProduction };
};

export default useEnvironment;
