import { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import AddressInput from '@common/components/address-input/AddressInput';
import Button, { ButtonSpacing } from '@common/components/button/Button';
import SignupBaseCard from '@common/components/sign-up-layout/SignupBaseCard';
import TextInput from '@common/components/text-input/TextInput';
import { ES_ADDRESS_FIELD, ES_POSTAL_CODE } from '@common/constants';
import { useApplicationFlowInstance, useDispatch } from '@common/hooks';
import { RootDispatch } from '@common/redux';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions } from '@common/services/application';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import m from './AddressForm.messages';

interface FormValues {
  address: {
    region: string;
    city: string;
    street: string;
    postCode: string;
    houseNo: string;
    houseName?: string | null;
    floorNumber?: string | null;
    door?: string | null;
  };
}

const AddressForm = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<RootDispatch>();
  const [instanceId] = useApplicationFlowInstance();
  const [isManualAddressEnter, setIsManualAddressEnter] = useState(false);
  const schema: yup.ObjectSchema<FormValues> = useMemo(() => {
    return yup.object({
      address: yup.object({
        region: yup
          .string()
          .matches(ES_ADDRESS_FIELD, formatMessage(m.minThreeLettersError))
          .required(formatMessage(m.mandatoryFieldError)),
        city: yup
          .string()
          .matches(ES_ADDRESS_FIELD, formatMessage(m.minThreeLettersError))
          .required(formatMessage(m.mandatoryFieldError)),
        street: yup
          .string()
          .matches(ES_ADDRESS_FIELD, formatMessage(m.minThreeLettersError))
          .required(formatMessage(m.mandatoryFieldError)),
        postCode: yup
          .string()
          .matches(ES_POSTAL_CODE, formatMessage(m.postalCodeError))
          .required(formatMessage(m.mandatoryFieldError)),
        houseNo: yup
          .string()
          .matches(/[a-zA-Z0-9]/, formatMessage(m.oneLetterOneNumberError))
          .required(formatMessage(m.mandatoryFieldError)),
        houseName: yup
          .string()
          .test('house-name-validation', 'Invalid house name', (value) => {
            if (!value) {
              return true;
            }
            return !!value.match(ES_ADDRESS_FIELD);
          })
          .optional()
          .nullable(),

        floorNumber: yup
          .string()
          .optional()
          .nullable()
          .test('is-empty-or-valid', formatMessage(m.floorNumberRangeError), function (value) {
            if (value === null || value === undefined || value === '') {
              return true;
            }
            const num = parseInt(value, 10);
            if (!/^-?\d+$/.test(value)) {
              return this.createError({
                message: formatMessage(m.floorNumberRangeError),
              });
            }
            if (num < -5 || num > 120) {
              return this.createError({
                message: formatMessage(m.floorNumberRangeError),
              });
            }
            return true;
          }),
        door: yup.string().optional().nullable(),
      }),
    });
  }, [formatMessage]);

  const ctx = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const {
    setValue,
    watch,
    clearErrors,
    formState: { isSubmitting, isValid },
  } = ctx;

  const handleSwitchToManualEnter = useCallback(() => {
    setIsManualAddressEnter((prev) => !prev);
    setValue('address.houseName', '');
    setValue('address.postCode', '');
    setValue('address.street', '');
    setValue('address.houseNo', '');
    setValue('address.city', '');
    setValue('address.region', '');
    setValue('address.floorNumber', '');
    setValue('address.door', '');
    clearErrors();
  }, [setValue, clearErrors]);

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      await dispatch(
        performApplicationFlowAction({
          action: FlowActions.SUBMIT,
          instanceId,
          data: { ...values.address },
        })
      ).unwrap();
    },
    [dispatch, instanceId]
  );

  const postCode = watch('address.postCode');

  const shouldHideDoorAndFloor = useMemo(
    () => !isManualAddressEnter && !postCode,
    [isManualAddressEnter, postCode]
  );

  return (
    <SignupBaseCard
      headerText={formatMessage(m.cardTitle)}
      subHeaderText={formatMessage(isManualAddressEnter ? m.manualCardSubTitle : m.cardSubTitle)}
    >
      <form
        autoComplete="off"
        className="flex size-full"
        onSubmit={ctx.handleSubmit(async (v: FormValues) => {
          try {
            await handleSubmit(v);
          } catch (_) {}
        })}
      >
        <FormProvider {...ctx}>
          <div className="flex size-full flex-col gap-3 sm:gap-3">
            {isManualAddressEnter ? (
              <>
                <TextInput name="address.street" label={formatMessage(m.addressLabel)} />
                <TextInput name="address.houseNo" label={formatMessage(m.houseNameOrNumberLabel)} />
              </>
            ) : (
              <div>
                <AddressInput.Fetchify
                  name="address"
                  label={formatMessage(m.addressLabel)}
                  errorMessage={formatMessage(m.missingAddressError)}
                />
                <Button
                  type="button"
                  extraClassNames="mt-1 mb-1"
                  onClick={handleSwitchToManualEnter}
                  spacing={ButtonSpacing.NONE}
                  color="underlined-black"
                  text={formatMessage(m.helpButtonText)}
                />
              </div>
            )}
            {!shouldHideDoorAndFloor && (
              <>
                <TextInput
                  name="address.floorNumber"
                  step="1"
                  type="number"
                  label={formatMessage(m.floorNumberLabel)}
                />
                <TextInput name="address.door" label={formatMessage(m.doorLabel)} />
              </>
            )}
            {isManualAddressEnter && (
              <>
                <TextInput
                  type="number"
                  step="1"
                  min={1}
                  name="address.postCode"
                  label={formatMessage(m.postalCodeLabel)}
                />
                <TextInput name="address.city" label={formatMessage(m.townMunicipalityLabel)} />
                <TextInput name="address.region" label={formatMessage(m.regionLabel)} />
              </>
            )}
            <div className="mt-5 flex gap-2 self-stretch">
              {isManualAddressEnter && (
                <Button
                  fullWidth
                  type="button"
                  onClick={handleSwitchToManualEnter}
                  color="transparent"
                  hoverClassName="hover:bg-black hover:text-white"
                  borderClassName="border-2 border-black"
                  text={formatMessage(m.goBackButton)}
                />
              )}

              <Button
                fullWidth
                type="submit"
                disabled={isSubmitting || !isValid}
                text={formatMessage(m.continueButton)}
                extraClassNames="hover:bg-white max-sm:hover:bg-black hover:border-2 hover:border-black hover:text-black max-sm:hover:text-white"
              />
            </div>
          </div>
        </FormProvider>
      </form>
    </SignupBaseCard>
  );
};

export default AddressForm;
