import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import Button, { ButtonSpacing } from '@common/components/button/Button';
import CurrencyInput from '@common/components/currency-input/CurrencyInput';
import PencilIcon from '@common/components/icons/PencilIcon';
import BlackDotsLoader from '@common/components/loader/BlackDotsLoader';
import Slider from '@common/components/slider/Slider';
import { Currency } from '@common/constants';
import { SubscriptionName } from '@common/enums';
import { useSelector } from '@common/hooks';
import useDebouncedValue from '@common/hooks/useDebouncedValue';
import useIntercomChat from '@common/hooks/useIntercomChat';
import useSubsciptions from '@common/hooks/useSubscriptions';
import { selectBalance } from '@common/redux/selectors/banking';
import { yupResolver } from '@hookform/resolvers/yup';
import InfoRow from '@monefit-es/components/info-row/InfoRow';
import PaymentStartFormHeader from '@monefit-es/components/payment-start-form-header/PaymentStartFormHeader';
import { MIN_WITHDRAWAL_AMOUNT } from '@monefit-es/constants/withdrawal-limit';
import useLimitAndBalance from '@monefit-es/hooks/useLimitAndBalance';
import { PaymentStep } from '@monefit-es/pages/payment-page/PaymentPage.types';
import clsx from 'clsx';
import { format, parseISO } from 'date-fns';
import * as yup from 'yup';

import { getClassNames } from './PaymentStartForm.classes';
import m from './PaymentStartForm.messages';

interface FormValues {
  amount: number;
}
interface PaymentStartFormProps {
  setStep: (v: PaymentStep) => void;
  setAmount: (v: number) => void;
  amount?: number;
}

const PaymentStartForm = ({ setStep, setAmount, amount: defaultAmount }: PaymentStartFormProps) => {
  const [isManualAmountEdit, setIsManualAmountEdit] = useState(false);
  const [pendingLeftToPayRequests, setPendingLeftToPayRequests] = useState(0);
  const { getHasActiveSubsctiption } = useSubsciptions();
  const subscriptionEnabled = getHasActiveSubsctiption(SubscriptionName.CREDIT_LINE_PREMIUM);
  const { formatMessage, formatNumber } = useIntl();
  const { balanceLoading, limitLoading, agreement, balanceSummary, fetchBalanceSummary } =
    useLimitAndBalance({
      disableAgreementFetch: true,
      disableBalanceFetch: true,
      disableLimitFetch: true,
    });
  const balanceData = useSelector(selectBalance);

  const paymentData = balanceSummary?.balance?.total;

  const { openChat } = useIntercomChat();
  const schema: yup.ObjectSchema<FormValues> = useMemo(() => {
    return yup.object({
      amount: yup
        .number()
        .required()
        .max(paymentData?.maxPayment ?? 0)
        .min(paymentData?.minPayment ?? 0),
    });
  }, [paymentData?.maxPayment, paymentData?.minPayment]);

  const minAmount = useMemo(
    () => paymentData?.minPayment ?? MIN_WITHDRAWAL_AMOUNT,
    [paymentData?.minPayment]
  );

  const formatDate = (dateString: string) => {
    const dateObj = parseISO(dateString);
    const day = parseInt(format(dateObj, 'd'));
    const month = format(dateObj, 'MMM');

    const getOrdinalSuffix = (day: number) => {
      if (day > 3 && day < 21) {
        return formatMessage(m.suffixTh);
      }
      switch (day % 10) {
        case 1:
          return formatMessage(m.suffixSt);
        case 2:
          return formatMessage(m.suffixNd);
        case 3:
          return formatMessage(m.suffixRd);
        default:
          return formatMessage(m.suffixTh);
      }
    };

    return `${day}${getOrdinalSuffix(day)} ${month}`;
  };

  const formatCurrency = useCallback(
    (amount: number | undefined = 0) => {
      return formatNumber(amount, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        currencyDisplay: 'symbol',
        currency: Currency.EUR,
        style: 'currency',
      });
    },
    [formatNumber]
  );

  const ctx = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      amount: defaultAmount || paymentData?.maxPayment || MIN_WITHDRAWAL_AMOUNT,
    },
  });

  const {
    watch,
    setValue,
    formState: { isSubmitting, isValid },
  } = ctx;

  const amount = watch('amount');
  const debouncedAmount = useDebouncedValue(amount, isManualAmountEdit ? 1000 : 100);
  const abortControllerRef = useRef(new AbortController());

  const nextInvoiceTargetDate = balanceData?.nextInvoiceTargetDate;

  const handleFormSubmit = useCallback(
    async (v: FormValues) => {
      setAmount(v.amount);
      setStep(PaymentStep.CHECKOUT);
    },
    [setAmount, setStep]
  );

  const handleFetchLeftToPay = useCallback(async () => {
    setPendingLeftToPayRequests((prev) => prev + 1);
    try {
      if (debouncedAmount < minAmount) {
        setValue('amount', minAmount);
      }
      // We need to abort previous requests if new one was created via slider/currencyInput
      abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();
      const amountForFetch = debouncedAmount < minAmount ? minAmount : debouncedAmount;

      await fetchBalanceSummary(agreement?.id, amountForFetch, abortControllerRef.current.signal);
    } catch (_) {
    } finally {
      setPendingLeftToPayRequests((prev) => prev - 1);
      setAmountLoading(false);
    }
  }, [agreement?.id, debouncedAmount, fetchBalanceSummary, minAmount, setValue]);

  const toggleEditMode = useCallback(() => {
    setIsManualAmountEdit((prev) => !prev);
  }, []);

  const classes = useMemo(() => getClassNames(), []);

  useEffect(() => {
    handleFetchLeftToPay();
  }, [handleFetchLeftToPay]);

  useEffect(() => {
    if (amount === minAmount || amount === paymentData?.maxPayment) {
      return;
    }
    setValue('amount', Number(Math.floor(amount).toFixed(2)));
  }, [amount, minAmount, setValue, paymentData?.maxPayment]);

  const [amountLoading, setAmountLoading] = useState<boolean>(false);

  const renderSubscriptionContent = () => {
    if (amountLoading || pendingLeftToPayRequests > 0) {
      return <BlackDotsLoader style={{ height: 20 }} />;
    }
    if (paymentData?.leftToPay === 0) {
      return <div> {formatMessage(m.noRemainingPayment)} </div>;
    }
    return balanceSummary?.invoice?.lastInvoiceByDueDate?.interestAmount !== 0 ? (
      <div>
        {formatMessage(m.noPaymentWarning, {
          remainingPayment: formatCurrency(paymentData?.leftToPay),
          nextInvoiceTargetDate: nextInvoiceTargetDate ? formatDate(nextInvoiceTargetDate) : '',
          interestLeftToPay: formatCurrency(paymentData?.interestLeftToPay),
        })}
      </div>
    ) : null;
  };

  const renderNoSubscriptionContent = () => {
    return (
      <div>
        {formatMessage(m.reduceInvoiceToAmount, {
          amount: formatCurrency(amount),
        })}
      </div>
    );
  };

  return (
    <form
      autoComplete="off"
      className="flex size-full"
      onSubmit={ctx.handleSubmit(async (v: FormValues) => {
        try {
          await handleFormSubmit(v);
        } catch (_) {}
      })}
    >
      <FormProvider {...ctx}>
        <div className="flex w-full">
          <div className="flex w-full flex-col items-start justify-end self-stretch">
            <PaymentStartFormHeader />
            <div className={classes.formContainer}>
              <div className="flex flex-col items-start justify-center gap-5 self-stretch">
                <div className="flex flex-col items-start justify-center gap-2 self-stretch">
                  <div className="text-base leading-snug tracking-tight text-black">
                    {formatMessage(m.amountSelect)}
                  </div>
                  {paymentData?.maxPayment && isManualAmountEdit && (
                    <CurrencyInput
                      disabled={isSubmitting}
                      name="amount"
                      customClassName="h-14"
                      limit={paymentData?.maxPayment}
                    />
                  )}
                  {!isManualAmountEdit && (
                    <div className="flex justify-between self-stretch">
                      <div
                        className={clsx('font-heavy  text-4xl leading-10 text-black sm:text-5xl')}
                      >
                        {formatCurrency(amount)}
                      </div>
                      <button type="button" onClick={toggleEditMode}>
                        <PencilIcon />
                      </button>
                    </div>
                  )}
                </div>
                {paymentData?.maxPayment && !isManualAmountEdit && (
                  <Slider
                    color="green"
                    allowsDecimals
                    name="amount"
                    min={minAmount}
                    step={1}
                    max={paymentData.maxPayment}
                    onInput={() => {
                      setAmountLoading(true);
                    }}
                  />
                )}
                {amount === minAmount && (
                  <InfoRow
                    title={formatMessage(m.payLesss)}
                    value={
                      <div className="font-semibold">
                        <Button
                          spacing={ButtonSpacing.XS}
                          type="button"
                          color="dark-gray"
                          text="Contact support"
                          textSize="sm"
                          onClick={() => openChat()}
                        />
                      </div>
                    }
                  />
                )}
                <div className="text-base font-medium leading-normal text-black  text-opacity-60">
                  {subscriptionEnabled
                    ? renderSubscriptionContent()
                    : renderNoSubscriptionContent()}
                </div>
                <Button
                  disabled={!isValid || isSubmitting || limitLoading || balanceLoading}
                  type="submit"
                  fullWidth
                  text={formatMessage(m.continue)}
                />
              </div>
            </div>
          </div>
        </div>
      </FormProvider>
    </form>
  );
};

export default PaymentStartForm;
