import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  title: {
    defaultMessage: 'Almost there ⚡️',
  },
  subTitle: {
    defaultMessage: 'Please complete your profile.',
  },
  firstName: {
    defaultMessage: 'First name',
  },
  lastName: {
    defaultMessage: 'Last name',
  },
  birthDay: {
    defaultMessage: 'Birthday',
  },
  taxResidency: {
    defaultMessage: 'Tax residency',
  },
  generalInfo: {
    defaultMessage: 'General information',
  },
  yourAddress: {
    defaultMessage: 'Your address',
  },
  selectCountry: {
    defaultMessage: 'Select country',
  },
  address: {
    defaultMessage: 'Address',
  },
  city: {
    defaultMessage: 'City',
  },
  postalCode: {
    defaultMessage: 'Postal code',
  },
  promoCodeTitle: {
    defaultMessage: 'Promo code (optional)',
  },
  promoCodeLabel: {
    defaultMessage: 'Enter a promo code',
  },
  ctaButton: {
    defaultMessage: 'Signup',
    description: '@smartsaver Signup individal cta button',
  },
  dataProtected: {
    defaultMessage: 'Your data is protected 🔒',
    description: '@smartsaver Your data is protected 🔒',
  },
  fieldIsMandatory: {
    defaultMessage: 'This field is required',
  },
});
