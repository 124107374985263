import { FC, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import EyeIcon from '@common/components/icons/EyeIcon';
import PencilDrawlIcon from '@common/components/icons/PencilDrawIcon';
import ImageCard from '@common/components/image-card/ImageCard';
import { useApplicationFlowInstance, useDispatch, useLocale, useSelector } from '@common/hooks';
import useEnvironment from '@common/hooks/useEnvironment';
import { RootDispatch } from '@common/redux';
import { setShowLoader } from '@common/redux/slices/application';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions } from '@common/services/application';
import { getPublicUrl, getUserData } from '@common/utils';
import DocumentsFooter from '@monefit-es/components/documents-footer/DocumentsFooter';
import DocumentsItem from '@monefit-es/components/documents-item/DocumentsItem';
import DocumentsModal from '@monefit-es/components/documents-modal/DocumentsModal';
import { useDocuments } from '@monefit-es/hooks/useDocuments';
import documentsImg from '@src/assets/img/monefit-es/documents_1.webp';

import { DocumentType } from './Document.types';
import m from './Documents.messages';

interface DocumentProps {
  setRedirectUrl: (v: string | null) => void;
}
const Documents: FC<DocumentProps> = ({ setRedirectUrl }) => {
  const { saveDocumentToStorage } = useDocuments();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<RootDispatch>();
  const [modalSate, setModalState] = useState<{ type: 'SECCI' | 'AGREEMENT'; show: boolean }>({
    type: 'SECCI',
    show: false,
  });
  const [instanceId] = useApplicationFlowInstance();
  const { data } = useSelector((state) => state.application.flowInstance);
  const [documentHasLoaded, setDocumentHasLoaded] = useState<boolean>(false);
  const [documentId, setDocumentId] = useState<string | null>(null);
  const { localeWithCountry } = useLocale();
  const { environment } = useEnvironment();
  const closeAllModals = useCallback(() => {
    setModalState({
      type: 'SECCI',
      show: false,
    });
  }, []);

  const createSecciDocument = useCallback(async () => {
    await dispatch(
      performApplicationFlowAction({
        instanceId,
        action: FlowActions.CREATE,
        data: {
          templateName: DocumentType.SECCI,
          name: 'secci',
          type: 'secci',
        },
      })
    )
      .unwrap()
      .then((res) => {
        saveDocumentToStorage({
          type: DocumentType.SECCI,
          id: res.responseData.id,
          isSigned: true,
        });
      })
      .catch(() => {
        closeAllModals();
      });
  }, [dispatch, instanceId, closeAllModals, saveDocumentToStorage]);

  const getSigningURL = useCallback(
    async (documentId: string) => {
      const { user } = getUserData();
      const { REACT_APP_ES_APPLICATION_FLOW_ID: flowId } = process.env;

      const resp = await dispatch(
        performApplicationFlowAction({
          action: FlowActions.SIGN,
          instanceId,
          shouldRefreshToken: true,
          data: {
            documentId,
            data: {
              email: user?.email,
              name:
                user?.firstName && user?.lastName
                  ? `${user.firstName} ${user.lastName}`
                  : (user.displayName ?? user.email),
              returnUrl: `${getPublicUrl(environment ?? 'development')}/${localeWithCountry}/${flowId}/documents?type=${DocumentType.CREDIT_AGREEMENT}`,
            },
          },
        })
      ).unwrap();

      if (!resp.responseData.description) {
        throw new Error('cannot sign document');
      }

      return resp.responseData.description;
    },
    [dispatch, instanceId, environment, localeWithCountry]
  );
  const handleSecciDocumentAccept = useCallback(async () => {
    try {
      dispatch(setShowLoader(false));
      await createSecciDocument();
    } finally {
      dispatch(setShowLoader(true));
      closeAllModals();
    }
  }, [closeAllModals, dispatch, createSecciDocument]);

  const previewSecciDocument = useCallback(
    async (templateName: DocumentType) => {
      dispatch(setShowLoader(false));
      await dispatch(
        performApplicationFlowAction({
          instanceId,
          action: FlowActions.PREVIEW,
          data: { templateName },
        })
      )
        .unwrap()
        .finally(() => {
          dispatch(setShowLoader(true));
        });
    },
    [dispatch, instanceId]
  );

  const createSignedAgreement = useCallback(async () => {
    const resp = await dispatch(
      performApplicationFlowAction({
        instanceId,
        action: FlowActions.CREATE,
        data: {
          templateName: DocumentType.CREDIT_AGREEMENT,
          name: 'contrato',
          type: 'agreement',
        },
      })
    ).unwrap();

    if (!resp.responseData) {
      throw new Error('unable to create agreement signing document');
    }

    return resp.responseData.id;
  }, [dispatch, instanceId]);

  const renderDocuSign = useCallback(
    async (documentSignURL: string) => {
      if (!documentSignURL || !process.env.REACT_APP_DOCUSIGN_INTEGRATION_KEY) {
        return;
      }

      const docusign = await window?.DocuSign?.loadDocuSign(
        process.env.REACT_APP_DOCUSIGN_INTEGRATION_KEY ?? ''
      );

      const signing = await docusign.signing({
        url: documentSignURL,
        displayFormat: 'focused',
        style: {
          /** High-level variables that mirror our existing branding APIs. Reusing the branding name here for familiarity. */
          branding: {
            primaryButton: {
              /** Background color of primary button */
              backgroundColor: '#333',
              /** Text color of primary button */
              color: '#fff',
            },
          },

          /** High-level components we allow specific overrides for */
          signingNavigationButton: {
            nextText: formatMessage(m.nextButtonText),
            next: formatMessage(m.nextButtonText),
            finishText: formatMessage(m.continueButtonText),
            /** 'bottom-left'|'bottom-center'|'bottom-right',  default: bottom-right */
            position: 'bottom-center',
          },
        },
      });

      signing.on('ready', (event: any) => {
        if (documentHasLoaded) {
          return;
        }

        setDocumentHasLoaded(true);
      });

      signing.on('sessionEnd', (event: any) => {
        saveDocumentToStorage({
          type: DocumentType.CREDIT_AGREEMENT,
          id: documentId || '',
          isSigned: true,
        });
        closeAllModals();
      });

      signing.mount(document.querySelector('#docusign-container'));
    },
    [closeAllModals, documentHasLoaded, documentId, formatMessage, saveDocumentToStorage]
  );

  return (
    <>
      <ImageCard imageSrc={documentsImg}>
        <div className="flex size-full flex-col justify-between gap-3 ">
          <div className="flex flex-col gap-5">
            <div className="font-heavy text-4xl font-black leading-10 text-black">
              {formatMessage(m.headTitle)}
            </div>
            <div className="text-base font-medium leading-normal text-black">
              {formatMessage(m.subtitle1)}
              <br />
              {formatMessage(m.subtitle2)}
            </div>
          </div>
          <DocumentsItem
            icon={<EyeIcon width="19" height="19" />}
            type={DocumentType.SECCI}
            headerText={formatMessage(m.secci)}
            subText={formatMessage(m.secciText)}
            onSignClick={async () => {
              setModalState({ type: 'SECCI', show: true });
              await previewSecciDocument(DocumentType.SECCI);
            }}
          />

          <DocumentsItem
            icon={<PencilDrawlIcon />}
            type={DocumentType.CREDIT_AGREEMENT}
            headerText={formatMessage(m.loadAgreement)}
            subText={formatMessage(m.loadAgreementText)}
            onSignClick={async () => {
              setModalState({ type: 'AGREEMENT', show: true });
              dispatch(setShowLoader(false));

              const documentId = await createSignedAgreement();
              setDocumentId(documentId);
              const signingURL = await getSigningURL(documentId);
              renderDocuSign(signingURL);
            }}
          />
          <DocumentsFooter />
        </div>
      </ImageCard>

      {/* SECCI MODAL */}
      <DocumentsModal
        id="docusign-container"
        hideAccept={modalSate.type === 'AGREEMENT'}
        isOpen={modalSate.show}
        onBackClick={() => {
          setModalState({ type: 'SECCI', show: false });
        }}
        onAcceptClick={async () => {
          if (modalSate.type === 'SECCI') {
            await handleSecciDocumentAccept();
          }
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: data?.responseData }} />
      </DocumentsModal>
    </>
  );
};
export default Documents;
