import FacebookIcon from '@common/components/icons/social-media/FacebookIcon';
import InstagramIcon from '@common/components/icons/social-media/InstagramIcon';
import LinkedIn from '@common/components/icons/social-media/LinkedIn';
import XIcon from '@common/components/icons/social-media/XIcon';
import YoutubeIcon from '@common/components/icons/social-media/YoutubeIcon';
import { LINKS } from '@common/constants';
import WaitingListFooterButton from '@monefit-es/components/waiting-list-footer-button/WaitingListFooterButton';

const WaitingListFooterLinks = () => {
  return (
    <div className="mb-12 flex justify-center gap-4 sm:gap-10">
      <WaitingListFooterButton
        link={LINKS.ES_FACEBOOK}
        defatultIcon={<FacebookIcon isBlackAndWhite />}
        coloredIcon={<FacebookIcon />}
      />
      <WaitingListFooterButton
        link={LINKS.INSTAGRAM}
        defatultIcon={<InstagramIcon isBlackAndWhite />}
        coloredIcon={<InstagramIcon />}
      />
      <WaitingListFooterButton
        link={LINKS.YOUTUBE}
        defatultIcon={<YoutubeIcon isBlackAndWhite />}
        coloredIcon={<YoutubeIcon />}
      />
      <WaitingListFooterButton
        link={LINKS.LINKEDIN}
        defatultIcon={<LinkedIn isBlackAndWhite />}
        coloredIcon={<LinkedIn />}
      />
      <WaitingListFooterButton
        link={LINKS.X}
        defatultIcon={<XIcon isBlackAndWhite />}
        coloredIcon={<XIcon />}
      />
    </div>
  );
};

export default WaitingListFooterLinks;
