import { useCallback, useEffect, useMemo } from 'react';
import { useIntercom } from 'react-use-intercom';

import { useCookieConsents } from '@common/hooks/useCookieConsents';
import useEnvironment from '@common/hooks/useEnvironment';
import { delay } from '@common/utils';
import { useLocale } from '@smartsaver/hooks/useLocale';

const INIT_DELAY = 1000;

const Intercom = () => {
  const { boot, shutdown } = useIntercom();
  const { locale } = useLocale();
  const { consents, loaded: constentsLoaded } = useCookieConsents();
  const { isDevOrStagingEnv } = useEnvironment();

  const canLoadIntercom = useMemo(
    () => (isDevOrStagingEnv ? true : constentsLoaded && consents.necessary),
    [consents, constentsLoaded, isDevOrStagingEnv]
  );

  const bootIntercom = useCallback(async () => {
    await delay(INIT_DELAY);
    boot({ languageOverride: locale });
  }, [boot, locale]);

  const updateIntercom = useCallback(() => {
    shutdown();
    bootIntercom();
  }, [shutdown, bootIntercom]);

  useEffect(() => {
    if (!canLoadIntercom) {
      return;
    }
    bootIntercom();
  }, []);

  useEffect(() => {
    if (!canLoadIntercom) {
      return;
    }
    updateIntercom();
  }, [updateIntercom, canLoadIntercom]);

  return null;
};

export default Intercom;
