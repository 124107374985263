import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  headerText: {
    defaultMessage: 'You will receive funds today',
    description: 'Monefit-ES: You will receive funds today',
  },
  subTitle1: {
    defaultMessage: 'New credit limit',
    description: 'New credit limit',
  },
  subTitle2: {
    defaultMessage: 'Next invoice date',
    description: 'Next invoice date',
  },
  doneButtonText: {
    defaultMessage: 'Done',
    description: 'Done',
  },
  amountSent: {
    defaultMessage: '{amount} € sent to your bank',
    description: '{amount} € sent to your bank',
  },
});
