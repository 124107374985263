import { useCallback, useMemo } from 'react';

import Optimove from '@optimove-inc/web-sdk';

import useEnvironment from './useEnvironment';

const useOptimove = () => {
  const { isProduction } = useEnvironment();
  const { REACT_APP_OPTIMOVE_TOKEN } = process.env;

  const optimove = useMemo(
    () => new Optimove(REACT_APP_OPTIMOVE_TOKEN ?? ''),
    [REACT_APP_OPTIMOVE_TOKEN]
  );

  const setUserId = useCallback(
    (userId: string) => {
      if (!isProduction) {
        return;
      }

      return optimove.setUserId(userId);
    },
    [optimove, isProduction]
  );

  const registerUser = useCallback(
    (userId: string, email: string) => {
      if (!isProduction) {
        return;
      }

      return optimove.registerUser(userId, email);
    },
    [isProduction, optimove]
  );

  const setPageVisit = useCallback(
    ({ url, title, userId }: { url?: string; title?: string; userId?: string }) => {
      if (!isProduction) {
        return;
      }

      if (userId) {
        optimove.setUserId(userId);
      }

      return optimove.setPageVisit(url, title);
    },
    [isProduction, optimove]
  );

  const reportEvent = useCallback(
    ({
      eventName,
      params,
      userId,
    }: {
      eventName: string;
      params?: Record<string, any>;
      userId?: string;
    }) => {
      if (!isProduction) {
        return;
      }

      if (userId) {
        optimove.setUserId(userId);
      }

      return optimove.reportEvent(eventName, params);
    },
    [isProduction, optimove]
  );

  return {
    reportEvent,
    setPageVisit,
    setUserId,
    registerUser,
  };
};

export default useOptimove;
