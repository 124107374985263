import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import { toast } from 'react-toastify';

import InputError from '@common/components/input-error/InputError';
import InputLabel from '@common/components/input-label/InputLabel';
import { IOption } from '@common/components/select-input/SelectInput';
import { Country } from '@common/hooks';
import useDebouncedValue from '@common/hooks/useDebouncedValue';
import blackDots from '@src/assets/animations/Dots-animation-black.json';
import axios from 'axios';
import Lottie from 'lottie-react';

import { getClassNames } from './AddressInput.Fetchify.classes';
import { getStyles } from './AddressInput.Fetchify.styles';
import { FetchifyAddressRetrieve, FetchifyAddressesFetch } from './AddressInput.Fetchify.types';
import { AddressAttributeMap } from './AddressInput.types';

const FETCHIFY_BASE_URL = 'https://api.craftyclicks.co.uk/address/1.1';

interface SelectValue {
  label: string;
  value: string;
  count: number;
}

export interface AddressInputFetchifyProps {
  name: string;
  label?: string;
  placeholder?: string;
  hideError?: boolean;
  attributeMap?: Partial<AddressAttributeMap>;
  errorMessage?: string;
}

const DEFAULT_ADDRESS_ATTRIBUTE_MAP: AddressAttributeMap = {
  region: 'region',
  city: 'city',
  street: 'street',
  postCode: 'postCode',
  houseNo: 'houseNo',
  houseName: 'houseName',
  floorNo: 'floorNo',
  doorNo: 'doorNo',
};

const AddressInputFetchify: FC<AddressInputFetchifyProps> = ({
  name,
  hideError,
  label = '',
  attributeMap,
  errorMessage,
}) => {
  const { formatMessage } = useIntl();
  const [inputValue, setInputValue] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [options, setOptions] = useState<IOption[]>([]);
  const [isFocused, setIsFocused] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [canShowError, setCanShowError] = useState(false);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const debouncedInputValue = useDebouncedValue(inputValue, 1000);
  const attributeMapFinal = useMemo(
    () => ({ ...DEFAULT_ADDRESS_ATTRIBUTE_MAP, ...attributeMap }),
    [attributeMap]
  );

  const handleInputChange = (value: string) => {
    setInputValue(value);
  };

  const {
    field: { value },
    formState: { isSubmitting },
  } = useController({
    name,
  });

  const {
    formState: { errors },
    trigger,
    setValue,
  } = useFormContext();

  const loadAddressOptions = useCallback(
    async (value: string, id?: string | null) => {
      setMenuIsOpen(true);
      setOptionsLoading(true);
      setSearchFilter(value);

      const { REACT_APP_FETCHIFY_API_KEY } = process.env;

      const payload = {
        key: REACT_APP_FETCHIFY_API_KEY,
        query: value,
        country: Country.ES,
        extra: {
          exclude_pobox: true,
          no_groupings: true,
        },
        id: id,
      };
      try {
        axios.post<FetchifyAddressesFetch>(`${FETCHIFY_BASE_URL}/find`, payload).then((res) => {
          const optionsList = res?.data?.results ?? [];
          const optionsMapped = optionsList.map((x) => ({
            value: x.id,
            label: x.labels.join(', '),
            count: x.count,
          }));
          setOptions(optionsMapped);
          setOptionsLoading(false);
        });
      } catch (_) {
        setOptionsLoading(false);
        toast(formatMessage({ defaultMessage: 'Unbale to fetch addresses' }), { type: 'error' });
      }
    },
    [formatMessage]
  );

  useEffect(() => {
    if (debouncedInputValue.length >= 3) {
      loadAddressOptions(debouncedInputValue);
    }
  }, [debouncedInputValue, loadAddressOptions]);

  const handleChange = useCallback(
    async (newValue: SelectValue | null) => {
      if (!newValue?.value) {
        setValue(name, undefined);
        trigger('address');
        return;
      }

      if (newValue.count > 1) {
        loadAddressOptions(searchFilter, newValue.value);
      } else {
        const { REACT_APP_FETCHIFY_API_KEY } = process.env;
        const payload = {
          key: REACT_APP_FETCHIFY_API_KEY,
          id: newValue.value,
          country: Country.ES,
        };

        try {
          const res = await axios.post(`${FETCHIFY_BASE_URL}/retrieve`, payload);
          const typedRes = res?.data?.result as FetchifyAddressRetrieve;

          // TODO: handle floorNo and doorNo
          const address: {
            [key: string]: string;
          } = {
            floorNo: '0',
            doorNo: '0',
            contactFloorNo: '0',
            contactDoorNo: '0',
          };

          if (attributeMapFinal.region) {
            address[attributeMapFinal.region] = typedRes?.province_name;
          }
          if (attributeMapFinal.city) {
            address[attributeMapFinal.city] = typedRes?.locality;
          }
          if (attributeMapFinal.street) {
            address[attributeMapFinal.street] =
              typedRes?.street_prefix + typedRes?.street_name + typedRes?.street_suffix;
          }
          if (attributeMapFinal.postCode) {
            address[attributeMapFinal.postCode] = typedRes?.postal_code;
          }
          if (attributeMapFinal.houseNo) {
            address[attributeMapFinal.houseNo] = typedRes?.building_number;
          }
          if (attributeMapFinal.houseName) {
            address[attributeMapFinal.houseName] = typedRes?.building_name;
          }

          setValue(name, address);
          trigger();
        } catch (e) {
          console.error(e);
          toast(formatMessage({ defaultMessage: 'Unable to retrieve address' }), { type: 'error' });
        }
        setMenuIsOpen(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      setValue,
      trigger,
      name,
      attributeMapFinal.region,
      attributeMapFinal.city,
      attributeMapFinal.street,
      attributeMapFinal.postCode,
      attributeMapFinal.houseNo,
      attributeMapFinal.houseName,
      searchFilter,
      formatMessage,
    ]
  );

  const styles = useMemo(() => getStyles(), []);
  const classNames = useMemo(() => getClassNames(), []);

  return (
    <div className="relative">
      <Select
        components={{
          LoadingIndicator: () => null,
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
          LoadingMessage: () => (
            <div className="flex size-full items-center justify-center">
              <Lottie animationData={blackDots} style={{ height: 20 }} />
            </div>
          ),
        }}
        filterOption={null}
        options={options}
        inputValue={inputValue}
        isLoading={optionsLoading}
        onInputChange={handleInputChange}
        menuIsOpen={menuIsOpen}
        isClearable
        isDisabled={isSubmitting}
        noOptionsMessage={() =>
          optionsLoading
            ? formatMessage({ defaultMessage: 'Loading' })
            : formatMessage({ defaultMessage: 'No options' })
        }
        onMenuClose={() => setMenuIsOpen(false)}
        classNames={{
          control: () => 'h-16',
          menu: () => 'px-3 py-2 sm:px-7 sm:py-4',
          valueContainer: () => 'px-8 py-7',
        }}
        styles={styles.autoFillInput}
        placeholder=""
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setIsFocused(false);
          setCanShowError(true);
          trigger(name);
        }}
        onChange={handleChange as any}
      />
      <div>
        <InputLabel name={name} text={label} shouldMinimize={isFocused || value !== undefined} />
      </div>
      {!!errors?.[name] && !hideError && (
        <InputError message={errorMessage ?? errors?.[name]?.message?.toString() ?? ''} />
      )}
    </div>
  );
};

export default AddressInputFetchify;
