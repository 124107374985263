import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import Layout from '@common/components/layout/Layout';
import { useDispatch } from '@common/hooks';
import { RootDispatch } from '@common/redux';
import { setShowLoader } from '@common/redux/slices/application';
import DrawdownPendingCard from '@monefit-es/components/drawdown-pending-card/DrawdownPendingCard';

const DrawdownProcessingPage = () => {
  const dispatch = useDispatch<RootDispatch>();
  const { formatMessage } = useIntl();
  useEffect(() => {
    return () => {
      dispatch(setShowLoader(true));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout.WithNavigation
      maxWDesktop={545}
      hideProgressBar
      backButtonText={formatMessage({
        defaultMessage: 'In progress',
        description: 'Monefit-ES: In progress',
      })}
      loaderType="empty"
    >
      <DrawdownPendingCard />
    </Layout.WithNavigation>
  );
};

export default DrawdownProcessingPage;
