import { useCallback, useEffect } from 'react';

import { useApplicationFlowInstance, useDispatch, useInterval, useSelector } from '@common/hooks';
import { RootDispatch } from '@common/redux';
import { selectFlowInstanceStep } from '@common/redux/selectors';
import { setShowLoader } from '@common/redux/slices/application';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions, FlowSteps } from '@common/services/application';
import MainLayout from '@monefit-es/components/layout/MainLayout';
import ManualReviewCard from '@monefit-es/components/manual-review-card/ManualReviewCard';

const INTERVAL = 10 * 1000; // 10s

const ReviewPage = () => {
  const dispatch = useDispatch<RootDispatch>();
  const [instanceId] = useApplicationFlowInstance();
  const currentStep = useSelector(selectFlowInstanceStep);

  useEffect(() => {
    sessionStorage.removeItem('PREDECISION_STEP');
  }, []);

  const checkReview = useCallback(async () => {
    dispatch(setShowLoader(false));
    await dispatch(performApplicationFlowAction({ action: FlowActions.CHECK, instanceId }))
      .unwrap()
      .then((res) => {
        if (res.currentStepId !== FlowSteps.REVIEW) {
          dispatch(setShowLoader(true));
        }
      })
      .catch(() => {
        dispatch(setShowLoader(true));
      });
  }, [dispatch, instanceId]);

  useInterval(checkReview, currentStep === FlowSteps.REVIEW ? INTERVAL : null);

  return (
    <MainLayout type="onboarding">
      <ManualReviewCard />
    </MainLayout>
  );
};

export default ReviewPage;
