import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { SmartSaverRoute } from '@smartsaver/constants/routes';
import { useLocale } from '@smartsaver/hooks/useLocale';
import clsx from 'clsx';

import { getClassNames } from './IndividualCompanySingupSwitch.classes';
import m from './IndividualCompanySingupSwitch.messages';

const IndividualCompanySingupSwitch = () => {
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();
  const { locale } = useLocale();
  const navigate = useNavigate();

  const isIndividualSelected = useMemo(
    () => pathname.includes(SmartSaverRoute.SIGNUP_INDIVIDUAL),
    [pathname]
  );
  const isCompanySelected = useMemo(
    () => pathname.includes(SmartSaverRoute.SIGNUP_COMPANY),
    [pathname]
  );
  const classNames = getClassNames();

  const handleClick = useCallback(() => {
    if (isIndividualSelected) {
      navigate(`/${locale}/${SmartSaverRoute.SIGNUP_COMPANY}`, { replace: true });
    } else {
      navigate(`/${locale}/${SmartSaverRoute.SIGNUP_INDIVIDUAL}`, { replace: true });
    }
  }, [locale, isIndividualSelected, navigate]);

  return (
    <div className="flex w-full">
      <button
        onClick={handleClick}
        type="button"
        className={clsx(classNames.common, 'rounded-l-xl', {
          [classNames.selected]: isIndividualSelected,
        })}
      >
        <div className={classNames.buttonTextContainer}>{formatMessage(m.individual)}</div>
      </button>
      <button
        onClick={handleClick}
        type="button"
        className={clsx(classNames.common, 'rounded-r-xl', {
          [classNames.selected]: isCompanySelected,
        })}
      >
        <div className={classNames.buttonTextContainer}>{formatMessage(m.company)}</div>
      </button>
    </div>
  );
};

export default IndividualCompanySingupSwitch;
