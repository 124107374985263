import { FC, JSX, useEffect, useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import { LocalStorageKeys, MONEFIT_PUBLIC_SITE_URL } from '@common/constants';
import { useApplicationFlowInstance, useApplicationRoutingPageMap } from '@common/hooks';
import { useSelector } from '@common/hooks/useSelector';
import { FlowSteps, FlowType } from '@common/services/application';
import { ALLOWED_FLOW_TYPES } from '@common/utils/helpers';
import { isUUID } from '@common/utils/uuid';
import { kebabCase } from 'lodash';

export interface ApplicationRouteHandlerProps {
  localePath: string;
  children?: JSX.Element;
  flowSteps: FlowSteps[];
}

/**
 * Component for handling application flow related route corrections
 **/
const ApplicationRouteHandler: FC<ApplicationRouteHandlerProps> = ({
  localePath,
  children,
  flowSteps = [],
}) => {
  const { pathname } = useLocation();
  const { instanceId, flowIdOrType: flowIdOrTypeParam } = useParams();
  const { flowInstance } = useSelector((state) => state.application);
  const [storedInstanceId, setStoredInstanceId] = useApplicationFlowInstance();
  const [pageMap] = useApplicationRoutingPageMap(flowSteps);
  const [redirectPath, setRedirectPath] = useState<string>();

  const possibleFlowIdOrTypeFromLocation = pathname.split('/')?.[2]; // e.g. ['', et-ee, application, ...rest]
  const flowIdOrType = flowIdOrTypeParam ?? possibleFlowIdOrTypeFromLocation ?? null;
  const isAllowedFlowType = isUUID(flowIdOrType)
    ? true
    : flowIdOrType && ALLOWED_FLOW_TYPES.indexOf(flowIdOrType as FlowType) !== -1;

  useEffect(() => {
    if (instanceId && instanceId !== storedInstanceId && pathname.includes('/start')) {
      setStoredInstanceId(instanceId);
    }
  }, [instanceId, storedInstanceId, setStoredInstanceId, pathname]);

  // Store flow type on flow start
  useEffect(() => {
    if (flowIdOrType && isAllowedFlowType && pathname.includes('/start')) {
      sessionStorage.setItem(LocalStorageKeys.FLOW_ID_OR_TYPE, flowIdOrType);
    }
  }, [pathname, isAllowedFlowType, flowIdOrType]);

  // Setup redirect to page referred by application service if needed
  useEffect(() => {
    if (!isAllowedFlowType) {
      // TODO: hook to determine current app's public site url
      window.location.replace(MONEFIT_PUBLIC_SITE_URL);
      return;
    }

    const basePath = `/${localePath}/${flowIdOrType}`;
    const currentStepIdPath = `${basePath}/${kebabCase(flowInstance.data?.currentStepId)}`;

    if (currentStepIdPath !== pathname) {
      pageMap.forEach((page) => {
        if (currentStepIdPath === `${basePath}/${page.path}`) {
          setRedirectPath(`${basePath}/${page.path}`);
        }
      });
    }
  }, [
    flowIdOrType,
    localePath,
    isAllowedFlowType,
    pageMap,
    pathname,
    flowInstance.data?.currentStepId,
  ]);

  if (redirectPath && redirectPath !== pathname) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ?? null;
};

export default ApplicationRouteHandler;
