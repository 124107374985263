import { useIntl } from 'react-intl';

import Button from '@common/components/button/Button';
import { useLocale } from '@common/hooks';
import m from '@monefit-es/components/dashboard/Dashboard.messages';

const DashboardFAQ = () => {
  const { formatMessage } = useIntl();
  const { locale } = useLocale();
  const faqUrl =
    locale === 'es'
      ? 'https://monefit.com/wp-content/uploads/2024/10/monefit_credit_FAQ_es-1.pdf'
      : 'https://monefit.com/wp-content/uploads/2024/10/monefit_credit_FAQ_en-1.pdf';
  return (
    <div className="flex h-[240px] w-full flex-col items-start justify-between gap-14 rounded-3xl bg-white px-6 py-7 sm:h-[350px] sm:p-12 ">
      <div className="flex h-20 flex-col items-start justify-start gap-5 self-stretch">
        <div className="inline-flex items-start justify-start gap-5 self-stretch">
          <div className="inline-flex flex-col items-start justify-start gap-2.5">
            <div className="text-lg font-semibold leading-normal text-black">
              {formatMessage(m.gotMoreQuestions)}
            </div>
            <div className="h-11 text-base font-normal text-black">
              {formatMessage(m.faqDescription)}
            </div>
          </div>
        </div>
      </div>
      <a href={faqUrl} target="_blank" rel="noopener noreferrer" className="w-full">
        <Button
          extraClassNames="text-lg"
          color="gray-alt"
          fullWidth
          text={formatMessage(m.openFAQbtnText)}
        />
      </a>
    </div>
  );
};

export default DashboardFAQ;
