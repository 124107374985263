import { documentService } from '@common/services/documents';
import { GetUserDocumentsResponse, UserDocument } from '@common/types/user-document';
import { ApiError, getUserData } from '@common/utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getUserDocuments = createAsyncThunk<UserDocument[] | null>(
  'documents/user-documents',
  async (_, { rejectWithValue }) => {
    const { user } = getUserData();
    if (!user.id) {
      return rejectWithValue('User ID is missing');
    }
    try {
      const searchParams = new URLSearchParams({
        'filter[userId][eq]': user.id,
      });
      const { data } = await documentService.get<GetUserDocumentsResponse>(
        `/document?${searchParams.toString()}`
      );
      return data.documents ?? null;
    } catch (e) {
      throw new ApiError(String(e));
    }
  }
);

export const downloadDocument = createAsyncThunk<void, { id: string; name: string }>(
  'documents/document-download',
  async (documentData) => {
    try {
      const { data } = await documentService.get(`/document/${documentData.id}/download`, {
        responseType: 'blob',
      });
      const blob = new Blob([data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${documentData.name}.pdf`);
      document.body.appendChild(link);
      link.click();
      link?.parentNode && link.parentNode.removeChild(link);
    } catch (e) {
      throw new ApiError(String(e));
    }
  }
);
