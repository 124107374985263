import { IconProps } from '@common/components/icons/types';

const FacebookIcon = ({ isBlackAndWhite }: IconProps) => {
  return (
    <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1246_1195)">
        <mask id="mask0_1246_1195" maskUnits="userSpaceOnUse" x="-9" y="-9" width="61" height="61">
          <path d="M-8.59961 -8.6001H51.6004V51.5999H-8.59961V-8.6001Z" fill="white" />
        </mask>
        <g mask="url(#mask0_1246_1195)">
          <path
            d="M43 21.5C43 9.62591 33.3741 2.67029e-05 21.5 2.67029e-05C9.62589 2.67029e-05 0 9.62591 0 21.5C0 31.5831 6.94149 40.0435 16.3061 42.3669V28.0701H11.8726V21.5H16.3061V18.669C16.3061 11.3511 19.6178 7.95924 26.8022 7.95924C28.1643 7.95924 30.5146 8.22626 31.4761 8.49338V14.4492C30.9686 14.3958 30.0873 14.3691 28.9922 14.3691C25.4668 14.3691 24.1047 15.7045 24.1047 19.1765V21.5H31.1274L29.9209 28.0701H24.1047V42.8425C34.7503 41.5567 43 32.4924 43 21.5Z"
            fill={isBlackAndWhite ? 'black' : '#1877F2'}
            fillOpacity={isBlackAndWhite ? '48%' : '100%'}
          />
          <path
            d="M29.9213 28.0701L31.1278 21.5H24.1052V19.1764C24.1052 15.7044 25.4672 14.3691 28.9926 14.3691C30.0877 14.3691 30.969 14.3957 31.4765 14.4491V8.49339C30.515 8.22627 28.1647 7.95915 26.8026 7.95915C19.6183 7.95915 16.3065 11.3511 16.3065 18.669V21.5H11.873V28.0701H16.3065V42.3669C17.97 42.7795 19.7094 43 21.5004 43C22.3822 43 23.2512 42.9456 24.1052 42.8425V28.0701H29.9213Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1246_1195">
          <rect width="43" height="43" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FacebookIcon;
