import { storeToken } from '@smartsaver/utils/token';
import { getSmartSaverServiceBaseUrls } from '@smartsaver/utils/url';
import axios from 'axios';

// import { jwtDecode } from 'jwt-decode';

const { authBaseUrl } = getSmartSaverServiceBaseUrls();

const authService = axios.create({
  baseURL: authBaseUrl,
  headers: {
    'Content-type': 'application/json',
  },
  withCredentials: true,
});

export const handleLogin = async ({ email, password }: { email: string; password: string }) => {
  const formData = new FormData();
  formData.append('identificator', email);
  formData.append('password', password);
  const { data } = await authService.post('/login', formData);
  const token = data?.data?.token;
  // const decoded = token ? jwtDecode(token) : null;
  if (token) {
    storeToken(token);
  }
};
