import { FC } from 'react';

import { IconProps } from './types';

const MenuIcon: FC<IconProps> = ({ width = '18', height = '18' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
    >
      <rect width="6" height="6" rx="3" fill="black" />
      <rect y="12" width="6" height="6" rx="3" fill="black" />
      <rect x="12" width="6" height="6" rx="3" fill="black" />
      <rect x="12" y="12" width="6" height="6" rx="3" fill="black" />
    </svg>
  );
};

export default MenuIcon;
