import { FC } from 'react';

import { IconProps } from './types';

const ShowPasswordIcon: FC<IconProps> = ({ width = '25', height = '24' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <g clipPath="url(#clip0_1_481)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.0913 12C17.0913 14.7614 14.8527 17 12.0913 17C9.32988 17 7.09131 14.7614 7.09131 12C7.09131 9.23858 9.32988 7 12.0913 7C14.8527 7 17.0913 9.23858 17.0913 12ZM15.0913 12C15.0913 13.6569 13.7482 15 12.0913 15C10.4345 15 9.09131 13.6569 9.09131 12C9.09131 10.3431 10.4345 9 12.0913 9C13.7482 9 15.0913 10.3431 15.0913 12Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.0913 12C24.0913 14.5 18.7187 21 12.0913 21C5.46389 21 0.0913086 15 0.0913086 12C0.0913086 9 5.46389 3 12.0913 3C18.7187 3 24.0913 9.5 24.0913 12ZM22.0909 11.9989L22.091 12L22.0909 12.0011C22.0885 12.0209 22.0734 12.1449 21.98 12.3886C21.8771 12.6571 21.7071 12.9977 21.4576 13.3925C20.9581 14.1829 20.2042 15.0894 19.2451 15.9451C17.3023 17.6786 14.7563 19 12.0913 19C9.37848 19 6.82485 17.7619 4.91309 16.1112C3.96505 15.2926 3.22539 14.4144 2.73651 13.6218C2.22017 12.7848 2.09131 12.2216 2.09131 12C2.09131 11.7784 2.22017 11.2152 2.73651 10.3782C3.22539 9.58565 3.96505 8.70737 4.91309 7.8888C6.82485 6.23812 9.37848 5 12.0913 5C14.7563 5 17.3023 6.32143 19.2451 8.05485C20.2042 8.91057 20.9581 9.81711 21.4576 10.6075C21.7071 11.0023 21.8771 11.3429 21.98 11.6114C22.0734 11.8551 22.0885 11.9791 22.0909 11.9989Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_481">
          <rect width={width} height={height} fill="white" transform="translate(0.0913086)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ShowPasswordIcon;
