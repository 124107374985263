import React, { FC, InputHTMLAttributes, ReactNode, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import InfoIconCircle from '@common/components/icons/InfoIconCircle';
import InputError from '@common/components/input-error/InputError';
import { inputErrorStyles } from '@common/components/input-error/InputError.styles';
import InputLabel from '@common/components/input-label/InputLabel';
import { sharedLabelStyles } from '@common/components/input-label/InputLabel.styles';
import clsx from 'clsx';

import styles from './PasswordInput.styles';

export interface PasswordInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  desciption?: string;
  styling?: 'regular' | 'account' | 'smartsaver';
  showHideIcons?: {
    show: ReactNode;
    hide: ReactNode;
  };
}

const PasswordInput: FC<PasswordInputProps> = ({
  name,
  label,
  placeholder,
  required,
  desciption,
  styling = 'regular',
  showHideIcons,
  ...rest
}) => {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { formatMessage } = useIntl();
  const {
    fieldState: { error },
  } = useController({
    name,
    rules: { required: required },
  });

  const {
    register,
    formState: { isSubmitting },
    getValues,
    trigger,
  } = useFormContext();

  const [canShowError, setCanShowError] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const togglePasswordVisibility = (e: React.MouseEvent) => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const { onChange, ...registration } = register(name);
  const isErrored = error?.message && canShowError;
  return (
    <div className="relative">
      <input
        {...rest}
        className={clsx('pr-16', styles.base, styles[styling ?? 'regular'], {
          [sharedLabelStyles.inputTopPaddingClassname]: !!label,
          [inputErrorStyles.border]: isErrored,
        })}
        autoComplete="new-password"
        disabled={isSubmitting}
        {...registration}
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
          onChange(e);
        }}
        type={showPassword ? 'text' : 'password'}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setIsFocused(false);
          setCanShowError(true);
          trigger(name);
        }}
      />
      {!!label && (
        <InputLabel
          name={name}
          text={label}
          styling={styling}
          shouldMinimize={isFocused || password || getValues(name)}
        />
      )}

      <button
        onMouseDown={(e) => e.preventDefault()}
        type="button"
        onClick={togglePasswordVisibility}
        className="absolute right-3 top-[35px] -translate-y-1/2 text-xs text-gray-500"
      >
        {showPassword
          ? showHideIcons?.hide || formatMessage({ defaultMessage: 'Hide' })
          : showHideIcons?.show || formatMessage({ defaultMessage: 'Show' })}
      </button>

      {!!desciption && (
        <div className="mt-2 flex gap-2 text-[14px] font-normal text-black">
          <div>
            <InfoIconCircle />
          </div>
          {desciption}
        </div>
      )}
      {isErrored && <InputError message={error?.message?.toString() ?? ''} />}
    </div>
  );
};

export default PasswordInput;
