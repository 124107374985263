const AlertIcon = () => {
  return (
    <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="2.45987"
        y="2.17666"
        width="65.0803"
        height="65.0803"
        rx="32.5401"
        fill="#F3BAC8"
        fillOpacity="0.07"
      />
      <rect
        x="2.45987"
        y="2.17666"
        width="65.0803"
        height="65.0803"
        rx="32.5401"
        stroke="#F3BAC1"
        strokeWidth="3.67364"
        strokeLinecap="square"
      />
      <path
        d="M35 26.3698L35 35.2695"
        stroke="#F3BAC1"
        stroke-width="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35 41.9461L35 43.0586"
        stroke="#F3BAC1"
        stroke-width="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AlertIcon;
