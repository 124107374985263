import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import FooterLogo from '@common/components/icons/FooterLogo';
import { getClassNames } from '@smartsaver/components/main-layout/MainLayout.classes';
import useLinks from '@smartsaver/hooks/useLinks';
import clsx from 'clsx';

import m from './Footer.messages';

const Footer = () => {
  const { formatMessage } = useIntl();
  const { links } = useLinks();

  const classes = useMemo(() => getClassNames(), []);
  return (
    <div className={clsx(classes.headerFooterContainer, 'mb-10')}>
      <div
        className={clsx(
          classes.headerFooterWidth,
          'flex w-full flex-col items-center justify-center gap-3 self-stretch md:flex-row md:justify-between md:gap-0'
        )}
      >
        <div className="flex flex-col items-center justify-center gap-8 md:flex-row md:gap-5">
          <a href={links.publicSite}>
            <FooterLogo />
          </a>
          <span className="text-center font-normal text-black text-opacity-50 md:text-base">
            {formatMessage(m.allRightsReserved)}
          </span>
        </div>
        <div className="flex items-center gap-3 underline">
          <a href={links.contactUs}>{formatMessage(m.contactUs)}</a>
          <a href={links.faq}>{formatMessage(m.faq)}</a>
          <a href={links.legal}>{formatMessage(m.legal)}</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
