import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import Button from '@common/components/button/Button';
import CheckIconOutlined from '@common/components/icons/CheckIconOutlined';
import { PlanFetch } from '@common/services/application';
import clsx from 'clsx';

import m from './PlanSelectCard.messages';

interface ProductSelectItemProps {
  type: 'credit' | 'subscription';
  texts: string[];
  onApplyClick: () => Promise<void>;
  planFetchData?: PlanFetch;
  halfSize?: boolean;
}

const Divider = ({ dividerType }: { dividerType: boolean }) => {
  return (
    <div
      className="my-2 flex h-px w-full"
      style={{
        background: dividerType
          ? 'linear-gradient(to right, transparent, rgba(0, 0, 0, 0.28) 51.5%, transparent)'
          : 'linear-gradient(to right, transparent, rgba(255, 255, 255) 51.5%, transparent)',
        opacity: '0.28',
      }}
    ></div>
  );
};

const PlanSelectItem = ({
  type,
  texts,
  onApplyClick,
  planFetchData,
  halfSize = true,
}: ProductSelectItemProps) => {
  const isCredit = type === 'credit';
  const { formatMessage, formatNumber } = useIntl();

  const limit: number | undefined = useMemo(
    () => (isCredit ? planFetchData?.freeProductLimit : planFetchData?.subscriptionProductLimit),
    [isCredit, planFetchData]
  );

  const percentage: string | null = useMemo(
    () =>
      planFetchData?.interestPercentage
        ? (planFetchData?.interestPercentage * 100).toFixed(2)
        : null,
    [planFetchData?.interestPercentage]
  );

  const subscriptionFee = useMemo(() => {
    const subscription = planFetchData?.data?.[0];
    return subscription?.fee ?? null;
  }, [planFetchData?.data]);

  return (
    <div
      className={clsx(
        'relative mb-2 flex h-fit w-full flex-col justify-start rounded-[1.75rem] shadow-bottom backdrop-blur',
        isCredit ? 'bg-[#E7E6E1]' : 'bg-blue-alt',
        { 'lg:w-1/2': halfSize }
      )}
    >
      <div className="inline-flex flex-col items-center justify-center gap-4 py-5">
        <div className="text-center text-3xl font-semibold leading-[105%] text-black sm:text-[2.5rem]">
          {formatMessage(isCredit ? m.credit : m.subscription)}
        </div>
      </div>
      <div
        className={`rounded-[1.125rem] border border-black/20 ${isCredit ? 'bg-[#F9F8F8] text-black' : 'bg-[#000] text-white'} p-12 backdrop-blur-[2px]`}
      >
        {!!limit && (
          <div className="text-center text-[4.25rem] font-semibold leading-[66.176%] tracking-[-0.17rem]">
            {formatNumber(limit, {
              style: 'currency',
              currency: 'EUR',
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
              useGrouping: true,
            })}
          </div>
        )}
        {!!percentage && (
          <div className="mt-6 text-center text-2xl font-normal leading-10 tracking-[-0.06rem]">
            {isCredit
              ? formatMessage(m.percentageText, { percentage })
              : formatMessage(m.subscrptionPercentageText)}
          </div>
        )}
        <Divider dividerType={isCredit} />
        <div>
          {texts.map((text) => (
            <div key={`${type}_${text}`} className="flex min-h-16 items-center gap-4 py-[0.62rem]">
              <div>
                <CheckIconOutlined isFilled={!isCredit} />
              </div>
              <div className="text-lg"> {text}</div>
            </div>
          ))}
        </div>
        <Button
          onClick={onApplyClick}
          text={formatMessage(m.buttonText)}
          color={isCredit ? 'gray-alt' : 'white'}
          fullWidth={true}
        />
        {!isCredit && (
          <div className="mt-2 flex w-full flex-col justify-center gap-2 text-white">
            {!!subscriptionFee && (
              <div className="text-center">
                {formatMessage(m.subscriptionFeeText, { fee: subscriptionFee })}
              </div>
            )}
            <div className="text-center">
              {/* TODO: LINK */}
              <a
                className="underline"
                rel="noreferrer"
                target="_blank"
                href="https://monefit.com/wp-content/uploads/2024/10/monefit_credit_subscription_terms_and_conditions_ES_01.20.2024.pdf"
              >
                {formatMessage(m.termsText)}
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlanSelectItem;
