import { FC } from 'react';

import { IconProps } from '@common/components/icons/types';

const EnglishFlag: FC<IconProps> = ({ height = '16', width = '16' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3176_7750)">
        <path
          d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
          fill="#F0F0F0"
        />
        <path
          d="M1.65355 3.12891C1.02514 3.9465 0.551297 4.88884 0.275391 5.91256H4.4372L1.65355 3.12891Z"
          fill="#0052B4"
        />
        <path
          d="M15.7243 5.91253C15.4483 4.88884 14.9745 3.9465 14.3461 3.12891L11.5625 5.91253H15.7243Z"
          fill="#0052B4"
        />
        <path
          d="M0.275391 10.0879C0.551328 11.1116 1.02517 12.0539 1.65355 12.8715L4.43711 10.0879H0.275391Z"
          fill="#0052B4"
        />
        <path
          d="M12.8696 1.65358C12.052 1.02517 11.1097 0.551328 10.0859 0.275391V4.43717L12.8696 1.65358Z"
          fill="#0052B4"
        />
        <path
          d="M3.12988 14.3461C3.94748 14.9745 4.88982 15.4483 5.91351 15.7242V11.5625L3.12988 14.3461Z"
          fill="#0052B4"
        />
        <path
          d="M5.91348 0.275391C4.88979 0.551328 3.94745 1.02517 3.12988 1.65355L5.91348 4.43714V0.275391Z"
          fill="#0052B4"
        />
        <path
          d="M10.0859 15.7242C11.1096 15.4483 12.052 14.9745 12.8695 14.3461L10.0859 11.5625V15.7242Z"
          fill="#0052B4"
        />
        <path
          d="M11.5625 10.0879L14.3461 12.8715C14.9745 12.054 15.4483 11.1116 15.7243 10.0879H11.5625Z"
          fill="#0052B4"
        />
        <path
          d="M15.9323 6.95653H9.04353H9.0435V0.0677188C8.70191 0.02325 8.35366 0 8 0C7.64628 0 7.29809 0.02325 6.95653 0.0677188V6.95647V6.9565H0.0677188C0.02325 7.29809 0 7.64634 0 8C0 8.35372 0.02325 8.70191 0.0677188 9.04347H6.95647H6.9565V15.9323C7.29809 15.9768 7.64628 16 8 16C8.35366 16 8.70191 15.9768 9.04347 15.9323V9.04353V9.0435H15.9323C15.9768 8.70191 16 8.35372 16 8C16 7.64634 15.9768 7.29809 15.9323 6.95653Z"
          fill="#D80027"
        />
        <path
          d="M10.0859 10.086L13.6558 13.6558C13.82 13.4917 13.9766 13.3201 14.1261 13.1422L11.0698 10.0859H10.0859V10.086Z"
          fill="#D80027"
        />
        <path
          d="M5.91268 10.0859H5.91262L2.34277 13.6558C2.5069 13.82 2.67849 13.9766 2.85637 14.126L5.91268 11.0697V10.0859Z"
          fill="#D80027"
        />
        <path
          d="M5.91219 5.91372V5.91366L2.34232 2.34375C2.17813 2.50787 2.02151 2.67947 1.87207 2.85734L4.92841 5.91369H5.91219V5.91372Z"
          fill="#D80027"
        />
        <path
          d="M10.0859 5.9132L13.6558 2.34327C13.4917 2.17908 13.3201 2.02245 13.1422 1.87305L10.0859 4.92939V5.9132Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0_3176_7750">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EnglishFlag;
