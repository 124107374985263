import { FC, InputHTMLAttributes, ReactNode, useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import InputError from '@common/components/input-error/InputError';

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  disabled?: boolean;
  labelComponent?: ReactNode;
  checkedMarkComponent?: ReactNode;
}

const Checkbox: FC<CheckboxProps> = ({
  name,
  label,
  disabled,
  labelComponent,
  checkedMarkComponent,
  ...rest
}) => {
  useController({
    name,
    rules: {},
  });

  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    trigger,
  } = useFormContext();

  const { onChange, ...registration } = register(name);

  const isChecked = !!getValues(name);

  const handleLabelClick = useCallback(() => {
    const currValue = getValues(name);
    setValue(name, !currValue);
    trigger(name);
  }, [trigger, setValue, getValues, name]);

  return (
    <div className="flex flex-col">
      <div className="inline-flex w-full items-start justify-start gap-5">
        <label className="flex cursor-pointer items-center gap-4">
          <input
            {...rest}
            type="checkbox"
            className="hidden"
            checked={isChecked}
            {...registration}
            onChange={(e) => {
              onChange(e);
              trigger(name);
            }}
            disabled={disabled}
          />
          <div
            className="flex size-8 rotate-180 items-center justify-center rounded-lg 
            border border-gray-300 bg-white 
            transition duration-300 ease-in-out"
          >
            {isChecked &&
              (checkedMarkComponent ?? <div className="size-5 rounded-full bg-blue-brand"></div>)}
          </div>
        </label>
        <>
          {labelComponent ? (
            labelComponent
          ) : (
            <div
              role="presentation"
              onClick={handleLabelClick}
              className="shrink grow basis-0 cursor-pointer text-[15px] font-medium leading-normal text-black"
            >
              {label ?? ''}
            </div>
          )}
        </>
      </div>
      {!!errors?.[name]?.message && (
        <InputError message={errors?.[name]?.message?.toString() ?? ''} />
      )}
    </div>
  );
};

export default Checkbox;
