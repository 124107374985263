import { useCallback, useEffect, useState } from 'react';

import { loadCheckoutWebComponents } from '@checkout.com/checkout-web-components';
import BlackDotsLoader from '@common/components/loader/BlackDotsLoader';
import { Currency } from '@common/constants';
import { useDispatch, useLocale, useSelector } from '@common/hooks';
import useEnvironment from '@common/hooks/useEnvironment';
import { RootDispatch } from '@common/redux';
import { selectActiveAgreement } from '@common/redux/selectors/banking';
import { createPaymentSession } from '@common/redux/thunks/payment';
import { getUserData } from '@common/utils';
import TransactionRejectedCard from '@monefit-es/components/transaction-rejected-card/TransactionRejectedCard';

import { APPEARANCE } from './PaymentCheckout.helpers';

interface PaymentCheckoutProps {
  amount: number;
}

const PaymentCheckout = ({ amount }: PaymentCheckoutProps) => {
  const [isErrored, setIsErrored] = useState(false);
  const activeAgreement = useSelector(selectActiveAgreement);

  const [checkoutComponentLoading, setCheckoutComponentLoading] = useState(true);
  const dispatch = useDispatch<RootDispatch>();
  const { isProduction, isDevOrStagingEnv } = useEnvironment();
  const { locale, localeWithCountry } = useLocale();
  const { REACT_APP_CHECKOUT_API_KEY } = process.env;
  const {
    user: { id },
  } = getUserData();

  const initFlow = useCallback(
    async (paymentSessionInit: any) => {
      const checkout = await loadCheckoutWebComponents({
        paymentSession: paymentSessionInit,
        publicKey: REACT_APP_CHECKOUT_API_KEY ?? '',
        locale: locale,
        appearance: APPEARANCE,
        environment: isProduction ? 'production' : 'sandbox',

        onReady: async () => {
          setCheckoutComponentLoading(false);
        },
        onError: (c, e) => {
          setIsErrored(true);
        },
      });

      const flowComponent = checkout.create('flow');
      flowComponent.mount('#flow-container');
    },
    [isProduction, REACT_APP_CHECKOUT_API_KEY, locale]
  );

  const handlePaymentStart = useCallback(
    async (agreementId?: string) => {
      if (!agreementId) {
        return;
      }
      await dispatch(
        createPaymentSession({
          providerName: 'CheckoutMonefitES',
          userId: id,
          agreementId,
          reference: 'userPayment',
          amount,
          currency: Currency.EUR,
          locale: localeWithCountry,
        })
      )
        .unwrap()
        .then((res) => {
          const paymentSession = {
            id: res.paymentId,
            payment_session_token: res.token,
            _links: {
              self: {
                href: res.response._links.self.href,
              },
            },
          };
          initFlow(paymentSession);
        })
        .catch(() => {
          setIsErrored(true);
          setCheckoutComponentLoading(false);
        });
    },
    [dispatch, id, amount, localeWithCountry, initFlow, setCheckoutComponentLoading, setIsErrored]
  );

  useEffect(() => {
    handlePaymentStart(activeAgreement?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isErrored) {
    return <TransactionRejectedCard />;
  }

  return (
    <div className="flex w-full flex-col items-center justify-center gap-6">
      {checkoutComponentLoading && <BlackDotsLoader style={{ height: 60 }} />}
      <div className="w-full" id="flow-container"></div>
      {isDevOrStagingEnv && <pre>success card: 4242424242424242</pre>}
    </div>
  );
};

export default PaymentCheckout;
