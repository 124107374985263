import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  headTitle: {
    defaultMessage: 'Next steps',
    description: '@monefit-es Next steps',
  },
  subtitle1: {
    defaultMessage: 'Review and sign the loan agreement;',
    description: '@monefit-es Review and sign the loan agreement;',
  },
  subtitle2: {
    defaultMessage: "we'll email it to you afterwards.",
    description: "@monefit-es we'll email it to you afterwards.",
  },
  secci: {
    defaultMessage: 'SECCI',
    description: 'SECCI',
  },
  secciText: {
    defaultMessage: 'Go through the agreement details and accept to proceed.',
    description: '@monefit-es Go through the agreement details and accept to proceed.',
  },
  loadAgreement: {
    defaultMessage: 'Loan agreement',
    description: '@monefit-es Loan agreement',
  },
  loadAgreementText: {
    defaultMessage: 'One final step, agree the loan terms & conditions.',
    description: '@monefit-es One final step, agree the loan terms & conditions.',
  },
  nextButtonText: {
    defaultMessage: 'Next',
    description: '@monefit-es click on next button to proceed the document to the next page',
  },
  continueButtonText: {
    defaultMessage: 'Continue',
    description: '@monefit-es finishes the signing process',
  },
});
