import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  title: {
    defaultMessage: 'Login',
  },
  subTitle: {
    defaultMessage: 'Use your email and password to login',
  },
  email: {
    defaultMessage: 'Email',
  },
  password: {
    defaultMessage: 'Password',
  },
  forgotPassword: {
    defaultMessage: 'Forgot password?',
  },
  haventRegistered: {
    defaultMessage: "Haven't registered yet? {signupLink}",
  },
  ctaButton: {
    defaultMessage: 'Login',
  },
  signup: {
    defaultMessage: 'Signup',
    description: '@smartsaver Signup link login form',
  },
  footer: {
    defaultMessage: 'Your data is protected 🔒',
    description: '@smartsaver Your data is protected 🔒',
  },
  passwordRequired: {
    defaultMessage: 'Password cannot be blank',
  },
  blankEmail: {
    defaultMessage: 'Email cannot be blank',
  },
  wrongEmail: {
    defaultMessage: 'Email is not a valid email address',
  },
});
