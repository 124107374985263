import { FC, ReactNode } from 'react';

import LogoIcon from '@common/components/icons/LogoIcon';
import LanguageSelect from '@common/components/language-select/LanguageSelect';
import FlowProgressBar from '@common/components/progress-bar/FlowProgressBar';
import { useSelector } from '@common/hooks/useSelector';

export interface HeaderProps {
  hideProgressBar?: boolean;
  hideLanguageSelect?: boolean;
  userDisplayNameNode?: ReactNode;
  // TODO: Remove this prop when WaitingList page is replaced with dashboard page
  isWaitingList?: boolean;
  type?: 'common' | 'dashboard' | 'onboarding';
  loadingProp?: boolean;
}

const Header: FC<HeaderProps> = ({ hideProgressBar = false, hideLanguageSelect = false }) => {
  const { loading, meta } = useSelector((st) => st.application.flowInstance);
  return (
    <div className="flex w-full flex-col items-center justify-center gap-3 p-2 lg:flex-row lg:justify-between lg:gap-0">
      <div className="flex flex-[0.5] items-center justify-start gap-5">
        <LogoIcon />
        {!hideLanguageSelect && <LanguageSelect loading={loading} showLoader={meta?.showLoader} />}
      </div>
      {!hideProgressBar && <FlowProgressBar />}
      <div className="w-full flex-[0.5]"></div>
    </div>
  );
};

export default Header;
