import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import LoadingCard from '@common/components/loader/LoadingCard';
import { LocalStorageKeys } from '@common/constants';
import useInitialFlowActionPerform from '@common/hooks/useInitialFlowActionPerform';
import { FlowActions, FlowSteps } from '@common/services/application';
import MainLayout from '@monefit-es/components/layout/MainLayout';
import MainLoader from '@monefit-es/components/loaders/MainLoader';
import loader from '@src/assets/img/monefit-es/bank_loader.webp';

import m from './OnboardingDecisionPage.messages';

const OnboardingDecisionPage = () => {
  const { formatMessage } = useIntl();

  useInitialFlowActionPerform({
    flowAction: FlowActions.SUBMIT,
  });

  const componentToShow = useMemo(() => {
    const preDecisionStep = sessionStorage.getItem(LocalStorageKeys.PREDECISION_STEP);
    return preDecisionStep === FlowSteps.BANK ? (
      <MainLoader />
    ) : (
      <div className="flex w-full items-center justify-center text-black">
        <LoadingCard
          text={formatMessage(m.loadingCardText)}
          imgSrcProp={loader}
          hasTextShadow={false}
        />
      </div>
    );
  }, [formatMessage]);

  return (
    <MainLayout hideProgressBar hideHeaderAndFooter loaderComponent={componentToShow}>
      {componentToShow}
    </MainLayout>
  );
};

export default OnboardingDecisionPage;
