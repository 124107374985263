import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';

import BlackDotsLoader from '@common/components/loader/BlackDotsLoader';
import { useApplicationFlowInstance, useDispatch, useSelector } from '@common/hooks';
import { RootDispatch } from '@common/redux';
import { setShowLoader } from '@common/redux/slices/application';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions, FlowSteps } from '@common/services/application';

import m from './DrawdownPendingCard.message';

const DrawdownPendingCard = () => {
  const [instanceId] = useApplicationFlowInstance();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<RootDispatch>();
  const { loading } = useSelector((st) => st.application.flowInstance);
  const handleCheck = useCallback(async () => {
    if (loading) {
      return;
    }
    dispatch(setShowLoader(false));
    await dispatch(performApplicationFlowAction({ action: FlowActions.CHECK, instanceId }))
      .unwrap()
      .then((res) => {
        if (res.currentStepId !== FlowSteps.PROCESSING) {
          dispatch(setShowLoader(true));
        }
      })
      .catch((err) => {
        // TODO: should show toast and redirect to dashboard (?)
        console.error(err);
      });
  }, [dispatch, instanceId, loading]);

  useEffect(() => {
    const intervalId = setInterval(handleCheck, 3000);
    return () => clearInterval(intervalId);
  }, [handleCheck]);

  return (
    <div className="inline-flex w-full flex-col items-start justify-between rounded-3xl bg-white p-7 backdrop-blur-lg">
      <div className="flex flex-col items-center justify-end gap-5 self-stretch py-20">
        <div className="flex justify-center">
          <BlackDotsLoader style={{ height: 60 }} />
        </div>

        <div className="self-stretch text-center text-base font-medium leading-none tracking-tight text-black">
          {formatMessage(m.pendingMessage)}
        </div>
      </div>
    </div>
  );
};

export default DrawdownPendingCard;
