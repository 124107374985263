import { FC } from 'react';

import { IconProps } from './types';

const CloseIcon2: FC<IconProps> = ({ width = '25', height = '24' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M18.7071 7.20711C19.0976 6.81658 19.0976 6.18342 18.7071 5.79289C18.3166 5.40237 17.6834 5.40237 17.2929 5.79289L12.5 10.5858L7.70711 5.79299C7.31658 5.40246 6.68342 5.40246 6.29289 5.79299C5.90237 6.18351 5.90237 6.81668 6.29289 7.2072L11.0857 12L6.29289 16.7929C5.90237 17.1834 5.90237 17.8166 6.29289 18.2071C6.68342 18.5976 7.31658 18.5976 7.70711 18.2071L12.5 13.4143L17.2929 18.2072C17.6834 18.5977 18.3166 18.5977 18.7071 18.2072C19.0976 17.8167 19.0976 17.1835 18.7071 16.793L13.9142 12L18.7071 7.20711Z"
        fill="#666666"
      />
    </svg>
  );
};

export default CloseIcon2;
