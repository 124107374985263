import { useEffect, useMemo } from 'react';
import { useIntercom } from 'react-use-intercom';
import { useLocation } from 'react-router-dom';

import { useLocale, useSelector } from '@common/hooks';
import { useCookieConsents } from '@common/hooks/useCookieConsents';
import useEnvironment from '@common/hooks/useEnvironment';
import { getUserDisplayName } from '@common/utils';
import { selectFlowInstance, selectAuthentication } from '@common/redux/selectors';

const ALLOWED_INTERCOM_PATHS = ['/user', '/account', '/declined', '/error'];

const Intercom = () => {
  const { consents, loaded: constentsLoaded } = useCookieConsents();
  const { isDevOrStagingEnv } = useEnvironment();
  const { pathname } = useLocation();
  const { boot, update, shutdown } = useIntercom();
  const authState = useSelector(selectAuthentication);
  const flowInstance = useSelector(selectFlowInstance);
  const userName = getUserDisplayName();
  const { locale } = useLocale();

  const hasConsents = useMemo(
    // () => (isDevOrStagingEnv ? true : constentsLoaded && consents.necessary),
    () => true,
    [consents, constentsLoaded, isDevOrStagingEnv]
  );
  const isPathAllowed = ALLOWED_INTERCOM_PATHS.some(path => pathname.includes(path));
  const isErrored = authState.error || flowInstance.error;
  const canLoadIntercom = (isPathAllowed || isErrored) && hasConsents;

  useEffect(() => {
    if (!canLoadIntercom) {
      return;
    }
    boot({ languageOverride: locale, name: userName ?? '' });
  }, [locale, boot, userName, canLoadIntercom]);

  useEffect(() => {
    if (!canLoadIntercom) {
      return;
    }
    update({ languageOverride: locale, name: userName ?? '' });
  }, [locale, update, userName, canLoadIntercom]);

  useEffect(() => {
    if (!canLoadIntercom) {
      shutdown();
    }
  }, [locale, shutdown, userName, canLoadIntercom]);

  return <></>;
};

export default Intercom;
