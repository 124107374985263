import { FC, useMemo, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Select, { OptionProps, Props as SelectProps, components } from 'react-select';

import IconDown from '@common/components/icons/IconDown';
import InputError from '@common/components/input-error/InputError';
import InputLabel from '@common/components/input-label/InputLabel';
import { sharedLabelStyles } from '@common/components/input-label/InputLabel.styles';

import { styles } from './SelectInput.styles';

export interface IOption {
  label: string;
  value: string | number;
}

export interface SelectInputProps extends SelectProps {
  name: string;
  options: IOption[];
  defaultValue?: string;
  label: string;
  hideError?: boolean;
  triggerOnChange?: boolean;
  styling?: 'regular' | 'account' | 'smartsaver';
}

const Option = (props: OptionProps<any>) => {
  return (
    <div className="text-left text-black">
      <components.Option {...props} />
    </div>
  );
};

const SelectInput: FC<SelectInputProps> = ({
  name,
  defaultValue,
  options,
  label,
  hideError = false,
  triggerOnChange = false,
  styling = 'regular',
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const {
    field: { onChange, value },
    formState: { isSubmitting },
    fieldState: { error },
  } = useController({
    defaultValue: defaultValue,
    name,
    rules: { required: props.required },
  });

  const { trigger } = useFormContext();

  const defaultOption = useMemo(() => options?.find((x) => x.value === value), [options, value]);
  const isErrored = error?.message && !hideError;

  return (
    <div className="relative">
      <Select
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => <IconDown />,
          Menu: (props) => <components.Menu {...props} />,
          IndicatorsContainer: (props) => (
            <components.IndicatorsContainer {...props} className="pr-2 sm:pr-8" />
          ),
          Option,
        }}
        {...props}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setIsFocused(false);
          trigger(name);
        }}
        isDisabled={isSubmitting}
        menuPlacement="auto"
        defaultValue={defaultOption}
        options={options}
        placeholder=""
        onChange={(newValue: any) => {
          onChange(newValue?.value ?? null);
          if (triggerOnChange) {
            trigger(name);
          }
        }}
        classNames={{
          control: () => 'h-16',
          menu: () => 'px-3 py-2 sm:px-7 sm:py-4',
          valueContainer: () => 'px-8 py-7',
        }}
        styles={{
          control: (base, props): any => ({
            ...base,
            borderColor: isErrored
              ? styles[styling]?.borderColorError
              : styles[styling]?.borderColor,
            boxShadow: 'none',
            borderRadius: '0.75rem', // rounded-xl
            '&:hover': {
              borderColor: isErrored
                ? styles[styling]?.borderColorError
                : styles[styling]?.borderColor,
            },
          }),
          option: (base, state): any => ({
            ...base,
            cursor: 'pointer',
            padding: '5px 1px',
            color: 'black',
            backgroundColor: 'transparent',
            fontFamily: state.isFocused ? 'Avenir Next DemiBold' : 'Avenir Next',
            '&:active': {
              backgroundColor: 'white',
            },
            '&:hover': {
              backgroundColor: '#F3F4F6', // gray-100
              color: 'black',
              borderRadius: '0.125rem', // rounded-sm,
            },
          }),
          input: (base) => ({
            ...base,
          }),
          menu: (base): any => ({
            ...base,
            borderRadius: '0.75rem', // rounded-xl,
            border: '1px solid #d1d5db', // border-gray-300
            boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
            marginTop: '4px',
            marginBottom: '4px',
          }),
          valueContainer: (base): any => ({
            ...base,
            display: 'flex',
            paddingTop: sharedLabelStyles.inputTopPaddingPixels,
            paddingLeft: '24px', // pl-6
            fontWeight: '600',
            flexWrap: 'no-wrap',
          }),
        }}
      />
      <div>
        <InputLabel
          styling={styling}
          name={name}
          text={label}
          shouldMinimize={isFocused || value !== undefined}
        />
      </div>
      {isErrored && <InputError message={error?.message?.toString() ?? ''} />}
    </div>
  );
};

export default SelectInput;
